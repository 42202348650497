import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import Swal from "sweetalert2";

import image from '../../assets/media/image-default-dark.svg';
import { useDropzone } from "react-dropzone";
import { StyleSelect } from "../../styles/styleSelect";
import ReactSelect from "react-select";
import Editor from "../../components/TextArea";
import Quill from "quill";
import api from "../../service/api";
//import "quill/dist/quill.core.css";

//import "nouislider/distribute/nouislider.css";

export interface Root {
   supplier: Supplier;
   nfe_identification: string;
   produtos: Produto[];
}

export interface Supplier {
   cnpj: number;
   name: string;
}

export interface Produto {
   name: string;
   quantity: number;
   unit_price: number;
   total_price: number;
   unit_type: string;
   selected: boolean;
}

function CreateProduct() {
   const initValues: {
      main_image: File | undefined,
      name: string,
      description: string,
      price: string,
      category: string,
      quantity?: number,
      limit: number
   } = {
      main_image: undefined,
      name: '',
      description: '',
      price: 'R$ 0,00',
      category: '',
      quantity: undefined,
      limit: 0
   }

   const [files, setFiles] = useState<File[]>([]);

   const [imagePreviewUrl, setImagePreviewUrl] = useState<string | undefined>(undefined);

   const [fileUrls, setFileUrls] = useState<string[]>([]);

   useEffect(() => {
      // Limpa os URLs antigos
      fileUrls.forEach((url) => URL.revokeObjectURL(url));

      // Cria novos URLs
      const newFileUrls = files.map(file => URL.createObjectURL(file));
      setFileUrls(newFileUrls);

      // Cleanup function to revoke URLs when component unmounts or files change
      return () => {
         newFileUrls.forEach(url => URL.revokeObjectURL(url));
      };
   }, [files]);

   const onDrop = useCallback((acceptedFiles: File[]) => {


      if (files.length + acceptedFiles.length > 5) {
         Swal.fire({
            text: "Você pode adicionar no máximo 5 arquivos",
            icon: "error",
            showCancelButton: false,
            showConfirmButton: false,
            buttonsStyling: false,
            customClass: {
               icon: "my-2 mx-auto",
            },
         });
         return
      }


      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles.map((file) => file)]);
   }, [files]);

   const removeFile = (file: File) => {
      setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
   };

   const { getRootProps, open, getInputProps } = useDropzone({
      maxFiles: 10,
      onDrop,
      noClick: true,
   });


   const SignupSchema = Yup.object().shape({
      name: Yup.string()
         .min(2, 'Nome é muito curto')
         .required('O nome do produto é obrigatório'),
      price: Yup.string()
         .test('is-greater-than-zero', 'O valor deve ser maior que zero', (value) => !!value && (Number(value.replace(/\D/g, "")) / 100) > 0)
         .required('Campo obrigatório'),
   });

   const Delta = Quill.import('delta');


   const [categories, setCategories] = useState<{ id: string, name: string }[]>([]);

   // Use a ref to access the quill instance directly
   const editorRef = useRef<Quill | null>(null);


   useEffect(() => {

      api.get<{ id: string, name: string }[]>('/category').then((response) => {

         setCategories(response.data)

      }).catch((error) => {
         console.log(error)
      })

   }, [])



   return (
      <>
         <div
            className="app-main flex-column flex-row-fluid"
            style={{ height: "calc(100vh - 74px)" }}
         >
            <div className="d-flex flex-column flex-column-fluid">
               <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
                  <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack ">
                     <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">

                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                           Adicionar produto
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

                           <li className="breadcrumb-item text-muted">
                              <a href="/metronic8/demo1/index.html" className="text-muted text-hover-primary">
                                 Home
                              </a>
                           </li>
                           <li className="breadcrumb-item">
                              <span className="bullet bg-gray-500 w-5px h-2px" />
                           </li>
                           <li className="breadcrumb-item text-muted">
                              eCommerce
                           </li>
                           <li className="breadcrumb-item">
                              <span className="bullet bg-gray-500 w-5px h-2px" />
                           </li>
                           <li className="breadcrumb-item text-muted">
                              Catalog
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div className="app-content flex-column-fluid" >
                  <div className="app-container container-xxl">
                     <Formik
                        initialValues={initValues}
                        onSubmit={(values, { resetForm }) => {

                           const formData = new FormData();
                           values.main_image && formData.append('images', values.main_image);
                           formData.append('name', values.name);
                           formData.append('description', values.description);
                           formData.append('price', `${parseInt(values.price.replace(/\D/g, "")) / 100}`);
                           formData.append('quantity', values.quantity?.toString() || "0");
                           formData.append('category', values.category);
                           formData.append('min_quantity', values.limit.toString());
                           files.forEach((image, key) => formData.append(`images`, image));

                           api.post('/product', formData).then((response) => {

                              Swal.fire({
                                 text: "Produto criado com sucesso!",
                                 icon: "success",
                                 showCancelButton: false,
                                 showConfirmButton: false,
                                 buttonsStyling: false,
                                 customClass: {
                                    icon: "my-2 mx-auto",
                                 },
                              });

                              console.log(response.statusText)

                              if (response.status != 201) {
                                 Swal.fire({
                                    text: "Erro ao criar produto!",
                                    icon: "error",
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                    buttonsStyling: false,
                                    customClass: {
                                       icon: "my-2 mx-auto",
                                    },
                                 });
                                 return
                              }

                              resetForm()
                              editorRef.current?.setContents(new Delta());
                              setFiles([]);
                              setImagePreviewUrl(undefined);
                              setFileUrls([]);

                           }).catch((error) => {
                              console.log(error)
                           })

                        }}
                        validationSchema={SignupSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                     >
                        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
                           <Form onSubmit={handleSubmit} className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework">

                              <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                                 <div className="card card-flush py-4">
                                    <div className="card-header">
                                       <div className="card-title">
                                          <h2>Foto</h2>
                                       </div>
                                    </div>
                                    <div className="card-body text-center pt-0">
                                       <div className={`image-input ${!imagePreviewUrl ? "image-input-empty" : "image-input-changed"} image-input-outline image-input-placeholder mb-3`} data-kt-image-input="true" style={{ backgroundImage: `url(${image})` }}>
                                          <div className="image-input-wrapper w-150px h-150px" style={{ backgroundImage: `url(${imagePreviewUrl})` }} />
                                          <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" aria-label="Change avatar" data-bs-original-title="Change avatar" data-kt-initialized="1">
                                             <i className="ki-duotone ki-pencil fs-7">
                                                <span className="path1" />
                                                <span className="path2" />
                                             </i>
                                             <input type="file" name="main_image" accept=".png, .jpg, .jpeg" onChange={(e) => {
                                                setFieldValue("main_image", e.target.files?.[0]);

                                                const file = e.target.files?.[0];

                                                setImagePreviewUrl(file && URL.createObjectURL(file));


                                             }} />
                                             <input type="hidden" name="avatar_remove" />
                                          </label>
                                          <span onClick={() => { setFieldValue("main_image", undefined) }} className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" aria-label="Cancel avatar" data-bs-original-title="Cancel avatar" data-kt-initialized="1">
                                             <i className="ki-duotone ki-cross fs-2">
                                                <span className="path1" />
                                                <span className="path2" />
                                             </i>
                                          </span>

                                       </div>
                                       <div className="text-muted fs-7">Defina a imagem em miniatura do produto. Somente arquivos de imagem *.png, *.jpg e *.jpeg são aceitos</div>
                                    </div>
                                 </div>
                                 {/*<div className="card card-flush py-4">

                                    <div className="card-header">

                                       <div className="card-title">
                                          <h2>Status</h2>
                                       </div>
                                       <div className="card-toolbar">
                                          <div className={`rounded-circle w-15px h-15px ${statusBackground[values.status]}`}></div>
                                       </div>

                                    </div>

                                    <div className="card-body pt-0" >


                                       <ReactSelect
                                          name="segment"
                                          isSearchable={true}
                                          placeholder="Selecione o tipo"
                                          className="mb-2"
                                          noOptionsMessage={() => "Sem opção"}
                                          theme={(theme) => ({
                                             ...theme,
                                             colors: {
                                                ...theme.colors,
                                                primary: "var(--bs-gray-400)",
                                             },
                                          })}
                                          defaultValue={{ value: "published", label: "Publicado" }}
                                          styles={StyleSelect}
                                          options={[{ value: "published", label: "Publicado" }, { value: "draft", label: "Rascunho" }, { value: "scheduled", label: "Agendado" }, { value: "inactive", label: "Inativo" }]}
                                          onChange={(e) => {
                                             setFieldValue("status", e?.value)
                                          }}
                                       />

                                       <div className="text-muted fs-7">Defina o status do produto.</div>



                                       <div className="d-none mt-10">
                                          <label htmlFor="kt_ecommerce_add_product_status_datepicker" className="form-label">Select publishing date and time</label>
                                          <input className="form-control flatpickr-input" id="kt_ecommerce_add_product_status_datepicker" placeholder="Pick date &amp; time" type="text" />
                                       </div>

                                    </div>

                                 </div>*/}



                                 <div className="card card-flush py-4">

                                    <div className="card-header">

                                       <div className="card-title">
                                          <h2>Categoria</h2>
                                       </div>

                                    </div>

                                    <div className="card-body pt-0">
                                       <ReactSelect
                                          name="status"
                                          isSearchable={true}
                                          placeholder="Selecione o tipo"
                                          className="mb-2"
                                          noOptionsMessage={() => "Sem opção"}
                                          theme={(theme) => ({
                                             ...theme,
                                             colors: {
                                                ...theme.colors,
                                                primary: "var(--bs-gray-400)",
                                             },
                                          })}
                                          onChange={(e) => {
                                             setFieldValue("category", e?.value)
                                          }}

                                          styles={StyleSelect}
                                          options={categories.map((category) => ({ value: category.id, label: category.name }))}
                                       />

                                       <div className="text-muted fs-7 mb-7">Adicione produto a uma categoria.</div>

                                       <a href="/metronic8/demo1/apps/ecommerce/catalog/add-category.html" className="btn btn-light-primary btn-sm ">
                                          <i className="ki-duotone ki-plus fs-2"></i>
                                          Criar nova categoria
                                       </a>

                                       {/*<label className="form-label d-block">Tags</label>
                                       <input onKeyDown={(e) => {

                                          if (e.key === "Enter") {
                                             e.preventDefault()
                                             setFieldValue("tags", [...values.tags, e.currentTarget.value])
                                             e.currentTarget.value = ""

                                          }
                                       }} className="form-control mb-2" />

                                       <div className="text-muted fs-7 mb-2">Adicione tags a um produto.</div>
                                       {values.tags.map((tag, key) => (
                                          <span className="badge badge-light-success m-1 py-1 px-2" style={{ position: "relative" }} key={key}>
                                             <button type="button" style={{
                                                maskRepeat: "no-repeat",
                                                maskPosition: "center",
                                                display: "block",
                                                opacity: 0.5,
                                                border: 0,
                                                top: "50%",
                                                backgroundColor: "transparent",
                                                marginLeft: "0px",
                                                paddingLeft: "0px",
                                             }} title="Remove item" onClick={() => { setFieldValue("tags", values.tags.filter((tagCurrent) => tagCurrent !== tag)) }}>
                                                <span style={{ fontSize: "13px" }}>×</span>
                                             </button>
                                             <span style={{ fontSize: "12px" }}>{tag}</span>
                                          </span>))}*/}
                                    </div>
                                 </div>


                                 <div className="card card-flush py-4">

                                    <div className="card-header">

                                       <div className="card-title">
                                          <h2>Vendas semanais</h2>
                                       </div>

                                    </div>



                                    <div className="card-body pt-0">
                                       <span className="text-muted">Não há dados disponíveis. Os dados de vendas começarão a ser capturados assim que o produto for publicado.</span>
                                    </div>

                                 </div>


                              </div>
                              <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10" >
                                 {/*<ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2" >
                                    <li className="nav-item" role="presentation" onClick={() => setTab("general")} style={{ cursor: "pointer" }}>
                                       <a className={`nav-link text-active-primary pb-4 ${tab === "general" && "active"}`}>Geral</a>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={() => setTab('advanced')} style={{ cursor: "pointer" }}>
                                       <a className={`nav-link text-active-primary pb-4 ${tab === "advanced" && "active"}`}>Avançado</a>
                                    </li>
                                 </ul>*/}


                                 <div className="tab-content" >

                                    <div className={`tab-pane fade active show`} >
                                       <div className="d-flex flex-column gap-7 gap-lg-10" >


                                          <div className="card card-flush py-4">

                                             <div className="card-header">
                                                <div className="card-title">
                                                   <h2>Geral</h2>
                                                </div>
                                             </div>

                                             <div className="card-body pt-0">

                                                <div className="mb-10 fv-row fv-plugins-icon-container">

                                                   <label className="required form-label">Nome do produto</label>
                                                   <input type="text" name="name" className="form-control mb-2" placeholder="Nome do produto" onChange={handleChange} value={values.name} />

                                                   <div className="text-muted fs-7">Um nome de produto é obrigatório e recomendado para ser exclusivo.</div>
                                                   {errors.name && <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{errors.name}</div>}


                                                </div>

                                                <div className="fv-row fv-plugins-icon-container">

                                                   <label className="form-label">Descrição</label>

                                                   {/*<Editor
                                                      ref={editorRef}
                                                      readOnly={false}
                                                      theme="snow"
                                                      onTextChange={(e: any) => {
                                                         setFieldValue("description", editorRef.current?.getSemanticHTML());
                                                      }}
                                                      value={values.description}

                                                   />*/}


                                                   <div className="text-muted fs-7 d-block">
                                                      Defina uma descrição do produto para melhor visibilidade.
                                                   </div>

                                                </div>

                                             </div>

                                          </div>


                                          <div className="card card-flush py-4">

                                             <div className="card-header">
                                                <div className="card-title">
                                                   <h2>Imagens</h2>
                                                </div>
                                             </div>




                                             <div className="card-body pt-0">

                                                <div className="fv-row mb-2 w-100">
                                                   <div {...getRootProps({ className: 'dropzone dz-clickable' })} onClick={open} style={{ maxWidth: "860px" }}>
                                                      <input {...getInputProps()} />
                                                      <div className="dz-message needsclick">
                                                         <i className="ki-duotone ki-file-up text-primary fs-3x">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                         </i>
                                                         <div className="ms-4">
                                                            <h3 className="fs-5 fw-bold text-gray-900 mb-1">Solte os arquivos aqui ou clique para fazer upload.</h3>
                                                            <span className="fs-7 fw-semibold text-gray-500">Carregue até 5 arquivos</span>

                                                         </div>
                                                      </div>
                                                      {files.map((file, key) => (
                                                         <div key={key} className="dz-preview dz-processing dz-image-preview" onClick={(event) => {
                                                            event.stopPropagation();
                                                         }}>
                                                            <div className="dz-image">
                                                               <img data-dz-thumbnail="" alt={file.name} src={fileUrls[key]} className="w-100 h-100" style={{ objectFit: "cover" }} />
                                                            </div>
                                                            <div className="dz-details">
                                                               <div className="dz-size">
                                                                  <span><strong>{(file.size / 1024 / 1024).toFixed(2)}</strong> MB</span>
                                                               </div>
                                                               <div className="dz-filename">
                                                                  <span>{file.name}</span>
                                                               </div>
                                                            </div>
                                                            <a style={{ zIndex: 5 }} className="dz-remove" onClick={() => { removeFile(file) }} data-dz-remove="">Remove file</a>
                                                         </div>
                                                      ))}
                                                   </div>
                                                </div>

                                             </div>
                                          </div>
                                          <div className="card card-flush py-4" >

                                             <div className="card-header">
                                                <div className="card-title">
                                                   <h2>Preço</h2>
                                                </div>
                                             </div>

                                             <div className="card-body pt-0" >

                                                <div className=" fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid">

                                                   <label className="required form-label">Preço base</label>

                                                   <input type="text" name="price" className="form-control mb-2" placeholder="Preço base" onChange={(event) => {
                                                      const value = event.target.value;
                                                      setFieldValue("price", new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                                                         Number(value.replace(/\D/g, "")) / 100
                                                      ))
                                                   }} value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                                                      Number(values.price.toString().replace(/\D/g, "")) / 100
                                                   )} />

                                                   <div className="text-muted fs-7">Defina o preço do produto.</div>

                                                   {errors.price && <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{errors.price}</div>}

                                                </div>

                                                {/*<div className="fv-row mb-10">

                                                   <label className="fs-6 fw-semibold mb-2">
                                                      Tipo de desconto

                                                      <span className="ms-1" data-bs-toggle="tooltip" aria-label="Select a discount type that will be applied to this product" data-bs-original-title="Select a discount type that will be applied to this product" data-kt-initialized="1">
                                                         <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                            <span className="path3"></span>
                                                         </i>
                                                      </span>
                                                   </label>

                                                   <div className="row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9" >

                                                      <div className="col">

                                                         <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 ${values.discount === "no_discount" && 'active'}`}>

                                                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                               <input className="form-check-input" type="radio" name="discount" defaultValue="no_discount" onChange={(e) => {
                                                                  handleChange(e);
                                                                  setFieldValue("value_discount", 0);
                                                               }} />
                                                            </span>
                                                            <span className="ms-5">
                                                               <span className="fs-4 fw-bold text-gray-800 d-block">Sem desconto</span>
                                                            </span>

                                                         </label>

                                                      </div>



                                                      <div className="col">

                                                         <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 ${values.discount === "percentage" && 'active'}`} >

                                                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                               <input className="form-check-input" type="radio" name="discount" defaultValue="percentage" onChange={(e) => {
                                                                  handleChange(e);
                                                                  setFieldValue("value_discount", 10);
                                                               }} />
                                                            </span>

                                                            <span className="ms-5">
                                                               <span className="fs-4 fw-bold text-gray-800 d-block">Porcentagem %</span>
                                                            </span>

                                                         </label>

                                                      </div>



                                                      <div className="col">

                                                         <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 ${values.discount === "fixed_price" && 'active'}`}>

                                                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                               <input className="form-check-input" type="radio" name="discount" defaultValue="fixed_price" onChange={(e) => {
                                                                  handleChange(e);
                                                                  setFieldValue("value_discount", 0);
                                                               }} />
                                                            </span>
                                                            <span className="ms-5">
                                                               <span className="fs-4 fw-bold text-gray-800 d-block">Preço fixo</span>
                                                            </span>

                                                         </label>

                                                      </div>

                                                   </div>

                                                </div>

                                                <div className={`mb-10 fv-row ${values.discount !== "percentage" && 'd-none'}`}>

                                                   <label className="form-label">Definir porcentagem de desconto</label>

                                                   <div className="d-flex flex-column text-center mb-5">
                                                      <div className="d-flex align-items-start justify-content-center mb-7">
                                                         <span className="fw-bold fs-3x" id="kt_ecommerce_add_product_discount_label">{Number(values.value_discount).toFixed(0)}</span>
                                                         <span className="fw-bold fs-4 mt-1 ms-2">%</span>
                                                      </div>
                                                      <div className="w-100" style={{ position: "relative" }}>
                                                         {values.discount === "percentage" && (<Nouislider className="noUi-sm noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr" range={{ min: 1, max: 95 }} start={[10]} connect={true} onUpdate={(e) => {
                                                            setFieldValue("value_discount", e[0]);
                                                         }} step={1} />)}
                                                      </div>
                                                   </div>
                                                   <div className="text-muted fs-7">Defina um desconto percentual a ser aplicado neste produto.</div>
                                                </div>
                                                <div className={`mb-10 fv-row ${values.discount !== "fixed_price" && 'd-none'}`}>

                                                   <label className="form-label">Preço fixo com desconto</label>

                                                   <input type="text" className="form-control mb-2" placeholder="Discounted price" onChange={(event) => {
                                                      const value = event.target.value;
                                                      setFieldValue("value_discount", new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                                                         Number(value.replace(/\D/g, "")) / 100
                                                      ))
                                                   }} value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                                                      Number(values.value_discount.toString().replace(/\D/g, "")) / 100
                                                   )} />

                                                   <div className="text-muted fs-7">Defina o preço com desconto do produto. O produto será reduzido ao preço fixo determinado</div>

                                                </div>*/}

                                             </div>

                                          </div>

                                          <div className="card card-flush py-4">

                                             <div className="card-header">
                                                <div className="card-title">
                                                   <h2>Inventário</h2>
                                                </div>
                                             </div>



                                             <div className="card-body pt-0">

                                                <div className="mb-10 fv-row fv-plugins-icon-container">

                                                   <label className="required form-label">Quantidade</label>

                                                   <input type="text" name="quantity" className="form-control mb-2" placeholder="Na prateleira" onChange={(e) => {
                                                      setFieldValue("quantity", Number(e.target.value.replace(/\D/g, "")))
                                                   }} value={values.quantity ? Number(values.quantity) : 0} />

                                                   <div className="text-muted fs-7">Insira a quantidade do produto.</div>

                                                   <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{errors.quantity}</div>
                                                </div>


                                                <div className=" fv-row fv-plugins-icon-container">

                                                   <label className="form-label">Limite minimo do estoque</label>

                                                   <input type="text" name="limit" value={""} className="form-control mb-2" placeholder="Limite" onChange={(e) => {
                                                      setFieldValue("limit", e.target.value.replace(/\D/g, ""))
                                                   }} />

                                                   <div className="text-muted fs-7">Insira o número do código de barras do produto.</div>

                                                   <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                </div>

                                             </div>

                                             

                                          </div>

                                       </div>
                                    </div>



                                    {/*<div className={`tab-pane fade ${tab === "advanced" && "active show"}`} id="kt_ecommerce_add_product_advanced" >
                                       <div className="d-flex flex-column gap-7 gap-lg-10">





                                         <div className="card card-flush py-4">

                                             <div className="card-header">
                                                <div className="card-title d-flex flex-column">
                                                   <h2>Dimensões</h2>
                                                </div>
                                             </div>



                                             <div className="card-body pt-0">

                                                <div id="kt_ecommerce_add_product_shipping" className="mt-2">

                                                   <div className="mb-10 fv-row">

                                                      <label className="form-label required">Peso</label>

                                                      <input type="text" name="weight" className="form-control mb-2" placeholder="Peso do produto" onChange={handleChange} value={values.weight} />

                                                      <div className="text-muted fs-7">Defina o peso do produto em quilogramas (kg).</div>

                                                   </div>



                                                   <div className="fv-row">

                                                      <label className="form-label required">Dimensões</label>

                                                      <div className="d-flex flex-wrap flex-sm-nowrap gap-3">
                                                         <input type="number" name="width" className="form-control mb-2" placeholder="Largura" onChange={handleChange} value={values.width} />
                                                         <input type="number" name="height" className="form-control mb-2" placeholder="Altura" onChange={handleChange} value={values.height} />
                                                         <input type="number" name="length" className="form-control mb-2" placeholder="Comprimento" onChange={handleChange} value={values.length} />
                                                      </div>

                                                      <div className="text-muted fs-7">Insira as dimensões do produto em centímetros (cm).</div>

                                                   </div>

                                                </div>

                                             </div>

                                          </div>*/}


                                    {/*<div className="card card-flush py-4">

                                             <div className="card-header">
                                                <div className="card-title">
                                                   <h2>Opções Meta</h2>
                                                </div>
                                             </div>



                                             <div className="card-body pt-0">

                                                <div className="mb-10">

                                                   <label className="form-label">Título da metatag</label>

                                                   <input type="text" className="form-control mb-2" name="title_meta" placeholder="Nome da meta tag" onChange={handleChange} value={values.title_meta} />

                                                   <div className="text-muted fs-7">Defina um título de metatag. Recomendado para palavras-chave simples e precisas.</div>

                                                </div>



                                                <div className="mb-10">

                                                   <label className="form-label">Descrição da metatag</label>
                                                   <textarea className="form-control mb-2" style={{ minHeight: "100px", resize: "none" }} name="description_meta" placeholder="Descrição da meta tag" onChange={handleChange} value={values.description_meta} />
                                                   <div className="text-muted fs-7">Defina uma descrição de meta tag para o produto para aumentar a classificação de SEO.</div>

                                                </div>



                                                <div>

                                                   <label className="form-label">Palavras-chave de metatag</label>



                                                   <input name="keyword_meta" className="form-control mb-2" onKeyDown={(e) => {
                                                      if (e.key === "Enter") {
                                                         e.preventDefault()
                                                         setFieldValue("keywords_meta", [...values.keywords_meta, e.currentTarget.value])
                                                         e.currentTarget.value = ""
                                                      }
                                                   }} />

                                                   <div className="text-muted fs-7">Defina uma lista de palavras-chave às quais o produto está relacionado. Adicione as palavras-chave prescionando a tecla<code>enter</code>.</div>
                                                   <div style={{ width: "800px" }}>
                                                      {values.keywords_meta.map((tag, key) => (
                                                         <span className="badge badge-light-success m-1 py-1 px-2" style={{ position: "relative" }} key={key}>
                                                            <button type="button" style={{
                                                               maskRepeat: "no-repeat",
                                                               maskPosition: "center",
                                                               display: "block",
                                                               opacity: 0.5,
                                                               border: 0,
                                                               top: "50%",
                                                               backgroundColor: "transparent",
                                                               marginLeft: "0px",
                                                               paddingLeft: "0px",
                                                            }} title="Remove item" onClick={() => { setFieldValue("keywords_meta", values.keywords_meta.filter((tagCurrent) => tagCurrent !== tag)) }}>
                                                               <span style={{ fontSize: "13px" }}>×</span>
                                                            </button>
                                                            <span style={{ fontSize: "12px" }}>{tag}</span>
                                                         </span>))}
                                                   </div>
                                                </div>

                                             </div>

                                          </div>
                                       </div>
                                    </div>*/}


                                 </div>


                                 <div className="d-flex justify-content-end">

                                    <a href="/metronic8/demo1/apps/ecommerce/catalog/products.html" id="kt_ecommerce_add_product_cancel" className="btn btn-light me-5">
                                       Cancelar
                                    </a>



                                    <button type="submit" id="kt_ecommerce_add_product_submit" className="btn btn-primary">
                                       <span className="indicator-label">
                                          Salvar produto
                                       </span>
                                       <span className="indicator-progress">
                                          Aguarde... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                       </span>
                                    </button>

                                 </div>
                              </div>


                           </Form>
                        )}

                     </Formik>


                  </div>

               </div>


            </div>
            <div className="app-footer">
               <div className="app-container  container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-gray-900 order-2 order-md-1">
                     <span className="text-muted fw-semibold me-1">2023©</span>
                     <a
                        href="#"
                        target="_blank"
                        className="text-gray-800 text-hover-primary"
                     >
                        Pommering
                     </a>
                  </div>
               </div>
            </div>{" "}
         </div>

      </>
   );
}

export { CreateProduct };
