import { FastField, FieldArray, Form, Formik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";

import image from '../../assets/media/image-default-dark.svg';
import api from "../../service/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cnpj } from "cpf-cnpj-validator";

export interface Root {
   supplier: Supplier;
   nfe_identification: string;
}


interface Supplier {
   response: Array<{
      id: string
      company_name: string
      document: string
      image?: string
   }>
   pagination: {
      totalPages: number
      currentPage: number
      pageSize: number
   }
}

function ListSupplier() {

   const navigate = useNavigate();

   const [suppliers, setSuppliers] = useState<Supplier | undefined>(undefined);

   const [page, setPage] = useState(0);

   useEffect(() => {
      api.get<Supplier>(`/supplier?limit=2&page=${page}`).then(({ data }) => {
         setSuppliers(data);
         console.log(data.pagination.currentPage, page)
      }).catch(error => {
         Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message,
         });
      });

   }, [page])


   return (
      <>
         <div
            className="app-main flex-column flex-row-fluid"
            style={{ height: "calc(100vh - 74px)" }}
         >

            <div className="d-flex flex-column flex-column-fluid">
               <div className="app-toolbar  py-3 py-lg-6 ">
                  <div className="app-container  container-xxl d-flex flex-stack ">
                     <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">

                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                           Fornecedores
                        </h1>




                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

                           <li className="breadcrumb-item text-muted">
                              <a href="/metronic8/demo1/index.html" className="text-muted text-hover-primary">
                                 Inicio </a>
                           </li>


                           <li className="breadcrumb-item">
                              <span className="bullet bg-gray-500 w-5px h-2px"></span>
                           </li>


                           <li className="breadcrumb-item text-muted"> Fornecedores </li>


                        </ul>

                     </div>
                  </div>

               </div>



               <div className="app-content  flex-column-fluid ">

                  <div className="app-container  container-xxl ">

                     <div className="card card-flush">

                        <div className="card-header align-items-center py-5 gap-2 gap-md-5">

                           <div className="card-title">

                              <div className="d-flex align-items-center position-relative my-1">
                                 <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                 </i>
                                 <input type="text" className="form-control form-control-solid w-250px ps-12" placeholder="Pesquisar fornecedor" />
                              </div>

                           </div>
                           <div className="card-toolbar flex-row-fluid justify-content-end gap-5">

                              <a onClick={() => {
                                 navigate(
                                    "/supplier/create"
                                 )
                              }} className="btn btn-primary">
                                 Adicionar Fornecedor
                              </a>
                           </div>
                        </div>

                        <div className="card-body pt-0">
                           <div className="dt-container dt-bootstrap5 dt-empty-footer">
                              <div className="table-responsive">
                                 <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable" style={{ width: "100%" }}>
                                    <colgroup>
                                       <col style={{ width: "285.609px" }} />
                                       <col style={{ width: "142.797px" }} />
                                       <col style={{ width: "156.062px" }} />
                                    </colgroup>
                                    <thead>
                                       <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                          <th className="min-w-200px dt-orderable-asc dt-orderable-desc" rowSpan={1} colSpan={1} tabIndex={0}>
                                             <span className="dt-column-title" role="button">Fornecedor</span>
                                             <span className="dt-column-order"></span>
                                          </th>
                                          <th className="min-w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc" rowSpan={1} colSpan={1} tabIndex={0}>
                                             <span className="dt-column-title" role="button">CNPJ</span>
                                             <span className="dt-column-order"></span>
                                          </th>

                                          <th className="text-end min-w-70px dt-orderable-none" rowSpan={1} colSpan={1} aria-label="Actions">
                                             <span className="dt-column-title">Ações</span>
                                             <span className="dt-column-order"></span>
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-600">

                                       {suppliers?.response.map((supplier) => (
                                          <tr key={supplier.id}>
                                             <td>
                                                <div className="d-flex align-items-center">


                                                   <a href="#" className="symbol symbol-50px">
                                                      {!!supplier.image ? (
                                                         <span className="symbol-label" style={{ backgroundImage: `url(${supplier.image})` }} />
                                                      ) : (
                                                         <span className="symbol-label" >
                                                            <i className="ki-duotone ki-shop fs-1">
                                                               <span className="path1"></span>
                                                               <span className="path2"></span>
                                                               <span className="path3"></span>
                                                               <span className="path4"></span>
                                                               <span className="path5"></span>
                                                            </i>
                                                         </span>
                                                      )}

                                                   </a>


                                                   <div className="ms-5">

                                                      <a href="" className="text-gray-800 text-hover-primary fs-5 fw-bold"
                                                         data-kt-ecommerce-product-filter="product_name">{supplier.company_name}</a>

                                                   </div>
                                                </div>
                                             </td>
                                             <td className="pe-0 dt-type-numeric">
                                                <span className="fw-bold">{cnpj.format(supplier.document)}</span>
                                             </td>
                                             <td className="d-flex flex-end">
                                                <a className="btn btn-primary w-40px h-40px p-0 m-0 d-flex align-items-center justify-content-center me-5">
                                                   <i className="ki-duotone ki-pencil p-0 fs-3">
                                                      <span className="path1"></span>
                                                      <span className="path2"></span>
                                                   </i>
                                                </a>
                                                <a className="btn btn-danger w-40px h-40px p-0 m-0 d-flex align-items-center justify-content-center">
                                                   <i className="ki-duotone ki-trash p-0 fs-3">
                                                      <span className="path1"></span>
                                                      <span className="path2"></span>
                                                      <span className="path3"></span>
                                                      <span className="path4"></span>
                                                      <span className="path5"></span>
                                                   </i>
                                                </a>

                                             </td>
                                          </tr>
                                       ))}

                                    </tbody>
                                 </table>
                              </div>
                              <div className="row mt-4">
                                 <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar">
                                    <div className="d-flex mt-2">
                                       <select className="form-select form-select-solid form-select-sm" >
                                          <option value="10">10</option>
                                          <option value="25">25</option>
                                          <option value="50">50</option>
                                          <option value="100">100</option>
                                       </select>
                                       <label></label>
                                    </div>
                                 </div>
                                 <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                                    <div className="dt-paging paging_simple_numbers">
                                       <nav>
                                          <ul className="pagination">


                                             <li className={`dt-paging-button page-item ${suppliers?.pagination && suppliers.pagination.currentPage <= 1 && 'disabled'}`}>
                                                <button className="page-link previous" type="button" tabIndex={-1} onClick={() => setPage(page - 1)}>
                                                   <i className="previous"></i>
                                                </button>
                                             </li>


                                             {suppliers?.pagination && suppliers?.pagination.totalPages >= 1 && Array.from(Array(suppliers?.pagination.totalPages).keys()).map((page) => (
                                                <li key={page} className={`dt-paging-button page-item ${suppliers?.pagination.currentPage === page + 1 ? 'active' : ''}`}>
                                                   <button className="page-link" type="button" onClick={() => setPage(page + 1)}>{page + 1}</button>
                                                </li>
                                             ))}


                                             <li className={`dt-paging-button page-item ${suppliers?.pagination && suppliers.pagination.totalPages <= page && 'disabled'}`}>
                                                <button className="page-link next" type="button" tabIndex={-1} onClick={() => setPage(page + 1)}>
                                                   <i className="next"></i>
                                                </button>
                                             </li>

                                          </ul>
                                       </nav>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>

               </div>

            </div>

            <div className="app-footer">
               <div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3 ">

                  <div className="text-gray-900 order-2 order-md-1">
                     <span className="text-muted fw-semibold me-1">2024©</span>
                     <a href="https://keenthemes.com" target="_blank" className="text-gray-800 text-hover-primary">Keenthemes</a>
                  </div>



                  <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                     <li className="menu-item">
                        <a href="https://keenthemes.com" target="_blank" className="menu-link px-2">About</a>
                     </li>

                     <li className="menu-item"><a href="https://devs.keenthemes.com" target="_blank"
                        className="menu-link px-2">Support</a></li>

                     <li className="menu-item"><a href="https://1.envato.market/Vm7VRE" target="_blank"
                        className="menu-link px-2">Purchase</a></li>
                  </ul>
               </div>

            </div>

         </div>

      </>
   );
}

export { ListSupplier };
