import axios from "axios";
import { cnpj } from "cpf-cnpj-validator";
import { FieldArray, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import image from '../../assets/media/image-default-dark.svg';

export interface Root {
   supplier: Supplier;
   nfe_identification: string;
   produtos: Produto[];
}

export interface Supplier {
   cnpj: number;
   name: string;
}

export interface Produto {
   name: string;
   quantity: number;
   unit_price: number;
   total_price: number;
   unit_type: string;
   selected: boolean;
}

function CreatePayment() {
   const [response, setResponse] = React.useState<{
      merchandise_code: string;
      name: string;
      quantity: number;
      unit_price: number;
      total_price: number;
      unit_type: string;
      selected: boolean;
   } | undefined>(undefined);

   const [file, setFile] = React.useState<File | null>(null);
   const [responseNfe, setResponseNfe] = React.useState<
      | {
         supplier: {
            cnpj: number;
            name: string;
         };
         nfe_identification: string;
         produtos: Array<{
            merchandise_code: string;
            name: string;
            quantity: number;
            unit_price: number;
            total_price: number;
            unit_type: string;
            selected: boolean;
         }>;
      }
      | undefined
   >(undefined);

   const [modelProduct, setModelProduct] = React.useState<boolean>(false);

   const [modelProductRegister, setModelProductRegister] = React.useState<boolean>(false);

   const [modelProductRegisterNew, setModelProductRegisterNew] = React.useState<boolean>(false);

   const [formCompany, setFormCompany] = React.useState<{
      cnpj: string | undefined;
      name: string | undefined;
      fantasy_name: string | undefined;
      inscricao_estadual: string | undefined;
      inscricao_municipal: string | undefined;
   }>({
      cnpj: undefined,
      name: undefined,
      fantasy_name: undefined,
      inscricao_estadual: undefined,
      inscricao_municipal: undefined,
   });

   /*Swal.fire({
      text: "Do you want to continue",
      icon: "error",
      showCancelButton: false,
      showConfirmButton: false,
      buttonsStyling: false,
      customClass: {
         icon: "my-2 mx-auto",
      },
   });-*/

   return (
      <>
         <div
            className="app-main flex-column flex-row-fluid"
            style={{ height: "calc(100vh - 74px)" }}
         >



            {/*<div className="app-container container-xxl h-100 d-flex">
                     <div className="d-flex flex-column flex-lg-row-fluid py-10">
                        <div className="d-flex flex-center flex-column flex-column-fluid">
                           <div className="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto">
                              <form className="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework" id="kt_create_account_form">
                                 <div className="current" data-kt-stepper-element="content">

                                    <div className="w-100">

                                       <div className="pb-10 pb-lg-15">
                                          <h2 className="fw-bold d-flex align-items-center text-gray-900">
                                             Criar metodo de pagamento

                                             <span className="ms-1" data-bs-toggle="tooltip" aria-label="Billing is issued based on your selected account typ" data-bs-original-title="Billing is issued based on your selected account typ" data-kt-initialized="1">
                                                <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                   <span className="path1"></span>
                                                   <span className="path2"></span>
                                                   <span className="path3"></span>
                                                </i>
                                             </span>
                                          </h2>

                                          <div className="text-muted fw-semibold fs-6">
                                             Selecione o meio de pagamento que deseja utilizar.
                                          </div>
                                       </div>

                                       <div className="fv-row fv-plugins-icon-container">
                                          <div className="mb-0">
                                             <label className="d-flex flex-stack mb-5 cursor-pointer">
                                                <span className="d-flex align-items-center me-2">
                                                   <span className="symbol symbol-50px me-6">
                                                      <span className="symbol-label">
                                                         <i className="ki-duotone ki-bank fs-1 text-gray-600"><span className="path1"></span><span className="path2"></span></i>                        </span>
                                                   </span>
                                                   <span className="d-flex flex-column">
                                                      <span className="fw-bold text-gray-800 text-hover-primary fs-5">Boleto com pix</span>
                                                      <span className="fs-6 fw-semibold text-muted">Geração do instrumento de pagamento.</span>
                                                   </span>
                                                </span>
                                                <span className="form-check form-check-custom form-check-solid">
                                                   <input className="form-check-input" type="radio" name="account_plan" value="1" />
                                                </span>
                                             </label>

                                             <label className="d-flex flex-stack mb-5 cursor-pointer">
                                                <span className="d-flex align-items-center me-2">
                                                   <span className="symbol symbol-50px me-6">
                                                      <span className="symbol-label">
                                                         <i className="ki-duotone ki-chart fs-1 text-gray-600"><span className="path1"></span><span className="path2"></span></i>
                                                      </span>
                                                   </span>
                                                   <span className="d-flex flex-column">
                                                      <span className="fw-bold text-gray-800 text-hover-primary fs-5">Pix</span>
                                                      <span className="fs-6 fw-semibold text-muted">Geração do QRcode e copia e cola.</span>
                                                   </span>
                                                </span>
                                                <span className="form-check form-check-custom form-check-solid">
                                                   <input className="form-check-input" type="radio" name="account_plan" value="2" />
                                                </span>
                                             </label>
                                             <label className="d-flex flex-stack mb-0 cursor-pointer">
                                                <span className="d-flex align-items-center me-2">
                                                   <span className="symbol symbol-50px me-6">
                                                      <span className="symbol-label">
                                                         <i className="ki-duotone ki-chart-pie-4 fs-1 text-gray-600"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
                                                      </span>
                                                   </span>
                                                   <span className="d-flex flex-column">
                                                      <span className="fw-bold text-gray-800 text-hover-primary fs-5">Boleto</span>
                                                      <span className="fs-6 fw-semibold text-muted">Geração do instrumento de pagamento.</span>
                                                   </span>
                                                </span>
                                                <span className="form-check form-check-custom form-check-solid">
                                                   <input className="form-check-input" type="radio" name="account_plan" value="3" />
                                                </span>

                                             </label>

                                          </div>
                                          
                                       </div>
                                    </div>
                                 </div>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>*/}

            <div className="d-flex flex-column flex-column-fluid">


               <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">


                  <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">




                     <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">

                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                           Gerar instrumento de pagamento
                        </h1>




                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

                           <li className="breadcrumb-item text-muted">
                              <a href="/metronic8/demo1/index.html" className="text-muted text-hover-primary">
                                 Home
                              </a>
                           </li>


                           <li className="breadcrumb-item">
                              <span className="bullet bg-gray-500 w-5px h-2px"></span>
                           </li>



                           <li className="breadcrumb-item text-muted">
                              Account
                           </li>


                        </ul>

                     </div>


                     <div className="d-flex align-items-center gap-2 gap-lg-3">

                        <div className="m-0">

                           <a href="#" className="btn btn-sm btn-flex btn-secondary fw-bold" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                              <i className="ki-duotone ki-filter fs-6 text-muted me-1"><span className="path1"></span><span className="path2"></span></i>
                              Filter
                           </a>





                           <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_667eaf60c061d">

                              <div className="px-7 py-5">
                                 <div className="fs-5 text-gray-900 fw-bold">Filter Options</div>
                              </div>



                              <div className="separator border-gray-200"></div>


                              <div className="px-7 py-5">

                                 <div className="mb-10">

                                    <label className="form-label fw-semibold">Status:</label>

                                    <div>
                                       <select className="form-select form-select-solid select2-hidden-accessible">
                                          <option></option>
                                          <option value="1">Approved</option>
                                          <option value="2">Pending</option>
                                          <option value="2">In Process</option>
                                          <option value="2">Rejected</option>
                                       </select>
                                       <span className="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-8-xz17" style={{ width: "100%" }}>
                                          <span className="selection">
                                             <span className="select2-selection select2-selection--multiple form-select form-select-solid">
                                                <ul className="select2-selection__rendered" id="select2-6jxg-container"></ul><span className="select2-search select2-search--inline">
                                                   <textarea className="select2-search__field" tabIndex={0} placeholder="Select option" style={{ width: "100%" }}>
                                                   </textarea>
                                                </span>
                                             </span>
                                          </span>
                                          <span className="dropdown-wrapper" aria-hidden="true"></span>
                                       </span>
                                    </div>

                                 </div>



                                 <div className="mb-10">

                                    <label className="form-label fw-semibold">Member Type:</label>



                                    <div className="d-flex">

                                       <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                                          <input className="form-check-input" type="checkbox" value="1" />
                                          <span className="form-check-label">
                                             Author
                                          </span>
                                       </label>



                                       <label className="form-check form-check-sm form-check-custom form-check-solid">
                                          <input className="form-check-input" type="checkbox" value="2" />
                                          <span className="form-check-label">
                                             Customer
                                          </span>
                                       </label>

                                    </div>

                                 </div>



                                 <div className="mb-10">

                                    <label className="form-label fw-semibold">Notifications:</label>



                                    <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                       <input className="form-check-input" type="checkbox" value="" name="notifications" />
                                       <label className="form-check-label">
                                          Enabled
                                       </label>
                                    </div>

                                 </div>



                                 <div className="d-flex justify-content-end">
                                    <button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>

                                    <button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                                 </div>

                              </div>

                           </div>
                        </div>







                        <a href="#" className="btn btn-sm fw-bold btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">
                           Create        </a>

                     </div>

                  </div>

               </div>



               <div id="kt_app_content" className="app-content  flex-column-fluid ">



                  <div id="kt_app_content_container" className="app-container  container-xxl ">















                     <div className="card ">

                        <div className="card-header card-header-stretch border-bottom border-gray-200">

                           <div className="card-title">
                              <h3 className="fw-bold m-0">Vendas</h3>
                           </div>



                           <div className="card-toolbar m-0">

                              <ul className="nav nav-stretch nav-line-tabs border-transparent" role="tablist">

                                 <li className="nav-item" role="presentation">
                                    <a id="kt_billing_6months_tab" className="nav-link fs-5 fw-semibold me-3 active" data-bs-toggle="tab" role="tab" href="#kt_billing_months" aria-selected="true">
                                       Month
                                    </a>
                                 </li>



                                 <li className="nav-item" role="presentation">
                                    <a id="kt_billing_1year_tab" className="nav-link fs-5 fw-semibold me-3" data-bs-toggle="tab" role="tab" href="#kt_billing_year" >
                                       Year
                                    </a>
                                 </li>



                                 <li className="nav-item" role="presentation">
                                    <a id="kt_billing_alltime_tab" className="nav-link fs-5 fw-semibold" data-bs-toggle="tab" role="tab" href="#kt_billing_all">
                                       All Time
                                    </a>
                                 </li>

                              </ul>

                           </div>

                        </div>



                        <div className="tab-content">

                           <div id="kt_billing_months" className="card-body p-0 tab-pane fade show active" role="tabpanel" aria-labelledby="kt_billing_months">

                              <div className="table-responsive">

                                 <table className="table table-row-bordered align-middle gy-4 gs-9">
                                    <thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75">
                                       <tr>
                                          <td className="min-w-100px"></td>
                                          <td className="min-w-150px">Numero</td>
                                          <td className="min-w-150px">Status</td>
                                          <td className="min-w-150px">Valor</td>
                                          <td></td>
                                       </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-600">

                                       {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
                                          <tr>
                                             <td>
                                                <label className="form-check form-check-custom form-check-inline form-check-solid  is-valid m-0">
                                                   <input className="form-check-input" name="" type="checkbox" value="1" />
                                                </label>
                                             </td>
                                             <td>#{number}</td>
                                             <td>Não pago</td>
                                             <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(123.79 * number)}</td>
                                             <td>
                                                <a href="#" className="btn btn-sm btn-light btn-active-light-primary me-5">Informações da venda</a>
                                                <a href="#" className="btn btn-sm btn-light btn-active-light-primary me-5">NFE Não emitida</a>
                                                <a href="#" className="btn btn-sm btn-light btn-active-light-primary">Desfazer venda</a>
                                             </td>
                                          </tr>
                                       ))}

                                    </tbody>
                                 </table>

                              </div>

                           </div>


                           <div id="kt_billing_year" className="card-body p-0 tab-pane fade " role="tabpanel" aria-labelledby="kt_billing_year">

                              <div className="table-responsive">

                                 <table className="table table-row-bordered align-middle gy-4 gs-9">
                                    <thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75">
                                       <tr>
                                          <td className="min-w-150px">Date</td>
                                          <td className="min-w-250px">Description</td>
                                          <td className="min-w-150px">Amount</td>
                                          <td className="min-w-150px">Invoice</td>
                                          <td></td>
                                       </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-600">

                                       <tr>
                                          <td>Dec 01, 2021</td>
                                          <td><a href="#">Billing for Ocrober 2024</a></td>
                                          <td>$250.79</td>
                                          <td><a href="#" className="btn btn-sm btn-light btn-active-light-primary">PDF</a></td>
                                          <td className="text-right"><a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a></td>
                                       </tr>


                                       <tr>
                                          <td>Oct 08, 2021</td>
                                          <td><a href="#">Statements for September 2024</a></td>
                                          <td>$98.03</td>
                                          <td><a href="#" className="btn btn-sm btn-light btn-active-light-primary">PDF</a></td>
                                          <td className="text-right"><a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a></td>
                                       </tr>


                                       <tr>
                                          <td>Aug 24, 2021</td>
                                          <td>Paypal</td>
                                          <td>$35.07</td>
                                          <td><a href="#" className="btn btn-sm btn-light btn-active-light-primary">PDF</a></td>
                                          <td className="text-right"><a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a></td>
                                       </tr>


                                       <tr>
                                          <td>Aug 01, 2021</td>
                                          <td><a href="#">Invoice for July 2024</a></td>
                                          <td>$142.80</td>
                                          <td><a href="#" className="btn btn-sm btn-light btn-active-light-primary">PDF</a></td>
                                          <td className="text-right"><a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a></td>
                                       </tr>


                                       <tr>
                                          <td>Jul 01, 2021</td>
                                          <td><a href="#">Statements for June 2024</a></td>
                                          <td>$123.79</td>
                                          <td><a href="#" className="btn btn-sm btn-light btn-active-light-primary">PDF</a></td>
                                          <td className="text-right"><a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a></td>
                                       </tr>


                                       <tr>
                                          <td>Jun 17, 2021</td>
                                          <td>Paypal</td>
                                          <td>$23.09</td>
                                          <td><a href="#" className="btn btn-sm btn-light btn-active-light-primary">PDF</a></td>
                                          <td className="text-right"><a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a></td>
                                       </tr>

                                    </tbody>
                                 </table>

                              </div>

                           </div>


                           <div id="kt_billing_all" className="card-body p-0 tab-pane fade " role="tabpanel" aria-labelledby="kt_billing_all">

                              <div className="table-responsive">

                                 <table className="table table-row-bordered align-middle gy-4 gs-9">
                                    <thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75">
                                       <tr>
                                          <td className="min-w-150px">Date</td>
                                          <td className="min-w-250px">Description</td>
                                          <td className="min-w-150px">Amount</td>
                                          <td className="min-w-150px">Invoice</td>
                                          <td></td>
                                       </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-600">

                                       <tr>
                                          <td>Nov 01, 2021</td>
                                          <td><a href="#">Billing for Ocrober 2024</a></td>
                                          <td>$123.79</td>
                                          <td><a href="#" className="btn btn-sm btn-light btn-active-light-primary">PDF</a></td>
                                          <td className="text-right"><a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a></td>
                                       </tr>


                                       <tr>
                                          <td>Aug 10, 2021</td>
                                          <td>Paypal</td>
                                          <td>$35.07</td>
                                          <td><a href="#" className="btn btn-sm btn-light btn-active-light-primary">PDF</a></td>
                                          <td className="text-right"><a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a></td>
                                       </tr>


                                       <tr>
                                          <td>Aug 01, 2021</td>
                                          <td><a href="#">Invoice for July 2024</a></td>
                                          <td>$142.80</td>
                                          <td><a href="#" className="btn btn-sm btn-light btn-active-light-primary">PDF</a></td>
                                          <td className="text-right"><a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a></td>
                                       </tr>


                                       <tr>
                                          <td>Jul 20, 2021</td>
                                          <td><a href="#">Statements for June 2024</a></td>
                                          <td>$123.79</td>
                                          <td><a href="#" className="btn btn-sm btn-light btn-active-light-primary">PDF</a></td>
                                          <td className="text-right"><a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a></td>
                                       </tr>


                                       <tr>
                                          <td>Jun 17, 2021</td>
                                          <td>Paypal</td>
                                          <td>$23.09</td>
                                          <td><a href="#" className="btn btn-sm btn-light btn-active-light-primary">PDF</a></td>
                                          <td className="text-right"><a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a></td>
                                       </tr>


                                       <tr>
                                          <td>Jun 01, 2021</td>
                                          <td><a href="#">Invoice for May 2024</a></td>
                                          <td>$123.79</td>
                                          <td><a href="#" className="btn btn-sm btn-light btn-active-light-primary">PDF</a></td>
                                          <td className="text-right"><a href="#" className="btn btn-sm btn-light btn-active-light-primary">View</a></td>
                                       </tr>

                                    </tbody>
                                 </table>

                              </div>

                           </div>

                        </div>

                     </div>
                  </div>

               </div>


            </div>
            <div className="app-footer">
               <div className="app-container  container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-gray-900 order-2 order-md-1">
                     <span className="text-muted fw-semibold me-1">2023©</span>
                     <a
                        href="#"
                        target="_blank"
                        className="text-gray-800 text-hover-primary"
                     >
                        Pommering
                     </a>
                  </div>
               </div>
            </div>{" "}
         </div>

         {modelProduct && (
            <div
               className={`modal fade ${modelProduct ? "show d-block" : "d-none"
                  }`}
            >
               <div className="modal-dialog mw-650px">
                  <div className="modal-content">
                     <div className="modal-header pb-0 border-0 justify-content-end">
                        <div
                           className="btn btn-sm btn-icon btn-active-color-primary"
                           onClick={() => {
                              setModelProduct(() => false);
                           }}
                        >
                           <i className="ki-duotone ki-cross fs-1">
                              <span className="path1"></span>
                              <span className="path2"></span>
                           </i>{" "}
                        </div>
                     </div>

                     <div className="modal-body mx-5 mx-xl-18 pt-0 pb-15">
                        <div className="text-center mb-13">
                           <h1 className="mb-3">Produtos</h1>

                           <div className="text-muted fw-semibold fs-5">
                              Selecione um produto existente ou crie um novo.
                           </div>
                        </div>

                        <div className="btn btn-light-primary fw-bold w-100 mb-8" onClick={() => {
                           setModelProduct(() => false);
                           setModelProductRegisterNew(() => true);
                        }}>
                           <i className="ki-duotone ki-add-item fs-2 me-1">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                           </i>
                           Cadastrar novo produto
                        </div>

                        <div className="separator d-flex flex-center mb-8">
                           <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">
                              ou
                           </span>
                        </div>

                        <input
                           type="text"
                           className="search-input form-control form-control-solid mb-8"
                           name="search"
                           placeholder="Buscar produto..."

                        />

                        <div className="mb-7">
                           <div className="mh-350px scroll-y me-n7 pe-7">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
                                 <div
                                    key={number}
                                    className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed bg-hover-light"
                                 >
                                    <div className="d-flex align-items-center">
                                       <div className="symbol symbol-35px symbol-circle ms-3">
                                          <span className="symbol-label bg-light-danger text-danger fw-semibold">
                                             M{" "}
                                          </span>
                                       </div>

                                       <div className="ms-5">
                                          <a
                                             href="#"
                                             className="fs-5 fw-bold text-gray-900 mb-2"
                                          >
                                             Product name
                                          </a>

                                          <div className="fw-semibold text-muted">
                                             Fornecedor
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              ))}
                           </div>
                        </div>

                        <div className="d-flex flex-stack">
                           <div className="me-5 fw-semibold"></div>

                           <label className="form-check form-switch form-check-custom form-check-solid"></label>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}

         {modelProductRegisterNew && (
            <div
               className={`modal fade ${modelProductRegisterNew ? "show d-block" : "d-none"
                  }`}
            >
               <div className="modal-dialog mw-650px">
                  <div className="modal-content">
                     <div className="modal-header pb-0 border-0 justify-content-end">
                        <div
                           className="btn btn-sm btn-icon btn-active-color-primary"
                           onClick={() => {
                              setModelProductRegisterNew(() => false);
                           }}
                        >
                           <i className="ki-duotone ki-cross fs-1">
                              <span className="path1"></span>
                              <span className="path2"></span>
                           </i>{" "}
                        </div>
                     </div>

                     <div className="modal-body mx-5 mx-xl-18 pt-0 pb-15">
                        <div className="text-center mb-13">
                           <h1 className="mb-3">Novo produtos</h1>

                           <div className="text-muted fw-semibold fs-5">
                              Cadastro de um novo produto.
                           </div>
                        </div>
                        <div className="mb-8 d-flex" style={{ justifyContent: 'center', flexDirection: "column", alignItems: "center" }}>

                           <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: `url(${(image)})` }}>

                              <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: `url(${(image)})` }}></div>



                              <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" aria-label="Change avatar" data-bs-original-title="Change avatar" data-kt-initialized="1">
                                 <i className="ki-duotone ki-pencil fs-7"><span className="path1"></span><span className="path2"></span></i>

                                 <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                 <input type="hidden" name="avatar_remove" />

                              </label>



                              <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" aria-label="Cancel avatar" data-bs-original-title="Cancel avatar" data-kt-initialized="1">
                                 <i className="ki-duotone ki-cross fs-2"><span className="path1"></span><span className="path2"></span></i>                            </span>



                              <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" aria-label="Remove avatar" data-bs-original-title="Remove avatar" data-kt-initialized="1">
                                 <i className="ki-duotone ki-cross fs-2"><span className="path1"></span><span className="path2"></span></i>                            </span>

                           </div>



                           <div className="form-text mt-5">Arquivos permitidos:  png, jpg, jpeg.</div>

                        </div>

                        <input
                           type="text"
                           className="search-input form-control form-control-solid mb-8"
                           name="name_product"
                           placeholder="Nome do produto"
                        />
                        <input
                           type="text"
                           className="search-input form-control form-control-solid mb-8"
                           name="unity_measure"
                           placeholder="Unidade de medida"
                        />
                        <div className="mb-8 d-flex flex-wrap gap-5">
                           <div className="fv-row w-100 flex-md-root">
                              <input
                                 type="text"
                                 className="search-input form-control form-control-solid "
                                 name="height"
                                 placeholder="Altura"
                              />
                           </div>

                           <div className="fv-row w-100 flex-md-root">
                              <input
                                 type="text"
                                 className="search-input form-control form-control-solid "
                                 name="width"
                                 placeholder="Largura"
                              />
                           </div>

                           <div className="fv-row w-100 flex-md-root">
                              <input
                                 type="text"
                                 className="search-input form-control form-control-solid "
                                 name="length"
                                 placeholder="Comprimento"
                              />
                           </div>

                        </div>


                        <textarea
                           className="search-input form-control form-control-solid mb-8"
                           name="description"
                           placeholder="Descrição do produto"
                           rows={5}
                           style={{ resize: "none" }}
                        />

                        <div
                           className="w-100 d-flex mt-10"
                           style={{
                              justifyContent:
                                 "space-between",
                           }}
                        >
                           <div
                              onClick={() => {
                                 setModelProductRegisterNew(() => false);
                                 setModelProduct(() => true);

                              }}
                              className="btn btn-light btn-active-light-primary d-flex w-175px"
                              style={{
                                 justifyContent:
                                    "center",
                                 alignItems:
                                    "center",
                              }}
                           >
                              Voltar
                           </div>
                           <button
                              type="submit"
                              className="btn btn-primary d-flex w-175px"
                              style={{
                                 justifyContent:
                                    "center",
                                 alignItems:
                                    "center",
                              }}
                           >
                              Cadastrar
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}


         {(modelProduct || modelProductRegisterNew) && <div className={`modal-backdrop fade show`}></div>}
      </>
   );
}

export { CreatePayment };
