function Home() {
   return (
      <div className="app-main flex-column flex-row-fluid">
         <div className="d-flex flex-column flex-column-fluid">
            <div className="app-toolbar  py-3 py-lg-6 ">
               <div className="app-container  container-xxl d-flex flex-stack ">
                  <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                     <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                        Inicio
                     </h1>
                     <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                           <a
                              href="#"
                              className="text-muted text-hover-primary"
                           >
                              inicio{" "}
                           </a>
                        </li>
                     </ul>
                  </div>

                  <div className="d-flex align-items-center gap-2 gap-lg-3">
                     <a href="#" className="btn btn-sm fw-bold btn-secondary">
                        Gerenciar Vendas{" "}
                     </a>

                     <a href="#" className="btn btn-sm fw-bold btn-primary">
                        Adicionar Produto{" "}
                     </a>
                  </div>
               </div>
            </div>

            <div className="app-content flex-column-fluid">
               <div className="app-container container-xxl">
                  <div className="row g-5 g-xl-10 mb-xl-10">
                     <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                        <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                           <div className="card-header pt-5">
                              <div className="card-title d-flex flex-column">
                                 <div className="d-flex align-items-center">
                                    <span className="fs-4 fw-semibold text-gray-500 me-1 align-self-start">
                                       R$
                                    </span>

                                    <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
                                       69.700
                                    </span>

                                    <span className="badge badge-light-success fs-base">
                                       <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                                          <span className="path1" />
                                          <span className="path2" />
                                       </i>
                                       2.2%
                                    </span>
                                 </div>

                                 <span className="text-gray-500 pt-1 fw-semibold fs-6">
                                    Ganhos esperados
                                 </span>
                              </div>
                           </div>

                           <div className="card-body pt-2 pb-4 d-flex align-items-center">
                              <div className="d-flex flex-center me-5 pt-2">
                                 <div
                                    style={{
                                       minWidth: 70,
                                       minHeight: 70,
                                    }}
                                    data-kt-size={70}
                                    data-kt-line={11}
                                 >
                                    <span />
                                    <canvas height={70} width={70} />
                                 </div>
                              </div>

                              <div className="d-flex flex-column content-justify-center w-100">
                                 <div className="d-flex fs-6 fw-semibold align-items-center">
                                    <div className="bullet w-8px h-6px rounded-2 bg-danger me-3" />

                                    <div className="text-gray-500 flex-grow-1 me-4">
                                       Bombas
                                    </div>

                                    <div className="fw-bolder text-gray-700 text-xxl-end">
                                       R$7.660
                                    </div>
                                 </div>

                                 <div className="d-flex fs-6 fw-semibold align-items-center my-3">
                                    <div className="bullet w-8px h-6px rounded-2 bg-primary me-3" />

                                    <div className="text-gray-500 flex-grow-1 me-4">
                                       Pistões
                                    </div>

                                    <div className="fw-bolder text-gray-700 text-xxl-end">
                                       R$2.820
                                    </div>
                                 </div>

                                 <div className="d-flex fs-6 fw-semibold align-items-center">
                                    <div
                                       className="bullet w-8px h-6px rounded-2 me-3"
                                       style={{
                                          backgroundColor: "#E4E6EF",
                                       }}
                                    />

                                    <div className="text-gray-500 flex-grow-1 me-4">
                                       Outros
                                    </div>

                                    <div className=" fw-bolder text-gray-700 text-xxl-end">
                                       R$45.257
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="card card-flush h-md-50 mb-xl-10">
                           <div className="card-header pt-5">
                              <div className="card-title d-flex flex-column">
                                 <div className="d-flex align-items-center">
                                    <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
                                       1.836
                                    </span>

                                    <span className="badge badge-light-danger fs-base">
                                       <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
                                          <span className="path1" />
                                          <span className="path2" />
                                       </i>
                                       2.2%
                                    </span>
                                 </div>

                                 <span className="text-gray-500 pt-1 fw-semibold fs-6">
                                    Pedidos este mês
                                 </span>
                              </div>
                           </div>

                           <div className="card-body d-flex align-items-end pt-0">
                              <div className="d-flex align-items-center flex-column mt-3 w-100">
                                 <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                    <span className="fw-bolder fs-6 text-gray-900">
                                       1.048 para meta
                                    </span>
                                    <span className="fw-bold fs-6 text-gray-500">
                                       62%
                                    </span>
                                 </div>
                                 <div className="h-8px mx-3 w-100 bg-light-success rounded">
                                    <div
                                       className="bg-success rounded h-8px"
                                       role="progressbar"
                                       style={{ width: "62%" }}
                                       aria-valuenow={50}
                                       aria-valuemin={0}
                                       aria-valuemax={100}
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>{" "}
                     </div>

                     <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                        <div className="card card-flush  h-md-50 mb-5 mb-xl-10">
                           <div className="card-header pt-5">
                              <div className="card-title d-flex flex-column">
                                 <div className="d-flex align-items-center">
                                    <span className="fs-4 fw-semibold text-gray-500 me-1 align-self-start">
                                       R$
                                    </span>

                                    <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
                                       2.420
                                    </span>

                                    <span className="badge badge-light-success fs-base">
                                       <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                                          <span className="path1" />
                                          <span className="path2" />
                                       </i>
                                       2.6%
                                    </span>
                                 </div>

                                 <span className="text-gray-500 pt-1 fw-semibold fs-6">
                                    Vendas Médias Diárias
                                 </span>
                              </div>
                           </div>

                           <div className="card-body d-flex align-items-end px-0 pb-0">
                              <div
                                 className="w-100"
                                 style={{
                                    height: 80,
                                    minHeight: 80,
                                 }}
                              >
                                 <div
                                    className="apexcharts-canvas apexchartsud8pg1b apexcharts-theme-light"
                                    style={{
                                       width: 289,
                                       height: 80,
                                    }}
                                 >
                                    <div className="apexcharts-tooltip apexcharts-theme-light">
                                       <div
                                          className="apexcharts-tooltip-title"
                                          style={{
                                             fontFamily: "inherit",
                                             fontSize: 12,
                                          }}
                                       />
                                       <div
                                          className="apexcharts-tooltip-series-group"
                                          style={{ order: 1 }}
                                       >
                                          <span
                                             className="apexcharts-tooltip-marker"
                                             style={{
                                                backgroundColor:
                                                   "rgb(0, 106, 230)",
                                             }}
                                          />
                                          <div
                                             className="apexcharts-tooltip-text"
                                             style={{
                                                fontFamily: "inherit",
                                                fontSize: 12,
                                             }}
                                          >
                                             <div className="apexcharts-tooltip-y-group">
                                                <span className="apexcharts-tooltip-text-y-label" />
                                                <span className="apexcharts-tooltip-text-y-value" />
                                             </div>
                                             <div className="apexcharts-tooltip-goals-group">
                                                <span className="apexcharts-tooltip-text-goals-label" />
                                                <span className="apexcharts-tooltip-text-goals-value" />
                                             </div>
                                             <div className="apexcharts-tooltip-z-group">
                                                <span className="apexcharts-tooltip-text-z-label" />
                                                <span className="apexcharts-tooltip-text-z-value" />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                                       <div className="apexcharts-yaxistooltip-text" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="card card-flush h-md-50 mb-xl-10">
                           <div className="card-header pt-5">
                              <div className="card-title d-flex flex-column">
                                 <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
                                    6.3 mil
                                 </span>

                                 <span className="text-gray-500 pt-1 fw-semibold fs-6">
                                    Novos clientes este mês
                                 </span>
                              </div>
                           </div>

                           <div className="card-body d-flex flex-column justify-content-end pe-0">
                              <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">
                                 Vendas Hoje
                              </span>

                              <div className="symbol-group symbol-hover flex-nowrap">
                                 <div
                                    className="symbol symbol-35px symbol-circle"
                                    data-bs-toggle="tooltip"
                                    data-bs-original-title="Alan Warden"
                                    data-kt-initialized={1}
                                 >
                                    <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                       A
                                    </span>
                                 </div>

                                 <div
                                    className="symbol symbol-35px symbol-circle"
                                    data-bs-toggle="tooltip"
                                    data-bs-original-title="Susan Redwood"
                                    data-kt-initialized={1}
                                 >
                                    <span className="symbol-label bg-primary text-inverse-primary fw-bold">
                                       S
                                    </span>
                                 </div>

                                 <div
                                    className="symbol symbol-35px symbol-circle"
                                    data-bs-toggle="tooltip"
                                    data-bs-original-title="Perry Matthew"
                                    data-kt-initialized={1}
                                 >
                                    <span className="symbol-label bg-danger text-inverse-danger fw-bold">
                                       P
                                    </span>
                                 </div>

                                 <a
                                    href="#"
                                    className="symbol symbol-35px symbol-circle"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_view_users"
                                 >
                                    <span className="symbol-label bg-light text-gray-400 fs-8 fw-bold">
                                       +42
                                    </span>
                                 </a>
                              </div>
                           </div>
                        </div>{" "}
                     </div>

                     <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
                        <div className="card card-flush overflow-hidden h-md-100">
                           <div className="card-header py-5">
                              <h3 className="card-title align-items-start flex-column">
                                 <span className="card-label fw-bold text-gray-900">
                                    Vendas nestes meses
                                 </span>
                                 <span className="text-gray-500 mt-1 fw-semibold fs-6">
                                    Usuários de todos os canais
                                 </span>
                              </h3>

                              <div className="card-toolbar">
                                 <button
                                    className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    data-kt-menu-overflow="true"
                                 >
                                    <i className="ki-duotone ki-dots-square fs-1">
                                       <span className="path1" />
                                       <span className="path2" />
                                       <span className="path3" />
                                       <span className="path4" />
                                    </i>
                                 </button>

                                 <div
                                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                    data-kt-menu="true"
                                 >
                                    <div className="menu-item px-3">
                                       <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">
                                          Quick Actions
                                       </div>
                                    </div>

                                    <div className="separator mb-3 opacity-75" />

                                    <div className="menu-item px-3">
                                       <a href="#" className="menu-link px-3">
                                          New Ticket
                                       </a>
                                    </div>

                                    <div className="menu-item px-3">
                                       <a href="#" className="menu-link px-3">
                                          New Customer
                                       </a>
                                    </div>

                                    <div
                                       className="menu-item px-3"
                                       data-kt-menu-trigger="hover"
                                       data-kt-menu-placement="right-start"
                                    >
                                       <a href="#" className="menu-link px-3">
                                          <span className="menu-title">
                                             New Group
                                          </span>
                                          <span className="menu-arrow" />
                                       </a>

                                       <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                          <div className="menu-item px-3">
                                             <a
                                                href="#"
                                                className="menu-link px-3"
                                             >
                                                Admin Group
                                             </a>
                                          </div>

                                          <div className="menu-item px-3">
                                             <a
                                                href="#"
                                                className="menu-link px-3"
                                             >
                                                Staff Group
                                             </a>
                                          </div>

                                          <div className="menu-item px-3">
                                             <a
                                                href="#"
                                                className="menu-link px-3"
                                             >
                                                Member Group
                                             </a>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="menu-item px-3">
                                       <a href="#" className="menu-link px-3">
                                          New Contact
                                       </a>
                                    </div>

                                    <div className="separator mt-3 opacity-75" />

                                    <div className="menu-item px-3">
                                       <div className="menu-content px-3 py-3">
                                          <a
                                             className="btn btn-primary  btn-sm px-4"
                                             href="#"
                                          >
                                             Generate Reports
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">
                              <div className="px-9 mb-5">
                                 <div className="d-flex mb-2">
                                    <span className="fs-4 fw-semibold text-gray-500 me-1">
                                       R$
                                    </span>
                                    <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">
                                       14.094
                                    </span>
                                 </div>

                                 <span className="fs-6 fw-semibold text-gray-500">
                                    Faltam R$ 48.346,00 para a meta
                                 </span>
                              </div>

                              <div
                                 className="min-h-auto ps-4 pe-6"
                                 style={{
                                    height: 300,
                                    minHeight: 315,
                                 }}
                              >
                                 <div
                                    className="apexcharts-canvas apexchartsswyyg5otg apexcharts-theme-light"
                                    style={{
                                       width: "579.5px",
                                       height: 300,
                                    }}
                                 >
                                    <div
                                       className="apexcharts-tooltip apexcharts-theme-light"
                                       style={{
                                          left: "106.426px",
                                          top: "98.0657px",
                                       }}
                                    >
                                       <div
                                          className="apexcharts-tooltip-title"
                                          style={{
                                             fontFamily: "inherit",
                                             fontSize: 12,
                                          }}
                                       >
                                          Apr 02
                                       </div>
                                       <div
                                          className="apexcharts-tooltip-series-group apexcharts-active"
                                          style={{
                                             order: 1,
                                             display: "flex",
                                          }}
                                       >
                                          <span
                                             className="apexcharts-tooltip-marker"
                                             style={{
                                                backgroundColor:
                                                   "rgb(0, 162, 97)",
                                             }}
                                          />
                                          <div
                                             className="apexcharts-tooltip-text"
                                             style={{
                                                fontFamily: "inherit",
                                                fontSize: 12,
                                             }}
                                          >
                                             <div className="apexcharts-tooltip-y-group">
                                                <span className="apexcharts-tooltip-text-y-label">
                                                   Sales:{" "}
                                                </span>
                                                <span className="apexcharts-tooltip-text-y-value">
                                                   $18K
                                                </span>
                                             </div>
                                             <div className="apexcharts-tooltip-goals-group">
                                                <span className="apexcharts-tooltip-text-goals-label" />
                                                <span className="apexcharts-tooltip-text-goals-value" />
                                             </div>
                                             <div className="apexcharts-tooltip-z-group">
                                                <span className="apexcharts-tooltip-text-z-label" />
                                                <span className="apexcharts-tooltip-text-z-value" />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div
                                       className="apexcharts-xaxistooltip apexcharts-xaxistooltip-bottom apexcharts-theme-light"
                                       style={{
                                          left: "65.9108px",
                                          top: "253.82px",
                                       }}
                                    >
                                       <div
                                          className="apexcharts-xaxistooltip-text"
                                          style={{
                                             fontFamily: "inherit",
                                             fontSize: 12,
                                             minWidth: "35.2031px",
                                          }}
                                       >
                                          Apr 02
                                       </div>
                                    </div>
                                    <div className="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                                       <div className="apexcharts-yaxistooltip-text" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>{" "}
                     </div>
                  </div>
                  <div className="row gy-5 g-xl-10">
                     <div className="col-xl-6 mb-xl-10">
                        <div className="card h-md-100">
                           <div className="card-header align-items-center border-0">
                              <h3 className="fw-bold text-gray-900 m-0">
                                 Pedidos recentes
                              </h3>
                           </div>

                           <div className="card-body pt-2">
                              <div className="tab-content">
                                 <div className="tab-pane fade show active">
                                    <div className="table-responsive">
                                       <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                          <thead>
                                             <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                                                <th className="ps-0 w-50px">
                                                   Produto
                                                </th>
                                                <th className="min-w-125px" />
                                                <th className="text-end min-w-100px">
                                                   Quantidade
                                                </th>
                                                <th className="pe-0 text-end min-w-100px">
                                                   Preço
                                                </th>
                                                <th className="pe-0 text-end min-w-100px">
                                                   Preço Total
                                                </th>
                                             </tr>
                                          </thead>

                                          <tbody>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/210.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      Elephant 1802
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-2347
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x1
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      R$ 72,00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      R$ 126,00
                                                   </span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/215.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      Red Laga
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-1321
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x2
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      R$ 45,00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      R$ 76,00
                                                   </span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/209.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      RiseUP
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-4312
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x3
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      R$ 84,00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      R$ 168,00
                                                   </span>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>

                                 <div className="tab-pane fade">
                                    <div className="table-responsive">
                                       <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                          <thead>
                                             <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                                                <th className="ps-0 w-50px">
                                                   Produto
                                                </th>
                                                <th className="min-w-125px" />
                                                <th className="text-end min-w-100px">
                                                   Quantidade
                                                </th>
                                                <th className="pe-0 text-end min-w-100px">
                                                   Preço
                                                </th>
                                                <th className="pe-0 text-end min-w-100px">
                                                   Preço total
                                                </th>
                                             </tr>
                                          </thead>

                                          <tbody>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/197.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      Elephant 1802
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-4312
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x1
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $32.00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $312.00
                                                   </span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/178.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      Red Laga
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-3122
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x2
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $53.00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $62.00
                                                   </span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/22.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      RiseUP
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-1142
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x3
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $74.00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $139.00
                                                   </span>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>

                                 <div className="tab-pane fade">
                                    <div className="table-responsive">
                                       <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                          <thead>
                                             <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                                                <th className="ps-0 w-50px">
                                                   Produto
                                                </th>
                                                <th className="min-w-125px" />
                                                <th className="text-end min-w-100px">
                                                   Quantidade
                                                </th>
                                                <th className="pe-0 text-end min-w-100px">
                                                   Preço
                                                </th>
                                                <th className="pe-0 text-end min-w-100px">
                                                   Preço Total
                                                </th>
                                             </tr>
                                          </thead>

                                          <tbody>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/1.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      Elephant 1324
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-1523
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x1
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $43.00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $231.00
                                                   </span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/24.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      Red Laga
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-5314
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x2
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $71.00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $53.00
                                                   </span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/71.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      RiseUP
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-4222
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x3
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $23.00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $213.00
                                                   </span>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>

                                 <div className="tab-pane fade ">
                                    <div className="table-responsive">
                                       <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                          <thead>
                                             <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                                                <th className="ps-0 w-50px">
                                                   ITEM
                                                </th>
                                                <th className="min-w-125px" />
                                                <th className="text-end min-w-100px">
                                                   Quantidade
                                                </th>
                                                <th className="pe-0 text-end min-w-100px">
                                                   Preço
                                                </th>
                                                <th className="pe-0 text-end min-w-100px">
                                                   Preço Total
                                                </th>
                                             </tr>
                                          </thead>

                                          <tbody>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/41.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      Elephant 2635
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-1523
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x1
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $65.00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $163.00
                                                   </span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/63.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      Red Laga
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-2745
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x2
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $64.00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $73.00
                                                   </span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/59.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      RiseUP
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-5173
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x3
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $54.00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $173.00
                                                   </span>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>

                                 <div className="tab-pane fade ">
                                    <div className="table-responsive">
                                       <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                          <thead>
                                             <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                                                <th className="ps-0 w-50px">
                                                   ITEM
                                                </th>
                                                <th className="min-w-125px" />
                                                <th className="text-end min-w-100px">
                                                   Quantidade
                                                </th>
                                                <th className="pe-0 text-end min-w-100px">
                                                   Preço
                                                </th>
                                                <th className="pe-0 text-end min-w-100px">
                                                   Preço Total
                                                </th>
                                             </tr>
                                          </thead>

                                          <tbody>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/10.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      Nike
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-2163
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x1
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $64.00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $287.00
                                                   </span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/96.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      Adidas
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-2162
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x2
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $76.00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $51.00
                                                   </span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <img
                                                      src="/metronic8/demo1/assets/media/stock/ecommerce/13.png"
                                                      className="w-50px ms-n1"
                                                   />
                                                </td>
                                                <td className="ps-0">
                                                   <a
                                                      href="#"
                                                      className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0"
                                                   >
                                                      Puma
                                                   </a>
                                                   <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                                      Item: #XDG-1537
                                                   </span>
                                                </td>
                                                <td>
                                                   <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">
                                                      x3
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $27.00
                                                   </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                   <span className="text-gray-800 fw-bold d-block fs-6">
                                                      $167.00
                                                   </span>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>{" "}
                     </div>

                     <div className="col-xl-6 mb-5 mb-xl-10">
                        <div className="card card-flush overflow-hidden h-md-100">
                           <div className="card-header py-5">
                              <h3 className="card-title align-items-start flex-column">
                                 <span className="card-label fw-bold text-gray-900">
                                    Vendas de produtos com desconto
                                 </span>
                                 <span className="text-gray-500 mt-1 fw-semibold fs-6">
                                    Usuários de todos os canais
                                 </span>
                              </h3>

                              <div className="card-toolbar">
                                 <button
                                    className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    data-kt-menu-overflow="true"
                                 >
                                    <i className="ki-duotone ki-dots-square fs-1">
                                       <span className="path1" />
                                       <span className="path2" />
                                       <span className="path3" />
                                       <span className="path4" />
                                    </i>
                                 </button>

                                 <div
                                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                    data-kt-menu="true"
                                 >
                                    <div className="menu-item px-3">
                                       <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">
                                          Quick Actions
                                       </div>
                                    </div>

                                    <div className="separator mb-3 opacity-75" />

                                    <div className="menu-item px-3">
                                       <a href="#" className="menu-link px-3">
                                          New Ticket
                                       </a>
                                    </div>

                                    <div className="menu-item px-3">
                                       <a href="#" className="menu-link px-3">
                                          New Customer
                                       </a>
                                    </div>

                                    <div
                                       className="menu-item px-3"
                                       data-kt-menu-trigger="hover"
                                       data-kt-menu-placement="right-start"
                                    >
                                       <a href="#" className="menu-link px-3">
                                          <span className="menu-title">
                                             New Group
                                          </span>
                                          <span className="menu-arrow" />
                                       </a>

                                       <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                          <div className="menu-item px-3">
                                             <a
                                                href="#"
                                                className="menu-link px-3"
                                             >
                                                Admin Group
                                             </a>
                                          </div>

                                          <div className="menu-item px-3">
                                             <a
                                                href="#"
                                                className="menu-link px-3"
                                             >
                                                Staff Group
                                             </a>
                                          </div>

                                          <div className="menu-item px-3">
                                             <a
                                                href="#"
                                                className="menu-link px-3"
                                             >
                                                Member Group
                                             </a>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="menu-item px-3">
                                       <a href="#" className="menu-link px-3">
                                          New Contact
                                       </a>
                                    </div>

                                    <div className="separator mt-3 opacity-75" />

                                    <div className="menu-item px-3">
                                       <div className="menu-content px-3 py-3">
                                          <a
                                             className="btn btn-primary  btn-sm px-4"
                                             href="#"
                                          >
                                             Generate Reports
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">
                              <div className="px-9 mb-5">
                                 <div className="d-flex align-items-center mb-2">
                                    <span className="fs-4 fw-semibold text-gray-500 align-self-start me-1">
                                       R$
                                    </span>

                                    <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">
                                       3.706
                                    </span>

                                    <span className="badge badge-light-success fs-base">
                                       <i className="ki-duotone ki-arrow-down fs-5 text-success ms-n1">
                                          <span className="path1" />
                                          <span className="path2" />
                                       </i>{" "}
                                       4.5%
                                    </span>
                                 </div>

                                 <span className="fs-6 fw-semibold text-gray-500">
                                    Total de vendas com desconto neste mês
                                 </span>
                              </div>

                              <div
                                 className="min-h-auto ps-4 pe-6"
                                 style={{
                                    height: 300,
                                    minHeight: 315,
                                 }}
                              >
                                 <div
                                    className="apexcharts-canvas apexcharts53a76xgef apexcharts-theme-light"
                                    style={{
                                       width: "579.5px",
                                       height: 300,
                                    }}
                                 >
                                    <div
                                       className="apexcharts-tooltip apexcharts-theme-light"
                                       style={{
                                          left: "96.5165px",
                                          top: "123.993px",
                                       }}
                                    >
                                       <div
                                          className="apexcharts-tooltip-title"
                                          style={{
                                             fontFamily: "inherit",
                                             fontSize: 12,
                                          }}
                                       >
                                          Apr 02
                                       </div>
                                       <div
                                          className="apexcharts-tooltip-series-group apexcharts-active"
                                          style={{
                                             order: 1,
                                             display: "flex",
                                          }}
                                       >
                                          <span
                                             className="apexcharts-tooltip-marker"
                                             style={{
                                                backgroundColor:
                                                   "rgb(0, 106, 230)",
                                             }}
                                          />
                                          <div
                                             className="apexcharts-tooltip-text"
                                             style={{
                                                fontFamily: "inherit",
                                                fontSize: 12,
                                             }}
                                          >
                                             <div className="apexcharts-tooltip-y-group">
                                                <span className="apexcharts-tooltip-text-y-label">
                                                   Sales:{" "}
                                                </span>
                                                <span className="apexcharts-tooltip-text-y-value">
                                                   $345
                                                </span>
                                             </div>
                                             <div className="apexcharts-tooltip-goals-group">
                                                <span className="apexcharts-tooltip-text-goals-label" />
                                                <span className="apexcharts-tooltip-text-goals-value" />
                                             </div>
                                             <div className="apexcharts-tooltip-z-group">
                                                <span className="apexcharts-tooltip-text-z-label" />
                                                <span className="apexcharts-tooltip-text-z-value" />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div
                                       className="apexcharts-xaxistooltip apexcharts-xaxistooltip-bottom apexcharts-theme-light"
                                       style={{
                                          left: "56.0009px",
                                          top: "253.82px",
                                       }}
                                    >
                                       <div
                                          className="apexcharts-xaxistooltip-text"
                                          style={{
                                             fontFamily: "inherit",
                                             fontSize: 12,
                                             minWidth: "35.2031px",
                                          }}
                                       >
                                          Apr 02
                                       </div>
                                    </div>
                                    <div className="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                                       <div className="apexcharts-yaxistooltip-text" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>{" "}
                     </div>
                  </div>
                  <div className="row gy-5 g-xl-10">
                     <div className="col-xl-12 mb-5 mb-xl-12">
                        <div className="card card-flush h-xl-100">
                           <div className="card-header pt-7">
                              <h3 className="card-title align-items-start flex-column">
                                 <span className="card-label fw-bold text-gray-800">
                                    Pedidos de produtos
                                 </span>
                                 <span className="text-gray-500 mt-1 fw-semibold fs-6">
                                    Média 57 pedidos por dia
                                 </span>
                              </h3>

                              <div className="card-toolbar">
                                 <div className="d-flex flex-stack flex-wrap gap-4">
                                    <div className="d-flex align-items-center fw-bold">
                                       <div className="text-gray-500 fs-7 me-2">
                                          Cateogry
                                       </div>

                                       <select
                                          className="form-select form-select-transparent text-graY-800 fs-base lh-1 fw-bold py-0 ps-3 w-auto select2-hidden-accessible"
                                          data-control="select2"
                                          data-placeholder="Select an option"
                                          aria-hidden="true"
                                          data-kt-initialized={1}
                                       >
                                          <option />
                                          <option value="Show All">
                                             Show All
                                          </option>
                                          <option value="a">Category A</option>
                                          <option value="b">Category A</option>
                                       </select>
                                       <span
                                          className="select2 select2-container select2-container--bootstrap5"
                                          dir="ltr"
                                          style={{
                                             width: "100%",
                                          }}
                                       >
                                          <span className="selection">
                                             <span
                                                className="select2-selection select2-selection--single form-select form-select-transparent text-graY-800 fs-base lh-1 fw-bold py-0 ps-3 w-auto"
                                                role="combobox"
                                                aria-expanded="false"
                                                aria-labelledby="select2-atv0-container"
                                                aria-controls="select2-atv0-container"
                                             >
                                                <span
                                                   className="select2-selection__rendered"
                                                   role="textbox"
                                                   aria-readonly="true"
                                                   title="Show All"
                                                >
                                                   Show All
                                                </span>
                                                <span
                                                   className="select2-selection__arrow"
                                                   role="presentation"
                                                >
                                                   <b role="presentation" />
                                                </span>
                                             </span>
                                          </span>
                                          <span
                                             className="dropdown-wrapper"
                                             aria-hidden="true"
                                          />
                                       </span>
                                    </div>

                                    <div className="d-flex align-items-center fw-bold">
                                       <div className="text-gray-500 fs-7 me-2">
                                          Status
                                       </div>

                                       <select
                                          className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto select2-hidden-accessible"
                                          data-control="select2"
                                          data-placeholder="Select an option"
                                          data-kt-table-widget-4="filter_status"
                                          aria-hidden="true"
                                          data-kt-initialized={1}
                                       >
                                          <option />
                                          <option value="Show All">
                                             Show All
                                          </option>
                                          <option value="Shipped">
                                             Shipped
                                          </option>
                                          <option value="Confirmed">
                                             Confirmed
                                          </option>
                                          <option value="Rejected">
                                             Rejected
                                          </option>
                                          <option value="Pending">
                                             Pending
                                          </option>
                                       </select>
                                       <span
                                          className="select2 select2-container select2-container--bootstrap5"
                                          dir="ltr"
                                          style={{
                                             width: "100%",
                                          }}
                                       >
                                          <span className="selection">
                                             <span
                                                className="select2-selection select2-selection--single form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                                role="combobox"
                                                aria-expanded="false"
                                                aria-labelledby="select2-4dt2-container"
                                                aria-controls="select2-4dt2-container"
                                             >
                                                <span
                                                   className="select2-selection__rendered"
                                                   role="textbox"
                                                   aria-readonly="true"
                                                   title="Show All"
                                                >
                                                   Show All
                                                </span>
                                                <span
                                                   className="select2-selection__arrow"
                                                   role="presentation"
                                                >
                                                   <b role="presentation" />
                                                </span>
                                             </span>
                                          </span>
                                          <span
                                             className="dropdown-wrapper"
                                             aria-hidden="true"
                                          />
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="card-body pt-2">
                              <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                                 <div className="table-responsive">
                                    <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer">
                                       <thead>
                                          <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                             <th
                                                className="min-w-100px sorting_disabled"
                                                rowSpan={1}
                                                colSpan={1}
                                                style={{
                                                   width: "101.797px",
                                                }}
                                             >
                                                Identificação
                                             </th>
                                             <th
                                                className="text-end min-w-100px sorting_disabled"
                                                rowSpan={1}
                                                colSpan={1}
                                                style={{
                                                   width: "101.953px",
                                                }}
                                             >
                                                Criação
                                             </th>
                                             <th
                                                className="text-end min-w-125px sorting_disabled"
                                                rowSpan={1}
                                                colSpan={1}
                                                style={{
                                                   width: "127.375px",
                                                }}
                                             >
                                                Cliente
                                             </th>
                                             <th
                                                className="text-end min-w-100px sorting_disabled"
                                                rowSpan={1}
                                                colSpan={1}
                                                style={{
                                                   width: "101.953px",
                                                }}
                                             >
                                                Total
                                             </th>
                                             <th
                                                className="text-end min-w-100px sorting_disabled"
                                                rowSpan={1}
                                                colSpan={1}
                                                style={{
                                                   width: "101.953px",
                                                }}
                                             >
                                                Lucro
                                             </th>
                                             <th
                                                className="text-end min-w-50px sorting_disabled"
                                                rowSpan={1}
                                                colSpan={1}
                                                style={{
                                                   width: "90.9844px",
                                                }}
                                             >
                                                Status
                                             </th>
                                             <th
                                                className="text-end sorting_disabled"
                                                rowSpan={1}
                                                colSpan={1}
                                                style={{
                                                   width: "25.6406px",
                                                }}
                                             />
                                          </tr>
                                       </thead>

                                       <tbody className="fw-bold text-gray-600">
                                          <tr className="even">
                                             <td>
                                                <a
                                                   href="#"
                                                   className="text-gray-800 text-hover-primary"
                                                >
                                                   #YHD-047
                                                </a>
                                             </td>
                                             <td className="text-end">
                                                52 min atrás
                                             </td>
                                             <td className="text-end">
                                                <a
                                                   href="#"
                                                   className="text-gray-600 text-hover-primary"
                                                >
                                                   Jenny Wilson
                                                </a>
                                             </td>
                                             <td className="text-end">
                                                R$ 25,00{" "}
                                             </td>
                                             <td className="text-end">
                                                <span className="text-gray-800 fw-bolder">
                                                   R$ 4,20
                                                </span>
                                             </td>
                                             <td className="text-end">
                                                <span className="badge py-3 px-4 fs-7 badge-light-primary">
                                                   Confirmado
                                                </span>
                                             </td>
                                             <td className="text-end">
                                                <button
                                                   type="button"
                                                   className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px"
                                                   data-kt-table-widget-4="expand_row"
                                                >
                                                   <i className="ki-duotone ki-plus fs-4 m-0 toggle-off" />{" "}
                                                   <i className="ki-duotone ki-minus fs-4 m-0 toggle-on" />{" "}
                                                </button>
                                             </td>
                                          </tr>
                                          <tr className="odd">
                                             <td>
                                                <a
                                                   href="#"
                                                   className="text-gray-800 text-hover-primary"
                                                >
                                                   #SRR-678
                                                </a>
                                             </td>
                                             <td className="text-end">
                                                1 hour atrás
                                             </td>
                                             <td className="text-end">
                                                <a
                                                   href="#"
                                                   className="text-gray-600 text-hover-primary"
                                                >
                                                   Robert Fox
                                                </a>
                                             </td>
                                             <td className="text-end">
                                                R$ 1.630,00{" "}
                                             </td>
                                             <td className="text-end">
                                                <span className="text-gray-800 fw-bolder">
                                                   R$ 203,90
                                                </span>
                                             </td>
                                             <td className="text-end">
                                                <span className="badge py-3 px-4 fs-7 badge-light-warning">
                                                   Pendente
                                                </span>
                                             </td>
                                             <td className="text-end">
                                                <button
                                                   type="button"
                                                   className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px"
                                                   data-kt-table-widget-4="expand_row"
                                                >
                                                   <i className="ki-duotone ki-plus fs-4 m-0 toggle-off" />{" "}
                                                   <i className="ki-duotone ki-minus fs-4 m-0 toggle-on" />{" "}
                                                </button>
                                             </td>
                                          </tr>

                                          <tr className="even">
                                             <td>
                                                <a
                                                   href="#"
                                                   className="text-gray-800 text-hover-primary"
                                                >
                                                   #SKP-035
                                                </a>
                                             </td>
                                             <td className="text-end">
                                                2 dias atrás
                                             </td>
                                             <td className="text-end">
                                                <a
                                                   href="#"
                                                   className="text-gray-600 text-hover-primary"
                                                >
                                                   Eleanor Pena
                                                </a>
                                             </td>
                                             <td className="text-end">
                                                R$ 290,00{" "}
                                             </td>
                                             <td className="text-end">
                                                <span className="text-gray-800 fw-bolder">
                                                   R$ 29,00
                                                </span>
                                             </td>
                                             <td className="text-end">
                                                <span className="badge py-3 px-4 fs-7 badge-light-danger">
                                                   Rejeitado
                                                </span>
                                             </td>
                                             <td className="text-end">
                                                <button
                                                   type="button"
                                                   className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px"
                                                   data-kt-table-widget-4="expand_row"
                                                >
                                                   <i className="ki-duotone ki-plus fs-4 m-0 toggle-off" />{" "}
                                                   <i className="ki-duotone ki-minus fs-4 m-0 toggle-on" />{" "}
                                                </button>
                                             </td>
                                          </tr>
                                          <tr className="odd">
                                             <td>
                                                <a
                                                   href="#"
                                                   className="text-gray-800 text-hover-primary"
                                                >
                                                   #SKP-567
                                                </a>
                                             </td>
                                             <td className="text-end">
                                                7 min atrás
                                             </td>
                                             <td className="text-end">
                                                <a
                                                   href="#"
                                                   className="text-gray-600 text-hover-primary"
                                                >
                                                   Dan Wilson
                                                </a>
                                             </td>
                                             <td className="text-end">
                                                R$ 590.00{" "}
                                             </td>
                                             <td className="text-end">
                                                <span className="text-gray-800 fw-bolder">
                                                   R$ 50.00
                                                </span>
                                             </td>
                                             <td className="text-end">
                                                <span className="badge py-3 px-4 fs-7 badge-light-success">
                                                   Enviado
                                                </span>
                                             </td>
                                             <td className="text-end">
                                                <button
                                                   type="button"
                                                   className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px"
                                                   data-kt-table-widget-4="expand_row"
                                                >
                                                   <i className="ki-duotone ki-plus fs-4 m-0 toggle-off" />{" "}
                                                   <i className="ki-duotone ki-minus fs-4 m-0 toggle-on" />{" "}
                                                </button>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                                 <div className="row">
                                    <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
                                    <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" />
                                 </div>
                              </div>
                           </div>
                        </div>{" "}
                     </div>
                  </div>
                  <div className="row gy-5 g-xl-10">
                     <div className="col-xl-4">
                        <div className="card card-flush h-xl-100">
                           <div className="card-header pt-7">
                              <h3 className="card-title align-items-start flex-column">
                                 <span className="card-label fw-bold text-gray-900">
                                    Entrega de produto
                                 </span>
                                 <span className="text-gray-500 mt-1 fw-semibold fs-6">
                                    1 mi de produtos enviados até agora
                                 </span>
                              </h3>

                              <div className="card-toolbar">
                                 <a href="#" className="btn btn-sm btn-light">
                                    Detalhes do pedido
                                 </a>
                              </div>
                           </div>

                           <div className="card-body">
                              <div
                                 className="hover-scroll-overlay-y pe-6 me-n6"
                                 style={{ height: 415 }}
                              >
                                 <div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">
                                    <div className="d-flex flex-stack mb-3">
                                       <div className="me-3">
                                          <img
                                             src="/metronic8/demo1/assets/media/stock/ecommerce/210.png"
                                             className="w-50px ms-n1 me-1"
                                          />

                                          <a
                                             href="#"
                                             className="text-gray-800 text-hover-primary fw-bold"
                                          >
                                             Elephant 1802
                                          </a>
                                       </div>

                                       <div className="m-0">
                                          <button
                                             className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end"
                                             data-kt-menu-trigger="click"
                                             data-kt-menu-placement="bottom-end"
                                             data-kt-menu-overflow="true"
                                          >
                                             <i className="ki-duotone ki-dots-square fs-1">
                                                <span className="path1" />
                                                <span className="path2" />
                                                <span className="path3" />
                                                <span className="path4" />
                                             </i>
                                          </button>

                                          <div
                                             className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                             data-kt-menu="true"
                                          >
                                             <div className="menu-item px-3">
                                                <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">
                                                   Quick Actions
                                                </div>
                                             </div>

                                             <div className="separator mb-3 opacity-75" />

                                             <div className="menu-item px-3">
                                                <a
                                                   href="#"
                                                   className="menu-link px-3"
                                                >
                                                   New Ticket
                                                </a>
                                             </div>

                                             <div className="menu-item px-3">
                                                <a
                                                   href="#"
                                                   className="menu-link px-3"
                                                >
                                                   New Customer
                                                </a>
                                             </div>

                                             <div
                                                className="menu-item px-3"
                                                data-kt-menu-trigger="hover"
                                                data-kt-menu-placement="right-start"
                                             >
                                                <a
                                                   href="#"
                                                   className="menu-link px-3"
                                                >
                                                   <span className="menu-title">
                                                      New Group
                                                   </span>
                                                   <span className="menu-arrow" />
                                                </a>

                                                <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                   <div className="menu-item px-3">
                                                      <a
                                                         href="#"
                                                         className="menu-link px-3"
                                                      >
                                                         Admin Group
                                                      </a>
                                                   </div>

                                                   <div className="menu-item px-3">
                                                      <a
                                                         href="#"
                                                         className="menu-link px-3"
                                                      >
                                                         Staff Group
                                                      </a>
                                                   </div>

                                                   <div className="menu-item px-3">
                                                      <a
                                                         href="#"
                                                         className="menu-link px-3"
                                                      >
                                                         Member Group
                                                      </a>
                                                   </div>
                                                </div>
                                             </div>

                                             <div className="menu-item px-3">
                                                <a
                                                   href="#"
                                                   className="menu-link px-3"
                                                >
                                                   New Contact
                                                </a>
                                             </div>

                                             <div className="separator mt-3 opacity-75" />

                                             <div className="menu-item px-3">
                                                <div className="menu-content px-3 py-3">
                                                   <a
                                                      className="btn btn-primary  btn-sm px-4"
                                                      href="#"
                                                   >
                                                      Generate Reports
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="d-flex flex-stack">
                                       <span className="text-gray-500 fw-bold">
                                          To:
                                          <a
                                             href="#"
                                             className="text-gray-800 text-hover-primary fw-bold"
                                          >
                                             Jason Bourne{" "}
                                          </a>
                                       </span>

                                       <span className="badge badge-light-success">
                                          Delivered
                                       </span>
                                    </div>
                                 </div>

                                 <div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">
                                    <div className="d-flex flex-stack mb-3">
                                       <div className="me-3">
                                          <img
                                             src="/metronic8/demo1/assets/media/stock/ecommerce/209.png"
                                             className="w-50px ms-n1 me-1"
                                          />

                                          <a
                                             href="#"
                                             className="text-gray-800 text-hover-primary fw-bold"
                                          >
                                             RiseUP
                                          </a>
                                       </div>

                                       <div className="m-0">
                                          <button
                                             className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end"
                                             data-kt-menu-trigger="click"
                                             data-kt-menu-placement="bottom-end"
                                             data-kt-menu-overflow="true"
                                          >
                                             <i className="ki-duotone ki-dots-square fs-1">
                                                <span className="path1" />
                                                <span className="path2" />
                                                <span className="path3" />
                                                <span className="path4" />
                                             </i>
                                          </button>

                                          <div
                                             className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                             data-kt-menu="true"
                                          >
                                             <div className="menu-item px-3">
                                                <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">
                                                   Quick Actions
                                                </div>
                                             </div>

                                             <div className="separator mb-3 opacity-75" />

                                             <div className="menu-item px-3">
                                                <a
                                                   href="#"
                                                   className="menu-link px-3"
                                                >
                                                   New Ticket
                                                </a>
                                             </div>

                                             <div className="menu-item px-3">
                                                <a
                                                   href="#"
                                                   className="menu-link px-3"
                                                >
                                                   New Customer
                                                </a>
                                             </div>

                                             <div
                                                className="menu-item px-3"
                                                data-kt-menu-trigger="hover"
                                                data-kt-menu-placement="right-start"
                                             >
                                                <a
                                                   href="#"
                                                   className="menu-link px-3"
                                                >
                                                   <span className="menu-title">
                                                      New Group
                                                   </span>
                                                   <span className="menu-arrow" />
                                                </a>

                                                <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                   <div className="menu-item px-3">
                                                      <a
                                                         href="#"
                                                         className="menu-link px-3"
                                                      >
                                                         Admin Group
                                                      </a>
                                                   </div>

                                                   <div className="menu-item px-3">
                                                      <a
                                                         href="#"
                                                         className="menu-link px-3"
                                                      >
                                                         Staff Group
                                                      </a>
                                                   </div>

                                                   <div className="menu-item px-3">
                                                      <a
                                                         href="#"
                                                         className="menu-link px-3"
                                                      >
                                                         Member Group
                                                      </a>
                                                   </div>
                                                </div>
                                             </div>

                                             <div className="menu-item px-3">
                                                <a
                                                   href="#"
                                                   className="menu-link px-3"
                                                >
                                                   New Contact
                                                </a>
                                             </div>

                                             <div className="separator mt-3 opacity-75" />

                                             <div className="menu-item px-3">
                                                <div className="menu-content px-3 py-3">
                                                   <a
                                                      className="btn btn-primary  btn-sm px-4"
                                                      href="#"
                                                   >
                                                      Generate Reports
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="d-flex flex-stack">
                                       <span className="text-gray-500 fw-bold">
                                          To:
                                          <a
                                             href="#"
                                             className="text-gray-800 text-hover-primary fw-bold"
                                          >
                                             Marie Durant{" "}
                                          </a>
                                       </span>

                                       <span className="badge badge-light-primary">
                                          Shipping
                                       </span>
                                    </div>
                                 </div>

                                 <div className="border border-dashed border-gray-300 rounded px-7 py-3 ">
                                    <div className="d-flex flex-stack mb-3">
                                       <div className="me-3">
                                          <img
                                             src="/metronic8/demo1/assets/media/stock/ecommerce/192.png"
                                             className="w-50px ms-n1 me-1"
                                          />

                                          <a
                                             href="#"
                                             className="text-gray-800 text-hover-primary fw-bold"
                                          >
                                             Yellow Stone
                                          </a>
                                       </div>

                                       <div className="m-0">
                                          <button
                                             className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end"
                                             data-kt-menu-trigger="click"
                                             data-kt-menu-placement="bottom-end"
                                             data-kt-menu-overflow="true"
                                          >
                                             <i className="ki-duotone ki-dots-square fs-1">
                                                <span className="path1" />
                                                <span className="path2" />
                                                <span className="path3" />
                                                <span className="path4" />
                                             </i>
                                          </button>

                                          <div
                                             className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                             data-kt-menu="true"
                                          >
                                             <div className="menu-item px-3">
                                                <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">
                                                   Quick Actions
                                                </div>
                                             </div>

                                             <div className="separator mb-3 opacity-75" />

                                             <div className="menu-item px-3">
                                                <a
                                                   href="#"
                                                   className="menu-link px-3"
                                                >
                                                   New Ticket
                                                </a>
                                             </div>

                                             <div className="menu-item px-3">
                                                <a
                                                   href="#"
                                                   className="menu-link px-3"
                                                >
                                                   New Customer
                                                </a>
                                             </div>

                                             <div
                                                className="menu-item px-3"
                                                data-kt-menu-trigger="hover"
                                                data-kt-menu-placement="right-start"
                                             >
                                                <a
                                                   href="#"
                                                   className="menu-link px-3"
                                                >
                                                   <span className="menu-title">
                                                      New Group
                                                   </span>
                                                   <span className="menu-arrow" />
                                                </a>

                                                <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                   <div className="menu-item px-3">
                                                      <a
                                                         href="#"
                                                         className="menu-link px-3"
                                                      >
                                                         Admin Group
                                                      </a>
                                                   </div>

                                                   <div className="menu-item px-3">
                                                      <a
                                                         href="#"
                                                         className="menu-link px-3"
                                                      >
                                                         Staff Group
                                                      </a>
                                                   </div>

                                                   <div className="menu-item px-3">
                                                      <a
                                                         href="#"
                                                         className="menu-link px-3"
                                                      >
                                                         Member Group
                                                      </a>
                                                   </div>
                                                </div>
                                             </div>

                                             <div className="menu-item px-3">
                                                <a
                                                   href="#"
                                                   className="menu-link px-3"
                                                >
                                                   New Contact
                                                </a>
                                             </div>

                                             <div className="separator mt-3 opacity-75" />

                                             <div className="menu-item px-3">
                                                <div className="menu-content px-3 py-3">
                                                   <a
                                                      className="btn btn-primary  btn-sm px-4"
                                                      href="#"
                                                   >
                                                      Generate Reports
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="d-flex flex-stack">
                                       <span className="text-gray-500 fw-bold">
                                          To:
                                          <a
                                             href="#"
                                             className="text-gray-800 text-hover-primary fw-bold"
                                          >
                                             Kevin Leonard{" "}
                                          </a>
                                       </span>

                                       <span className="badge badge-light-danger">
                                          Confirmado
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>{" "}
                     </div>

                     <div className="col-xl-8">
                        <div className="card card-flush h-xl-100">
                           <div className="card-header pt-7">
                              <h3 className="card-title align-items-start flex-column">
                                 <span className="card-label fw-bold text-gray-900">
                                    Relatório de estoque
                                 </span>
                                 <span className="text-gray-500 mt-1 fw-semibold fs-6">
                                    Total de 2.356 itens em estoque
                                 </span>
                              </h3>

                              <div className="card-toolbar">
                                 <div className="d-flex flex-stack flex-wrap gap-4">
                                    <div className="d-flex align-items-center fw-bold">
                                       <div className="text-muted fs-7 me-2">
                                          Cateogry
                                       </div>

                                       <select
                                          className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto select2-hidden-accessible"
                                          data-control="select2"
                                          data-placeholder="Select an option"
                                          aria-hidden="true"
                                       >
                                          <option />
                                          <option value="Show All">
                                             Show All
                                          </option>
                                          <option value="a">Category A</option>
                                          <option value="b">Category B</option>
                                       </select>
                                       <span
                                          className="select2 select2-container select2-container--bootstrap5"
                                          dir="ltr"
                                          style={{
                                             width: "100%",
                                          }}
                                       >
                                          <span className="selection">
                                             <span
                                                className="select2-selection select2-selection--single form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                                role="combobox"
                                                aria-expanded="false"
                                                aria-controls="select2-k1vn-container"
                                             >
                                                <span
                                                   className="select2-selection__rendered"
                                                   role="textbox"
                                                   aria-readonly="true"
                                                   title="Show All"
                                                >
                                                   Show All
                                                </span>
                                                <span
                                                   className="select2-selection__arrow"
                                                   role="presentation"
                                                >
                                                   <b role="presentation" />
                                                </span>
                                             </span>
                                          </span>
                                          <span
                                             className="dropdown-wrapper"
                                             aria-hidden="true"
                                          />
                                       </span>
                                    </div>

                                    <div className="d-flex align-items-center fw-bold">
                                       <div className="text-muted fs-7 me-2">
                                          Status
                                       </div>

                                       <select
                                          className="form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto select2-hidden-accessible"
                                          data-control="select2"
                                          data-placeholder="Select an option"
                                          data-kt-table-widget-5="filter_status"
                                          aria-hidden="true"
                                          data-kt-initialized={1}
                                       >
                                          <option />
                                          <option value="Show All">
                                             Show All
                                          </option>
                                          <option value="In Stock">
                                             In Stock
                                          </option>
                                          <option value="Out of Stock">
                                             Out of Stock
                                          </option>
                                          <option value="Low Stock">
                                             Low Stock
                                          </option>
                                       </select>
                                       <span
                                          className="select2 select2-container select2-container--bootstrap5"
                                          dir="ltr"
                                          style={{
                                             width: "100%",
                                          }}
                                       >
                                          <span className="selection">
                                             <span
                                                className="select2-selection select2-selection--single form-select form-select-transparent text-gray-900 fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                                role="combobox"
                                                aria-expanded="false"
                                                aria-labelledby="select2-jrov-container"
                                                aria-controls="select2-jrov-container"
                                             >
                                                <span
                                                   className="select2-selection__rendered"
                                                   role="textbox"
                                                   aria-readonly="true"
                                                   title="Show All"
                                                >
                                                   Show All
                                                </span>
                                                <span
                                                   className="select2-selection__arrow"
                                                   role="presentation"
                                                >
                                                   <b role="presentation" />
                                                </span>
                                             </span>
                                          </span>
                                          <span
                                             className="dropdown-wrapper"
                                             aria-hidden="true"
                                          />
                                       </span>
                                    </div>

                                    <a
                                       href="#"
                                       className="btn btn-light btn-sm"
                                    >
                                       Visualizar estoque
                                    </a>
                                 </div>
                              </div>
                           </div>

                           <div className="card-body">
                              <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                                 <div className="table-responsive">
                                    <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer">
                                       <thead>
                                          <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                             <th
                                                className="min-w-150px sorting"
                                                aria-controls="kt_table_widget_5_table"
                                                rowSpan={1}
                                                colSpan={1}
                                                style={{
                                                   width: "151.078px",
                                                }}
                                             >
                                                Produto
                                             </th>

                                             <th
                                                className="text-end pe-3 min-w-150px sorting"
                                                aria-controls="kt_table_widget_5_table"
                                                rowSpan={1}
                                                colSpan={1}
                                                style={{
                                                   width: "151.219px",
                                                }}
                                             >
                                                Date Added
                                             </th>
                                             <th
                                                className="text-end pe-3 min-w-100px sorting"
                                                aria-controls="kt_table_widget_5_table"
                                                rowSpan={1}
                                                colSpan={1}
                                                style={{
                                                   width: "100.859px",
                                                }}
                                             >
                                                Preço
                                             </th>
                                             <th
                                                className="text-end pe-3 min-w-100px sorting"
                                                aria-controls="kt_table_widget_5_table"
                                                rowSpan={1}
                                                colSpan={1}
                                                style={{
                                                   width: "101.25px",
                                                }}
                                             >
                                                Status
                                             </th>
                                             <th
                                                className="text-end pe-0 min-w-75px sorting"
                                                aria-controls="kt_table_widget_5_table"
                                                rowSpan={1}
                                                colSpan={1}
                                                style={{
                                                   width: "75.6406px",
                                                }}
                                             >
                                                Quantidade
                                             </th>
                                          </tr>
                                       </thead>

                                       <tbody className="fw-bold text-gray-600">
                                          <tr className="even">
                                             <td>
                                                <a
                                                   href="#"
                                                   className="text-gray-900 text-hover-primary"
                                                >
                                                   Surface Laptop 4
                                                </a>
                                             </td>

                                             <td
                                                className="text-end"
                                                data-order="Data inválida"
                                             >
                                                01 Apr, 2023{" "}
                                             </td>

                                             <td className="text-end">
                                                $1,060{" "}
                                             </td>

                                             <td className="text-end">
                                                <span className="badge py-3 px-4 fs-7 badge-light-danger">
                                                   Out of Stock
                                                </span>
                                             </td>

                                             <td
                                                className="text-end"
                                                data-order={0}
                                             >
                                                <span className="text-gray-900 fw-bold">
                                                   0 PCS
                                                </span>
                                             </td>
                                          </tr>

                                          <tr className="even">
                                             <td>
                                                <a
                                                   href="#"
                                                   className="text-gray-900 text-hover-primary"
                                                >
                                                   Dell 32 UltraSharp
                                                </a>
                                             </td>

                                             <td className="text-end">
                                                22 Dec, 2023{" "}
                                             </td>

                                             <td className="text-end">
                                                $1,060{" "}
                                             </td>

                                             <td className="text-end">
                                                <span className="badge py-3 px-4 fs-7 badge-light-warning">
                                                   Low Stock
                                                </span>
                                             </td>

                                             <td className="text-end">
                                                <span className="text-gray-900 fw-bold">
                                                   8 PCS
                                                </span>
                                             </td>
                                          </tr>
                                          <tr className="odd">
                                             <td>
                                                <a
                                                   href="#"
                                                   className="text-gray-900 text-hover-primary"
                                                >
                                                   Google Pixel 6 Pro
                                                </a>
                                             </td>

                                             <td className="text-end">
                                                27 Dec, 2023{" "}
                                             </td>

                                             <td className="text-end">
                                                $1,060{" "}
                                             </td>

                                             <td className="text-end">
                                                <span className="badge py-3 px-4 fs-7 badge-light-primary">
                                                   In Stock
                                                </span>
                                             </td>

                                             <td className="text-end">
                                                <span className="text-gray-900 fw-bold">
                                                   124 PCS
                                                </span>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                                 <div className="row">
                                    <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
                                    <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" />
                                 </div>
                              </div>
                           </div>
                        </div>{" "}
                     </div>
                  </div>{" "}
               </div>
            </div>
         </div>
         <div className="app-footer">
            <div className="app-container  container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
               <div className="text-gray-900 order-2 order-md-1">
                  <span className="text-muted fw-semibold me-1">2023©</span>
                  <a
                     href="#"
                     target="_blank"
                     className="text-gray-800 text-hover-primary"
                  >
                     Pommering
                  </a>
               </div>
            </div>
         </div>{" "}
      </div>
   );
}

export { Home };
