import React, { useState, useCallback, useContext, createContext } from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { browserName, osName, browserVersion } from "react-device-detect";
import api from "../service/api";

interface User {
   name: string;
   avatar: string;
   nickname: string | undefined;
}

interface AuthState {
   user: User;
}

interface SignInCredentials {
   login: string;
   password: string;
}

interface AuthContextData {
   user: User | undefined;
   signIn(credentials: SignInCredentials): Promise<void>;
   signOut(): void;
   //updateUser(user: User): void;
   loading: boolean;
}

type BoxProps = {
   children: React.ReactNode; // 👈️ define children prop
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<BoxProps> = ({ children }) => {
   const [loading, setLoading] = useState(false);

   const [data, setData] = useState<AuthState | undefined>(() => {
      const user_serialize = localStorage.getItem("@pommering:user");

      if (!user_serialize) {
         return {} as AuthState;
      }

      return { user: JSON.parse(user_serialize) };
   });

   const signIn = useCallback(async ({ login, password }: any) => {
      setLoading(true);

      const device = [];
      osName && device.push(osName);
      browserName && device.push(browserName);
      browserVersion && device.push(`Version: ${browserVersion}`);

      try {
         const response = await api.post("/user/session", {
            email: login,
            password,
            /*device: `${
               device.length > 0 ? device.join(" - ") : "Desconhecido"
            }`,*/
         });

         const {  user } = response.data.data;

         //localStorage.setItem("@Cebraco:token", token);
         localStorage.setItem("@pommering:user", JSON.stringify(user));

         const MySwal = withReactContent(Swal);

         MySwal.fire({
            text: "Você fez login com sucesso!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            buttonsStyling: false,
            customClass: {
               icon: "my-2 mx-auto",
            },
         });

         //console.log(token);

         setTimeout(() => {
            setData({ user });
            MySwal.close();
         }, 1000);
      } catch (error) {
         throw error;
      } finally {
         setLoading(false);
      }
   }, []);

   const signOut = useCallback(() => {
      setLoading(true);
      setData(undefined);
      localStorage.removeItem("@pommering:user");

      setData({} as AuthState);
      setLoading(false);
   }, []);

   return (
      <AuthContext.Provider
         value={{
            user: data?.user,
            signIn,
            signOut,
            //updateUser,
            loading,
         }}
      >
         {children}
      </AuthContext.Provider>
   );
};

export function useAuth(): AuthContextData {
   const context = useContext(AuthContext);

   if (!context) {
      throw new Error("useAuth must be used within an AuthProvider");
   }

   return context;
}
