import React from "react";
import { Route, Routes } from "react-router-dom";

import GlobalDark from "../styles/globalDark";
import GlobalLigth from "../styles/globalLight";

import "../styles/datatables.bundle.css";
import "../styles/fullcalendar.bundle.css";
import "../styles/plugins.bundle.css";
import "../styles/style.bundle.css";

import { Home } from "../pages/home";
import { useTheme } from "../hooks/theme";
import { HomeLast } from "../pages/home/index-last";
import { Signin } from "../pages/signin";
import CheckAuthenticate from "./CheckAuthenticate";
import PrivateRoute from "./PrivateRoute";
import { ProductImportNfe } from "../pages/ProductImportNfe";
import { CreatePayment } from "../pages/CreatePayment";
import { CreateProduct } from "../pages/CreateProduct";
import { CreateSupplier } from "../pages/CreateSupplier";
import { ListSupplier } from "../pages/ListSupplier";
import { ListProduct } from "../pages/ListProduct";
import { EditProduct } from "../pages/EditProduct";

const RoutesPages: React.FC = () => {
   const { theme } = useTheme();

   return (
      <>
         {theme === "dark" ? <GlobalDark /> : <GlobalLigth />}
         <Routes>
            <Route element={<CheckAuthenticate />}>
               <Route path="/" element={<Signin />} />
            </Route>

            <Route element={<PrivateRoute />}>
               <Route path="/home" element={<Home />} />
               <Route path="/homeLast" element={<HomeLast />} />
               <Route path="/product/import/nfe" element={<ProductImportNfe />} />
               <Route path="/product/create" element={<CreateProduct />} />
               <Route path="/product/list" element={<ListProduct />} />
               <Route path="/payment/create" element={<CreatePayment />} />
               <Route path="/supplier/create" element={<CreateSupplier />} />
               <Route path="/supplier/list" element={<ListSupplier />} />
               <Route path="/product/edit/:id" element={<EditProduct/>} />
            </Route>
         </Routes>
      </>
   );
};

export default RoutesPages;
