import axios from "axios";
import { cnpj } from "cpf-cnpj-validator";
import { FieldArray, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import image from '../../assets/media/image-default-dark.svg';

export interface Root {
   supplier: Supplier;
   nfe_identification: string;
   produtos: Produto[];
}

export interface Supplier {
   cnpj: number;
   name: string;
}

export interface Produto {
   name: string;
   quantity: number;
   unit_price: number;
   total_price: number;
   unit_type: string;
   selected: boolean;
}

function ProductImportNfe() {
   const [response, setResponse] = React.useState<{
      merchandise_code: string;
      name: string;
      quantity: number;
      unit_price: number;
      total_price: number;
      unit_type: string;
      selected: boolean;
   } | undefined>(undefined);

   const [file, setFile] = React.useState<File | null>(null);
   const [responseNfe, setResponseNfe] = React.useState<
      | {
         supplier: {
            cnpj: number;
            name: string;
         };
         nfe_identification: string;
         produtos: Array<{
            merchandise_code: string;
            name: string;
            quantity: number;
            unit_price: number;
            total_price: number;
            unit_type: string;
            selected: boolean;
         }>;
      }
      | undefined
   >(undefined);

   const [modelProduct, setModelProduct] = React.useState<boolean>(false);

   const [modelProductRegister, setModelProductRegister] = React.useState<boolean>(false);

   const [modelProductRegisterNew, setModelProductRegisterNew] = React.useState<boolean>(false);

   const [formCompany, setFormCompany] = React.useState<{
      cnpj: string | undefined;
      name: string | undefined;
      fantasy_name: string | undefined;
      inscricao_estadual: string | undefined;
      inscricao_municipal: string | undefined;
   }>({
      cnpj: undefined,
      name: undefined,
      fantasy_name: undefined,
      inscricao_estadual: undefined,
      inscricao_municipal: undefined,
   });

   /*Swal.fire({
      text: "Do you want to continue",
      icon: "error",
      showCancelButton: false,
      showConfirmButton: false,
      buttonsStyling: false,
      customClass: {
         icon: "my-2 mx-auto",
      },
   });-*/

   return (
      <>
         <div
            className="app-main flex-column flex-row-fluid"
            style={{ height: "calc(100vh - 74px)" }}
         >
            <div className="d-flex flex-column flex-column-fluid">
               <div className="app-toolbar  py-3 py-lg-6 ">
                  <div className="app-container  container-xxl d-flex flex-stack ">
                     <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                           Importar produtos por NFE
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                           <li className="breadcrumb-item text-muted">
                              <a
                                 href="#"
                                 className="text-muted text-hover-primary"
                              >
                                 inicio{" "}
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>

               <div className="app-content flex-column-fluid">
                  <div className="app-container container-xxl h-100">
                     <Formik
                        onSubmit={(
                           values: any,
                           { setSubmitting }: FormikHelpers<any>
                        ) => {
                           Swal.fire({
                              text: "Deseja mesmo adicionar os produtos ao estoque ?",
                              confirmButtonText: "Sim, adicionar",
                              showConfirmButton: true,
                              denyButtonText: "Não, cancelar",
                              showDenyButton: true,
                              customClass: {
                                 confirmButton: "swal2-confirm btn btn-success",
                                 denyButton: "swal2-deny btn btn-danger",
                                 htmlContainer: "fs-4 fw-bold text-gray-900",
                              },
                              reverseButtons: true,
                              buttonsStyling: false,
                           }).then((result) => {
                              result.isConfirmed &&
                                 setTimeout(() => {
                                    alert(JSON.stringify(values, null, 2));
                                    setSubmitting(false);
                                 }, 500);
                           });
                        }}
                        initialValues={{
                           cnpj: "",
                           "company-name": "",
                           "fantasy-name": "",
                           "state-registration": "",
                           "link-supplier": true,
                        }}
                     >
                        {({
                           values,
                           errors,
                           touched,
                           handleChange,
                           handleBlur,
                           handleSubmit,
                           isSubmitting,
                           setFieldValue,
                           submitForm,
                           /* and other goodies */
                        }) => (
                           <>
                              {!file ? (
                                 <div className="row gx-5 gx-xl-10 mb-xl-10">
                                    <div className="col-12 h-150px">
                                       <input
                                          type="file"
                                          className="btn-check"
                                          name="account_type"
                                          id="xml-file"
                                          onChange={(e) => {
                                             if (e.target.files) {
                                                setFile(e.target.files[0]);

                                                var formData = new FormData();
                                                formData.append(
                                                   "nfe",
                                                   e.target.files[0]
                                                );

                                                axios
                                                   .post<{
                                                      supplier: {
                                                         cnpj: number;
                                                         name: string;
                                                         fantasy_name: string;
                                                         IE: number;
                                                         register: boolean;
                                                      };
                                                      nfe_identification: string;
                                                      produtos: Array<{
                                                         merchandise_code: string;
                                                         name: string;
                                                         quantity: number;
                                                         unit_price: number;
                                                         total_price: number;
                                                         unit_type: string;
                                                      }>;
                                                   }>(
                                                      `${process.env.REACT_APP_BACKEND}/product/nfe/information/file`,
                                                      formData,
                                                      {
                                                         headers: {
                                                            "Content-Type":
                                                               "multipart/form-data",
                                                         },
                                                      }
                                                   )
                                                   .then(({ data }) => {
                                                      //setFile(null);
                                                      setResponseNfe({
                                                         ...data,
                                                         produtos:
                                                            data.produtos.map(
                                                               (produto) => ({
                                                                  ...produto,
                                                                  selected:
                                                                     true,
                                                               })
                                                            ),
                                                      });

                                                      //setModelCompany(true)

                                                      /*setFormCompany({
                                                            cnpj: data.supplier.cnpj.toString(),
                                                            name: data.supplier.name,
                                                            fantasy_name:
                                                               data.supplier.fantasy_name,
                                                            inscricao_estadual:
                                                               data.supplier.IE.toString(),
                                                            inscricao_municipal: undefined,
                                                         });*/

                                                      setFieldValue(
                                                         "cnpj",
                                                         data.supplier.cnpj.toString()
                                                      );
                                                      setFieldValue(
                                                         "company-name",
                                                         data.supplier.name
                                                      );
                                                      setFieldValue(
                                                         "fantasy-name",
                                                         data.supplier
                                                            .fantasy_name
                                                      );

                                                      setFieldValue(
                                                         "state-registration",
                                                         data.supplier.IE.toString()
                                                      );
                                                   })
                                                   .catch((error) => {
                                                      setFile(null);
                                                      console.error(error);
                                                   });
                                             }
                                          }}
                                       />
                                       <label
                                          htmlFor="xml-file"
                                          className="card h-100 flex-center border border-dashed p-10"
                                       >
                                          <i className="ki-duotone ki-file-up fs-1 text-white me-4 mb-5">
                                             <span className="path1"></span>
                                             <span className="path2"></span>
                                          </i>
                                          <span className="fs-5 fw-bold mb-2">
                                             Arquivo não selecionado
                                          </span>

                                          <div className="fs-7 fw-semibold text-gray-500">
                                             Selecione um arquivo para continuar
                                          </div>
                                       </label>
                                    </div>
                                 </div>
                              ) : (
                                 <>
                                    {!responseNfe ? (
                                       <>
                                          <div className="row gx-5 gx-xl-10 mb-xl-10">
                                             <div className="col-12 h-150px">
                                                <label
                                                   htmlFor="xml-file"
                                                   className="card h-100 flex-center border border-dashed p-10"
                                                >
                                                   Carregando...
                                                </label>
                                             </div>
                                          </div>
                                       </>
                                    ) : (
                                       <>
                                          {responseNfe?.produtos.length ===
                                             0 ? (
                                             <div className="col-12">
                                                <div className="card h-100 flex-center border border-dashed p-10">
                                                   <span className="fs-5 fw-bold mb-2">
                                                      Nenhum produto encontrado
                                                   </span>

                                                   <div className="fs-7 fw-semibold text-gray-500">
                                                      Verifique se o arquivo
                                                      está correto
                                                   </div>
                                                </div>
                                             </div>
                                          ) : (
                                             <form
                                                className="row gx-5 gx-xl-10 mb-xl-10"
                                                onSubmit={handleSubmit}
                                             >
                                                <div className="col-8">
                                                   <div className="card ">
                                                      <div className="card-header card-header-stretch">
                                                         <div
                                                            className="card-title"
                                                            style={{
                                                               flexDirection:
                                                                  "column",
                                                               alignItems:
                                                                  "start",
                                                               justifyContent:
                                                                  "center",
                                                            }}
                                                         >
                                                            <h3>Produtos</h3>
                                                            <span className="fs-6 text-muted">
                                                               {
                                                                  responseNfe
                                                                     ?.produtos
                                                                     .length
                                                               }{" "}
                                                               produtos
                                                               encontrados
                                                            </span>
                                                         </div>
                                                      </div>
                                                      <div className="card-body p-0">
                                                         <div className="table-responsive">
                                                            <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
                                                               <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                                                                  <tr>
                                                                     <th className="w-75px">
                                                                        <label className="form-check form-check-custom form-check-solid">
                                                                           <input
                                                                              className="form-check-input h-20px w-20px"
                                                                              type="checkbox"
                                                                              value="email"
                                                                              checked={responseNfe?.produtos.every(
                                                                                 (
                                                                                    p
                                                                                 ) =>
                                                                                    p.selected
                                                                              )}
                                                                              onChange={(
                                                                                 event
                                                                              ) => {
                                                                                 if (
                                                                                    !responseNfe
                                                                                 ) {
                                                                                    return;
                                                                                 }

                                                                                 setResponseNfe(
                                                                                    {
                                                                                       ...responseNfe,
                                                                                       produtos:
                                                                                          responseNfe.produtos.map(
                                                                                             (
                                                                                                p
                                                                                             ) => {
                                                                                                return {
                                                                                                   ...p,
                                                                                                   selected:
                                                                                                      event
                                                                                                         .target
                                                                                                         .checked,
                                                                                                };
                                                                                             }
                                                                                          ),
                                                                                    }
                                                                                 );
                                                                              }}
                                                                           />
                                                                        </label>
                                                                     </th>
                                                                     <th className="min-w-200px fs-6">
                                                                        Produto
                                                                     </th>
                                                                     <th className="min-w-150px fs-6">
                                                                        Quantidade
                                                                     </th>
                                                                     <th className="min-w-150px fs-6">
                                                                        Preço
                                                                        uni.
                                                                     </th>
                                                                     <th className="w-150px fs-6">
                                                                        Preço
                                                                        total
                                                                     </th>
                                                                     <th className="w-75px fs-6"></th>
                                                                  </tr>
                                                               </thead>

                                                               <tbody className="fs-6 fw-semibold text-gray-600">
                                                                  <FieldArray
                                                                     name="products"
                                                                     render={(
                                                                        arrayHelpers
                                                                     ) => (
                                                                        <div></div>
                                                                     )}
                                                                  />
                                                                  {responseNfe?.produtos.map(
                                                                     (
                                                                        product
                                                                     ) => (
                                                                        <tr>
                                                                           <td>
                                                                              <label className="form-check form-check-custom form-check-solid">
                                                                                 <input
                                                                                    className="form-check-input h-20px w-20px"
                                                                                    type="checkbox"
                                                                                    value="email"
                                                                                    checked={
                                                                                       product.selected
                                                                                    }
                                                                                    onChange={(
                                                                                       e
                                                                                    ) => {
                                                                                       if (
                                                                                          !responseNfe
                                                                                       ) {
                                                                                          return;
                                                                                       }

                                                                                       setResponseNfe(
                                                                                          {
                                                                                             ...responseNfe,
                                                                                             produtos:
                                                                                                responseNfe.produtos.map(
                                                                                                   (
                                                                                                      p
                                                                                                   ) => {
                                                                                                      if (
                                                                                                         p.merchandise_code ===
                                                                                                         product.merchandise_code
                                                                                                      ) {
                                                                                                         return {
                                                                                                            ...p,
                                                                                                            selected:
                                                                                                               !p.selected,
                                                                                                         };
                                                                                                      }
                                                                                                      return p;
                                                                                                   }
                                                                                                ),
                                                                                          }
                                                                                       );
                                                                                    }}
                                                                                 />
                                                                              </label>
                                                                           </td>
                                                                           <td>
                                                                              <div className="d-flex fs-7">
                                                                                 {
                                                                                    product.name
                                                                                 }
                                                                              </div>
                                                                           </td>

                                                                           <td className="fs-7">
                                                                              {
                                                                                 product.quantity
                                                                              }{" "}
                                                                              {
                                                                                 product.unit_type
                                                                              }
                                                                           </td>

                                                                           <td className="fs-7">
                                                                              {new Intl.NumberFormat(
                                                                                 "pt-BR",
                                                                                 {
                                                                                    style: "currency",
                                                                                    currency:
                                                                                       "BRL",
                                                                                    minimumFractionDigits: 2,
                                                                                 }
                                                                              ).format(
                                                                                 product.unit_price
                                                                              )}
                                                                           </td>

                                                                           <td className="fs-7">
                                                                              {new Intl.NumberFormat(
                                                                                 "pt-BR",
                                                                                 {
                                                                                    style: "currency",
                                                                                    currency:
                                                                                       "BRL",
                                                                                    minimumFractionDigits: 2,
                                                                                 }
                                                                              ).format(
                                                                                 product.total_price
                                                                              )}
                                                                           </td>

                                                                           <td
                                                                              style={{
                                                                                 margin:
                                                                                    "auto",
                                                                              }}
                                                                           >
                                                                              <div
                                                                                 className=" d-flex"
                                                                                 style={{
                                                                                    alignItems:
                                                                                       "center",
                                                                                    flexDirection:
                                                                                       "column",
                                                                                 }}
                                                                                 onClick={() => {
                                                                                    setModelProduct(
                                                                                       true
                                                                                    );
                                                                                 }}
                                                                              >
                                                                                 <i className="ki-duotone ki-fasten fs-3 mb-1">
                                                                                    <span className="path1"></span>
                                                                                    <span className="path2"></span>
                                                                                 </i>
                                                                                 <span className="fs-7">
                                                                                    Desvinculado
                                                                                 </span>
                                                                              </div>
                                                                           </td>
                                                                        </tr>
                                                                     )
                                                                  )}
                                                               </tbody>
                                                            </table>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-4">
                                                   <div className="card ">
                                                      <div className="card-header card-header-stretch">
                                                         <div
                                                            className="card-title mt-10"
                                                            style={{
                                                               flexDirection:
                                                                  "column",
                                                               alignItems:
                                                                  "start",
                                                               justifyContent:
                                                                  "center",
                                                            }}
                                                         >
                                                            <h3>Fornecedor</h3>
                                                         </div>
                                                         <div className="d-flex flex-stack mb-8">
                                                            <div className="me-6">
                                                               <label className="fs-6 fw-semibold">
                                                                  Vincular ao
                                                                  fornecedor
                                                               </label>

                                                               <div className="fs-7 fw-semibold text-muted">
                                                                  Os produtos
                                                                  serão
                                                                  vinculados ao
                                                                  cnpj do
                                                                  fornecedor
                                                               </div>
                                                            </div>


                                                         </div>
                                                      </div>
                                                      <div className="card-body px-10">
                                                         <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                               <span>CNPJ</span>
                                                            </label>

                                                            <input
                                                               type="text"
                                                               className="form-control "
                                                               placeholder="00.000.000/0000-00"
                                                               onBlur={
                                                                  handleBlur
                                                               }
                                                               value={cnpj.format(
                                                                  values.cnpj
                                                                     ?.toString()
                                                                     .slice(
                                                                        0,
                                                                        14
                                                                     ) || ""
                                                               )}
                                                               name="cnpj"
                                                               disabled
                                                            />
                                                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                         </div>
                                                         <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                               <span>
                                                                  Razão social
                                                               </span>
                                                            </label>

                                                            <input
                                                               type="text"
                                                               className="form-control"
                                                               placeholder="Enter Target Title"
                                                               name="company-name"
                                                               value={
                                                                  values[
                                                                  "company-name"
                                                                  ]
                                                               }
                                                            />
                                                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                         </div>
                                                         <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                               <span>
                                                                  Nome fantasia
                                                               </span>
                                                            </label>

                                                            <input
                                                               type="text"
                                                               className="form-control "
                                                               placeholder="Enter Target Title"
                                                               name="fantasy-name"
                                                               value={
                                                                  values[
                                                                  "fantasy-name"
                                                                  ]
                                                               }
                                                            />
                                                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                         </div>
                                                         <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                                                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                               <span>
                                                                  Inscrição
                                                                  estadual
                                                               </span>
                                                            </label>

                                                            <input
                                                               type="text"
                                                               className="form-control"
                                                               name="state_registration"
                                                               value={
                                                                  values[
                                                                  "state-registration"
                                                                  ]
                                                               }
                                                            />
                                                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                         </div>
                                                         <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                                                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                               <span>
                                                                  Inscrição
                                                                  municipal
                                                               </span>
                                                            </label>

                                                            <input
                                                               type="text"
                                                               className="form-control"
                                                               name="target_title"
                                                               value={
                                                                  formCompany.inscricao_municipal ||
                                                                  "Não identificado"
                                                               }
                                                               onChange={(
                                                                  e
                                                               ) => {
                                                                  setFormCompany(
                                                                     {
                                                                        ...formCompany,
                                                                        inscricao_municipal:
                                                                           e
                                                                              .target
                                                                              .value,
                                                                     }
                                                                  );
                                                               }}
                                                            />
                                                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div
                                                      className="w-100 d-flex mt-10"
                                                      style={{
                                                         justifyContent:
                                                            "space-between",
                                                      }}
                                                   >
                                                      <div
                                                         className="btn btn-danger d-flex w-175px"
                                                         style={{
                                                            justifyContent:
                                                               "center",
                                                            alignItems:
                                                               "center",
                                                         }}
                                                         onClick={() => {
                                                            Swal.fire({
                                                               text: "Deseja mesmo cancelar a operação ?",
                                                               confirmButtonText:
                                                                  "Sim, cancelar",
                                                               showConfirmButton:
                                                                  true,
                                                               denyButtonText:
                                                                  "Não, cancelar",
                                                               showDenyButton:
                                                                  true,
                                                               customClass: {
                                                                  confirmButton:
                                                                     "swal2-confirm btn btn-success",
                                                                  denyButton:
                                                                     "swal2-deny btn btn-danger",
                                                                  htmlContainer:
                                                                     "fs-4 fw-bold text-gray-900",
                                                               },
                                                               reverseButtons:
                                                                  true,
                                                               buttonsStyling:
                                                                  false,
                                                            }).then(
                                                               (result) => {
                                                                  result.isConfirmed &&
                                                                     setFile(
                                                                        null
                                                                     );
                                                               }
                                                            );
                                                         }}
                                                      >
                                                         Cancelar
                                                      </div>
                                                      <button
                                                         type="submit"
                                                         disabled={
                                                            responseNfe?.produtos.every(
                                                               (product) =>
                                                                  !product.selected
                                                            ) || !file
                                                         }
                                                         className="btn btn-primary d-flex w-175px"
                                                         style={{
                                                            justifyContent:
                                                               "center",
                                                            alignItems:
                                                               "center",
                                                         }}
                                                      >
                                                         Cadastrar
                                                      </button>
                                                   </div>
                                                </div>
                                             </form>
                                          )}
                                       </>
                                    )}
                                 </>
                              )}
                           </>
                        )}
                     </Formik>
                  </div>
               </div>
            </div>
            <div className="app-footer">
               <div className="app-container  container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-gray-900 order-2 order-md-1">
                     <span className="text-muted fw-semibold me-1">2023©</span>
                     <a
                        href="#"
                        target="_blank"
                        className="text-gray-800 text-hover-primary"
                     >
                        Pommering
                     </a>
                  </div>
               </div>
            </div>{" "}
         </div>

         {modelProduct && (
            <div
               className={`modal fade ${modelProduct ? "show d-block" : "d-none"
                  }`}
            >
               <div className="modal-dialog mw-650px">
                  <div className="modal-content">
                     <div className="modal-header pb-0 border-0 justify-content-end">
                        <div
                           className="btn btn-sm btn-icon btn-active-color-primary"
                           onClick={() => {
                              setModelProduct(() => false);
                           }}
                        >
                           <i className="ki-duotone ki-cross fs-1">
                              <span className="path1"></span>
                              <span className="path2"></span>
                           </i>{" "}
                        </div>
                     </div>

                     <div className="modal-body mx-5 mx-xl-18 pt-0 pb-15">
                        <div className="text-center mb-13">
                           <h1 className="mb-3">Produtos</h1>

                           <div className="text-muted fw-semibold fs-5">
                              Selecione um produto existente ou crie um novo.
                           </div>
                        </div>

                        <div className="btn btn-light-primary fw-bold w-100 mb-8" onClick={() => {
                           setModelProduct(() => false);
                           setModelProductRegisterNew(() => true);
                        }}>
                           <i className="ki-duotone ki-add-item fs-2 me-1">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                           </i>
                           Cadastrar novo produto
                        </div>

                        <div className="separator d-flex flex-center mb-8">
                           <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">
                              ou
                           </span>
                        </div>

                        <input
                           type="text"
                           className="search-input form-control form-control-solid mb-8"
                           name="search"
                           placeholder="Buscar produto..."

                        />

                        <div className="mb-7">
                           <div className="mh-350px scroll-y me-n7 pe-7">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
                                 <div
                                    key={number}
                                    className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed bg-hover-light"
                                 >
                                    <div className="d-flex align-items-center">
                                       <div className="symbol symbol-35px symbol-circle ms-3">
                                          <span className="symbol-label bg-light-danger text-danger fw-semibold">
                                             M{" "}
                                          </span>
                                       </div>

                                       <div className="ms-5">
                                          <a
                                             href="#"
                                             className="fs-5 fw-bold text-gray-900 mb-2"
                                          >
                                             Product name
                                          </a>

                                          <div className="fw-semibold text-muted">
                                             Fornecedor
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              ))}
                           </div>
                        </div>

                        <div className="d-flex flex-stack">
                           <div className="me-5 fw-semibold"></div>

                           <label className="form-check form-switch form-check-custom form-check-solid"></label>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}

         {modelProductRegisterNew && (
            <div
               className={`modal fade ${modelProductRegisterNew ? "show d-block" : "d-none"
                  }`}
            >
               <div className="modal-dialog mw-650px">
                  <div className="modal-content">
                     <div className="modal-header pb-0 border-0 justify-content-end">
                        <div
                           className="btn btn-sm btn-icon btn-active-color-primary"
                           onClick={() => {
                              setModelProductRegisterNew(() => false);
                           }}
                        >
                           <i className="ki-duotone ki-cross fs-1">
                              <span className="path1"></span>
                              <span className="path2"></span>
                           </i>{" "}
                        </div>
                     </div>

                     <div className="modal-body mx-5 mx-xl-18 pt-0 pb-15">
                        <div className="text-center mb-13">
                           <h1 className="mb-3">Novo produtos</h1>

                           <div className="text-muted fw-semibold fs-5">
                              Cadastro de um novo produto.
                           </div>
                        </div>
                        <div className="mb-8 d-flex" style={{ justifyContent: 'center', flexDirection: "column", alignItems: "center" }}>

                           <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: `url(${(image)})` }}>

                              <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: `url(${(image)})` }}></div>



                              <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" aria-label="Change avatar" data-bs-original-title="Change avatar" data-kt-initialized="1">
                                 <i className="ki-duotone ki-pencil fs-7"><span className="path1"></span><span className="path2"></span></i>

                                 <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                 <input type="hidden" name="avatar_remove" />

                              </label>



                              <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" aria-label="Cancel avatar" data-bs-original-title="Cancel avatar" data-kt-initialized="1">
                                 <i className="ki-duotone ki-cross fs-2"><span className="path1"></span><span className="path2"></span></i>                            </span>



                              <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" aria-label="Remove avatar" data-bs-original-title="Remove avatar" data-kt-initialized="1">
                                 <i className="ki-duotone ki-cross fs-2"><span className="path1"></span><span className="path2"></span></i>                            </span>

                           </div>



                           <div className="form-text mt-5">Arquivos permitidos:  png, jpg, jpeg.</div>

                        </div>

                        <input
                           type="text"
                           className="search-input form-control form-control-solid mb-8"
                           name="name_product"
                           placeholder="Nome do produto"
                        />
                        <input
                           type="text"
                           className="search-input form-control form-control-solid mb-8"
                           name="unity_measure"
                           placeholder="Unidade de medida"
                        />
                        <div className="mb-8 d-flex flex-wrap gap-5">
                           <div className="fv-row w-100 flex-md-root">
                              <input
                                 type="text"
                                 className="search-input form-control form-control-solid "
                                 name="height"
                                 placeholder="Altura"
                              />
                           </div>

                           <div className="fv-row w-100 flex-md-root">
                              <input
                                 type="text"
                                 className="search-input form-control form-control-solid "
                                 name="width"
                                 placeholder="Largura"
                              />
                           </div>

                           <div className="fv-row w-100 flex-md-root">
                              <input
                                 type="text"
                                 className="search-input form-control form-control-solid "
                                 name="length"
                                 placeholder="Comprimento"
                              />
                           </div>

                        </div>


                        <textarea
                           className="search-input form-control form-control-solid mb-8"
                           name="description"
                           placeholder="Descrição do produto"
                           rows={5}
                           style={{ resize: "none" }}
                        />

                        <div
                           className="w-100 d-flex mt-10"
                           style={{
                              justifyContent:
                                 "space-between",
                           }}
                        >
                           <div
                              onClick={() => {
                                 setModelProductRegisterNew(() => false);
                                 setModelProduct(() => true);

                              }}
                              className="btn btn-light btn-active-light-primary d-flex w-175px"
                              style={{
                                 justifyContent:
                                    "center",
                                 alignItems:
                                    "center",
                              }}
                           >
                              Voltar
                           </div>
                           <button
                              type="submit"
                              className="btn btn-primary d-flex w-175px"
                              style={{
                                 justifyContent:
                                    "center",
                                 alignItems:
                                    "center",
                              }}
                           >
                              Cadastrar
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}





         {(modelProduct || modelProductRegisterNew) && <div className={`modal-backdrop fade show`}></div>}
      </>
   );
}

export { ProductImportNfe };
