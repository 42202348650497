import { FastField, FieldArray, Form, Formik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";

import image from '../../assets/media/image-default-dark.svg';
import api from "../../service/api";

export interface Root {
   supplier: Supplier;
   nfe_identification: string;
}

export interface Supplier {
   cnpj: number;
   name: string;
}

function CreateSupplier() {
   const initValues: {
      image: File | undefined,
      document: string,
      company_name: string,
      fantasy_name: string,
      emails: string[],
      phones: string[],
   } = {
      image: undefined,
      document: "",
      company_name: "",
      fantasy_name: "",
      emails: [""],
      phones: [""]
   }

   const SignupSchema = Yup.object().shape({
      document: Yup.string()
         .min(2, 'Nome é muito curto')
         .required('O nome do produto é obrigatório'),
      company_name: Yup.string()
         .required('Campo obrigatório'),
      fantasy_name: Yup.string()
   });

   
   return (
      <>
         <div
            className="app-main flex-column flex-row-fluid"
            style={{ height: "calc(100vh - 74px)" }}
         >
            <div className="d-flex flex-column flex-column-fluid">
               <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
                  <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack ">
                     <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">

                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                           Adicionar Fornecedor
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

                           <li className="breadcrumb-item text-muted">
                              <a href="/metronic8/demo1/index.html" className="text-muted text-hover-primary">
                                 Home
                              </a>
                           </li>
                           <li className="breadcrumb-item">
                              <span className="bullet bg-gray-500 w-5px h-2px" />
                           </li>
                           <li className="breadcrumb-item text-muted">
                              Fornecedores
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div className="app-content flex-column-fluid" >
                  <div className="app-container container-xxl">
                     <Formik
                        initialValues={initValues}
                        onSubmit={(values, { resetForm }) => {

                           const formData = new FormData();
                           values.image && formData.append('image', values.image);
                           formData.append('document', values.document.replace(/\D/g, ""));
                           formData.append('company_name', values.company_name);
                           formData.append('fantasy_name', values.fantasy_name);
                           values.emails.filter((email) => !!email).forEach((email) => formData.append('emails[]', email));
                           values.phones.filter((phone) => !!phone).forEach((phone) => formData.append('phones[]', phone.replace(/\D/g, "")));


                           api.post("/supplier", formData, {
                              withCredentials: true,  // Set for this request
                           }).then((response) => {

                              if(response.status !== 201) {
                                 Swal.fire({
                                    text: "Erro ao adicionar fornecedor!",
                                    icon: "error",
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                    buttonsStyling: false,
                                    customClass: {
                                       icon: "my-2 mx-auto",
                                    },
                                 });
                                 return;
                              }

                              Swal.fire({
                                 text: "Produto adicionado com sucesso!",
                                 icon: "success",
                                 showCancelButton: false,
                                 showConfirmButton: false,
                                 buttonsStyling: false,
                                 customClass: {
                                    icon: "my-2 mx-auto",
                                 },
                              });
                              resetForm();
                           }).catch((error) => {
                              Swal.fire({
                                 text: "Erro ao adicionar fornecedor!",
                                 icon: "error",
                                 showCancelButton: false,
                                 showConfirmButton: false,
                                 buttonsStyling: false,
                                 customClass: {
                                    icon: "my-2 mx-auto",
                                 },
                              });
                           })



                        }}
                        validationSchema={SignupSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                     >
                        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
                           <Form onSubmit={handleSubmit} className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework">

                              <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                                 <div className="card card-flush py-4">
                                    <div className="card-header">
                                       <div className="card-title">
                                          <h2>Foto</h2>
                                       </div>
                                    </div>
                                    <div className="card-body text-center pt-0">
                                       <div className={`image-input ${!values.image ? "image-input-empty" : "image-input-changed"} image-input-outline image-input-placeholder mb-3`} data-kt-image-input="true" style={{ backgroundImage: `url(${image})` }}>
                                          <div className="image-input-wrapper w-150px h-150px" style={{ backgroundImage: `url(${values.image && URL.createObjectURL(values.image)})` }} />
                                          <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" aria-label="Change avatar" data-bs-original-title="Change avatar" data-kt-initialized="1">
                                             <i className="ki-duotone ki-pencil fs-7">
                                                <span className="path1" />
                                                <span className="path2" />
                                             </i>
                                             <input type="file" name="image" accept=".png, .jpg, .jpeg" onChange={(e) => {
                                                setFieldValue("image", e.target.files?.[0]);
                                             }} />
                                             <input type="hidden" name="avatar_remove" />
                                          </label>
                                          <span onClick={() => { setFieldValue("main_image", undefined) }} className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" aria-label="Cancel avatar" data-bs-original-title="Cancel avatar" data-kt-initialized="1">
                                             <i className="ki-duotone ki-cross fs-2">
                                                <span className="path1" />
                                                <span className="path2" />
                                             </i>
                                          </span>

                                       </div>
                                       <div className="text-muted fs-7">Defina a imagem em miniatura do produto. Somente arquivos de imagem *.png, *.jpg e *.jpeg são aceitos</div>
                                    </div>
                                 </div>



                                 <div className="card card-flush py-4">

                                    <div className="card-header">

                                       <div className="card-title">
                                          <h2>Compras mensais</h2>
                                       </div>

                                    </div>



                                    <div className="card-body pt-0">
                                       <span className="text-muted">Não há dados disponíveis. Os dados de vendas começarão a ser capturados assim que o produto for publicado.</span>
                                    </div>

                                 </div>


                              </div>
                              <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10" >
                                 {/*<ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2" >
                                    <li className="nav-item" role="presentation" onClick={() => setTab("general")} style={{ cursor: "pointer" }}>
                                       <a className={`nav-link text-active-primary pb-4 ${tab === "general" && "active"}`}>Geral</a>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={() => setTab('advanced')} style={{ cursor: "pointer" }}>
                                       <a className={`nav-link text-active-primary pb-4 ${tab === "advanced" && "active"}`}>Avançado</a>
                                    </li>
                                 </ul>*/}


                                 <div className="tab-content" >

                                    <div className={`tab-pane fade active show`} id="kt_ecommerce_add_product_general">
                                       <div className="d-flex flex-column gap-7 gap-lg-10" >


                                          <div className="card card-flush py-4">

                                             <div className="card-header">
                                                <div className="card-title">
                                                   <h2>Geral</h2>
                                                </div>
                                             </div>

                                             <div className="card-body pt-0">

                                                <div className="mb-10 fv-row fv-plugins-icon-container">

                                                   <label className="required form-label">CNPJ</label>
                                                   <input type="text" name="document" className="form-control mb-2" placeholder="CNPJ da empresa" onChange={(event) => {
                                                      let value = event.target.value.replace(/\D/g, ""); // Remove tudo que não for dígito

                                                      if (value.length > 14) {
                                                         value = value.slice(0, 14); // Limita a 14 dígitos
                                                      }

                                                      // Aplica a máscara de CNPJ
                                                      value = value.replace(/^(\d{2})(\d)/, "$1.$2");
                                                      value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
                                                      value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
                                                      value = value.replace(/(\d{4})(\d)/, "$1-$2");

                                                      setFieldValue("document", value);
                                                   }} value={values.document} defaultValue={values.document} maxLength={18} />

                                                   <div className="text-muted fs-7">Um nome de produto é obrigatório e recomendado para ser exclusivo.</div>
                                                   {errors.document && <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{errors.document}</div>}


                                                </div>

                                                <div className="mb-10 fv-row fv-plugins-icon-container">

                                                   <label className="required form-label">Razão social</label>
                                                   <input type="text" name="company_name" className="form-control mb-2" placeholder="Razão Social" onChange={handleChange} value={values.company_name} defaultValue={values.company_name} />

                                                   <div className="text-muted fs-7">Um nome de produto é obrigatório e recomendado para ser exclusivo.</div>
                                                   {errors.company_name && <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{errors.company_name}</div>}

                                                </div>

                                                <div className="fv-row fv-plugins-icon-container">

                                                   <label className="form-label">Nome fantasia</label>
                                                   <input type="text" name="fantasy_name" className="form-control mb-2" placeholder="Nome fantasia" onChange={handleChange} value={values.fantasy_name} defaultValue={values.fantasy_name} />

                                                   <div className="text-muted fs-7">Um nome de produto é obrigatório e recomendado para ser exclusivo.</div>
                                                   {errors.fantasy_name && <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{errors.fantasy_name}</div>}

                                                </div>





                                             </div>

                                          </div>

                                          <div className="card card-flush py-4">
                                             <div className="card-header">
                                                <div className="card-title">
                                                   <h2>E-mail</h2>
                                                </div>
                                             </div>

                                             <div className="card-body pt-0">
                                                <FieldArray
                                                   name="emails"
                                                   render={({ remove, push }) => (
                                                      <>
                                                         <div className="form-group">
                                                            <div className="d-flex flex-column gap-3">
                                                               {values.emails.map(
                                                                  (
                                                                     email,
                                                                     index
                                                                  ) => (
                                                                     <div
                                                                        className="form-group d-flex flex-wrap align-items-center gap-5"
                                                                        key={index}
                                                                     >

                                                                        <FastField
                                                                           type="text"
                                                                           className="form-control mw-150 w-300px"
                                                                           name={`emails.${index}`}
                                                                           placeholder="E-mail"
                                                                           autoComplete="off"
                                                                           value={values.emails[index]}
                                                                        />

                                                                        {values
                                                                           .emails
                                                                           .length >
                                                                           1 && (
                                                                              <button
                                                                                 type="button"
                                                                                 className="btn btn-sm btn-icon btn-light-danger"
                                                                                 onClick={() => {
                                                                                    remove(
                                                                                       index
                                                                                    );
                                                                                 }}
                                                                              >
                                                                                 <i className="ki-duotone ki-cross fs-1">
                                                                                    <span className="path1"></span>
                                                                                    <span className="path2"></span>
                                                                                 </i>{" "}
                                                                              </button>
                                                                           )}
                                                                     </div>
                                                                  )
                                                               )}
                                                            </div>
                                                         </div>
                                                         {values.emails.length <=
                                                            7 && (
                                                               <div className="form-group mt-5">
                                                                  <button
                                                                     type="button"
                                                                     className="btn btn-sm btn-light-primary"
                                                                     onClick={() => {
                                                                        push({
                                                                           email: "",
                                                                           sector: "",
                                                                        });
                                                                     }}
                                                                  >
                                                                     <i className="ki-duotone ki-plus fs-2"></i>{" "}
                                                                     Adicionar um novo
                                                                     E-mail
                                                                  </button>
                                                               </div>
                                                            )}
                                                      </>
                                                   )}
                                                />
                                             </div>
                                          </div>


                                          <div className="card card-flush py-4">

                                             <div className="card-header">
                                                <div className="card-title">
                                                   <h2>Telefone</h2>
                                                </div>
                                             </div>

                                             <div className="card-body pt-0">
                                                <FieldArray
                                                   name="phones"
                                                   render={({ remove, push }) => (
                                                      <>
                                                         <div className="form-group">
                                                            <div className="d-flex flex-column gap-3">
                                                               {values.phones.map(
                                                                  (
                                                                     phone,
                                                                     index
                                                                  ) => (
                                                                     <div
                                                                        className="form-group d-flex flex-wrap align-items-center gap-5"
                                                                        key={index}
                                                                     >
                                                                        <input
                                                                           type="text"
                                                                           className="form-control mw-150 w-300px"
                                                                           name={`phones.${index}.email`}
                                                                           placeholder="Telefone"
                                                                           autoComplete="off"
                                                                           onChange={(event) => {
                                                                              let value = event.target.value.replace(/\D/g, ""); // Remove tudo que não for dígito

                                                                              if (value.length > 11) value = value.slice(0, 11); // Limita a 11 dígitos (DDD + 9 dígitos)

                                                                              // Aplica a máscara de telefone
                                                                              if (value.length > 10) {
                                                                                 // Formato para celular com 9 dígitos
                                                                                 value = value.replace(/^(\d{2})(\d)/, "($1) $2"); // (XX) XXXXX-XXXX
                                                                                 value = value.replace(/(\d{5})(\d)/, "$1-$2");
                                                                              } else if (value.length > 6) {
                                                                                 // Formato para telefone fixo
                                                                                 value = value.replace(/^(\d{2})(\d)/, "($1) $2"); // (XX) XXXX-XXXX
                                                                                 value = value.replace(/(\d{4})(\d)/, "$1-$2");
                                                                              } else if (value.length > 2) {
                                                                                 value = value.replace(/^(\d{2})(\d)/, "($1) $2"); // (XX) XXXX ou (XX) XXX
                                                                              }

                                                                              setFieldValue(`phones.${index}`, value);
                                                                           }}
                                                                           value={values.phones[index]}
                                                                        />

                                                                        {values
                                                                           .phones
                                                                           .length >
                                                                           1 && (
                                                                              <button
                                                                                 type="button"
                                                                                 className="btn btn-sm btn-icon btn-light-danger"
                                                                                 onClick={() => {
                                                                                    remove(
                                                                                       index
                                                                                    );
                                                                                 }}
                                                                              >
                                                                                 <i className="ki-duotone ki-cross fs-1">
                                                                                    <span className="path1"></span>
                                                                                    <span className="path2"></span>
                                                                                 </i>{" "}
                                                                              </button>
                                                                           )}
                                                                     </div>
                                                                  )
                                                               )}
                                                            </div>
                                                         </div>
                                                         {values.phones.length <=
                                                            7 && (
                                                               <div className="form-group mt-5">
                                                                  <button
                                                                     type="button"
                                                                     className="btn btn-sm btn-light-primary"
                                                                     onClick={() => {
                                                                        push({
                                                                           phone: "",
                                                                        });
                                                                     }}
                                                                  >
                                                                     <i className="ki-duotone ki-plus fs-2"></i>{" "}
                                                                     Adicionar um novo
                                                                     Numero
                                                                  </button>
                                                               </div>
                                                            )}
                                                      </>
                                                   )}
                                                />
                                             </div>

                                          </div>

                                       </div>
                                    </div>



                                    {/*<div className={`tab-pane fade ${tab === "advanced" && "active show"}`} id="kt_ecommerce_add_product_advanced" >
                                       <div className="d-flex flex-column gap-7 gap-lg-10">


                                          <div className="card card-flush py-4">

                                             <div className="card-header">
                                                <div className="card-title">
                                                   <h2>Inventário</h2>
                                                </div>
                                             </div>



                                             <div className="card-body pt-0">

                                                <div className="mb-10 fv-row fv-plugins-icon-container">

                                                   <label className="required form-label">Identificação</label>
                                                   <input type="text" value={values.identification} name="identification" className="form-control mb-2" placeholder="Identificação" onChange={handleChange} />
                                                   <div className="text-muted fs-7">Enter the product SKU.</div>
                                                   <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                </div>

                                                <div className="mb-10 fv-row fv-plugins-icon-container">

                                                   <label className="required form-label">Codigo de barra</label>

                                                   <input type="text" name="barcode" value={values.barCode} className="form-control mb-2" placeholder="Codigo de barras" onChange={handleChange} />

                                                   <div className="text-muted fs-7">Insira o número do código de barras do produto.</div>

                                                   <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                </div>



                                                <div className=" fv-row fv-plugins-icon-container">

                                                   <label className="required form-label">Quantidade</label>

                                                   <input type="number" name="quantity" className="form-control mb-2" placeholder="Na prateleira" onChange={handleChange} value={values.quantity} />

                                                   <div className="text-muted fs-7">Insira a quantidade do produto.</div>

                                                   <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                </div>

                                             </div>

                                          </div>


                                          <div className="card card-flush py-4">

                                             <div className="card-header">
                                                <div className="card-title d-flex flex-column">
                                                   <h2>Envio</h2>
                                                </div>
                                             </div>



                                             <div className="card-body pt-0">

                                                <div id="kt_ecommerce_add_product_shipping" className="mt-2">

                                                   <div className="mb-10 fv-row">

                                                      <label className="form-label required">Peso</label>

                                                      <input type="text" name="weight" className="form-control mb-2" placeholder="Peso do produto" onChange={handleChange} value={values.weight} />

                                                      <div className="text-muted fs-7">Defina o peso do produto em quilogramas (kg).</div>

                                                   </div>



                                                   <div className="fv-row">

                                                      <label className="form-label required">Dimensões</label>

                                                      <div className="d-flex flex-wrap flex-sm-nowrap gap-3">
                                                         <input type="number" name="width" className="form-control mb-2" placeholder="Largura" onChange={handleChange} value={values.width} />
                                                         <input type="number" name="height" className="form-control mb-2" placeholder="Altura" onChange={handleChange} value={values.height} />
                                                         <input type="number" name="length" className="form-control mb-2" placeholder="Comprimento" onChange={handleChange} value={values.length} />
                                                      </div>

                                                      <div className="text-muted fs-7">Insira as dimensões do produto em centímetros (cm).</div>

                                                   </div>

                                                </div>

                                             </div>

                                          </div>


                                          <div className="card card-flush py-4">

                                             <div className="card-header">
                                                <div className="card-title">
                                                   <h2>Opções Meta</h2>
                                                </div>
                                             </div>



                                             <div className="card-body pt-0">

                                                <div className="mb-10">

                                                   <label className="form-label">Título da metatag</label>

                                                   <input type="text" className="form-control mb-2" name="title_meta" placeholder="Nome da meta tag" onChange={handleChange} value={values.title_meta} />

                                                   <div className="text-muted fs-7">Defina um título de metatag. Recomendado para palavras-chave simples e precisas.</div>

                                                </div>



                                                <div className="mb-10">

                                                   <label className="form-label">Descrição da metatag</label>
                                                   <textarea className="form-control mb-2" style={{ minHeight: "100px", resize: "none" }} name="description_meta" placeholder="Descrição da meta tag" onChange={handleChange} value={values.description_meta} />
                                                   <div className="text-muted fs-7">Defina uma descrição de meta tag para o produto para aumentar a classificação de SEO.</div>

                                                </div>



                                                <div>

                                                   <label className="form-label">Palavras-chave de metatag</label>



                                                   <input name="keyword_meta" className="form-control mb-2" onKeyDown={(e) => {
                                                      if (e.key === "Enter") {
                                                         e.preventDefault()
                                                         setFieldValue("keywords_meta", [...values.keywords_meta, e.currentTarget.value])
                                                         e.currentTarget.value = ""
                                                      }
                                                   }} />

                                                   <div className="text-muted fs-7">Defina uma lista de palavras-chave às quais o produto está relacionado. Adicione as palavras-chave prescionando a tecla<code>enter</code>.</div>
                                                   <div style={{ width: "800px" }}>
                                                      {values.keywords_meta.map((tag, key) => (
                                                         <span className="badge badge-light-success m-1 py-1 px-2" style={{ position: "relative" }} key={key}>
                                                            <button type="button" style={{
                                                               maskRepeat: "no-repeat",
                                                               maskPosition: "center",
                                                               display: "block",
                                                               opacity: 0.5,
                                                               border: 0,
                                                               top: "50%",
                                                               backgroundColor: "transparent",
                                                               marginLeft: "0px",
                                                               paddingLeft: "0px",
                                                            }} title="Remove item" onClick={() => { setFieldValue("keywords_meta", values.keywords_meta.filter((tagCurrent) => tagCurrent !== tag)) }}>
                                                               <span style={{ fontSize: "13px" }}>×</span>
                                                            </button>
                                                            <span style={{ fontSize: "12px" }}>{tag}</span>
                                                         </span>))}
                                                   </div>
                                                </div>

                                             </div>

                                          </div>
                                       </div>
                                    </div>*/}


                                 </div>


                                 <div className="d-flex justify-content-end">

                                    <a href="/metronic8/demo1/apps/ecommerce/catalog/products.html" id="kt_ecommerce_add_product_cancel" className="btn btn-light me-5">
                                       Cancelar
                                    </a>



                                    <button type="submit" id="kt_ecommerce_add_product_submit" className="btn btn-primary">
                                       <span className="indicator-label">
                                          Salvar produto
                                       </span>
                                       <span className="indicator-progress">
                                          Aguarde... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                       </span>
                                    </button>

                                 </div>
                              </div>


                           </Form>
                        )}

                     </Formik>


                  </div>

               </div>


            </div>
            <div className="app-footer">
               <div className="app-container  container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-gray-900 order-2 order-md-1">
                     <span className="text-muted fw-semibold me-1">2023©</span>
                     <a
                        href="#"
                        target="_blank"
                        className="text-gray-800 text-hover-primary"
                     >
                        Pommering
                     </a>
                  </div>
               </div>
            </div>{" "}
         </div>

      </>
   );
}

export { CreateSupplier };
