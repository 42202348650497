import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../hooks/auth";
import { Menu } from "../components/menu";
//import Menu from "../components/System/Menu";

const PrivateRoute = () => {
   const { user } = useAuth();

   return user ? (
      <Menu>
         <Outlet />
      </Menu>
   ) : (
      <Navigate to="/" />
   );
};

export default PrivateRoute;
