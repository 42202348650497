import axios, { AxiosError } from "axios";

axios.defaults.withCredentials = true;

const api = axios.create({
   baseURL: process.env.REACT_APP_BACKEND,
   withCredentials: true,
});



api.interceptors.response.use(
   (response: any) => {
      return response;
   },
   async function (error: AxiosError) {
      if (error.response?.status === 400) {
         throw error.response.data || "";
      }

      if (error.response?.status === 401) {
         //removeCookie("token");
         //localStorage.removeItem('@Cebraco:token');
         //localStorage.removeItem('@pommering:user');
         //window.location.href = "/";
      }
   }
);

export default api;
