import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../hooks/auth";

const CheckAuthenticate = () => {
   const { user } = useAuth();

   return user ? <Navigate to="/home" /> : <Outlet />;
};

export default CheckAuthenticate;
