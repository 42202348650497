import { useState } from "react";
import { useAuth } from "../../hooks/auth";

import Logo from "../../assets/media/logos/demo9.svg";
import { formatName } from "../../function/formatName";
import api from "../../service/api";
import { useTheme } from "../../hooks/theme";
import { useNavigate } from "react-router-dom";

export const Menu = ({ children }: { children: React.ReactElement | null }) => {
   const navigate = useNavigate();

   const { theme, toggleTheme } = useTheme();
   const { signOut, user } = useAuth();

   const [menu, setMenu] = useState<
      | "product"
      | "nfes"
      | "payment"
      | "budget"
      | "client"
      | "bill"
      | "suppliers"
   >();

   const [profileOptions, setProfileOptions] = useState(false);

   return (
      <>
         <div
            className="app-default"
            id="kt_app_body"
            data-kt-app-layout="dark-sidebar"
            data-kt-app-header-fixed="true"
            data-kt-app-sidebar-enabled="true"
            data-kt-app-sidebar-fixed="true"
            data-kt-app-sidebar-hoverable="true"
            data-kt-app-sidebar-push-header="true"
            data-kt-app-sidebar-push-toolbar="true"
            data-kt-app-sidebar-push-footer="true"
            data-kt-app-toolbar-enabled="true"
            cz-shortcut-listen="true"
            data-kt-drawer-chat="off"
            data-kt-drawer="off"
            style={{ overflow: "auto" }}
         >
            <div className="d-flex flex-column flex-root app-root">
               <div className="app-page  flex-column flex-column-fluid">
                  <div className="app-header">
                     <div className="app-container container-fluid d-flex align-items-stretch justify-content-between">
                        <div
                           className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2"
                           title="Show sidebar menu"
                        >
                           <div className="btn btn-icon btn-active-color-primary w-35px h-35px">
                              <i className="ki-duotone ki-abstract-14 fs-2 fs-md-1">
                                 <span className="path1" />
                                 <span className="path2" />
                              </i>{" "}
                           </div>
                        </div>
                        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                           <a href="#" className="d-lg-none">
                              <img alt="Logo" src={Logo} className="h-30px" />
                           </a>
                        </div>
                        <div className="d-flex align-items-stretch justify-content-end flex-lg-grow-1">
                           <div className="app-navbar flex-shrink-0">
                              <div className="app-navbar-item ms-1 ms-md-4">
                                 <div
                                    className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px"
                                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                    data-kt-menu-attach="parent"
                                    data-kt-menu-placement="bottom-end"
                                 >
                                    <i className="ki-duotone ki-element-11 fs-2">
                                       <span className="path1" />
                                       <span className="path2" />
                                       <span className="path3" />
                                       <span className="path4" />
                                    </i>{" "}
                                 </div>

                                 <div
                                    className="menu menu-sub menu-sub-dropdown menu-column w-100 w-sm-350px"
                                    data-kt-menu="true"
                                 >
                                    <div className="card">
                                       <div className="card-header">
                                          <div className="card-title">
                                             Ecommerces parceiros
                                          </div>

                                          <div className="card-toolbar">
                                             <button
                                                type="button"
                                                className="btn btn-sm btn-icon btn-active-light-primary me-n3"
                                                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                                data-kt-menu-placement="bottom-end"
                                             >
                                                <i className="ki-duotone ki-setting-3 fs-2">
                                                   <span className="path1" />
                                                   <span className="path2" />
                                                   <span className="path3" />
                                                   <span className="path4" />
                                                   <span className="path5" />
                                                </i>{" "}
                                             </button>

                                             <div
                                                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                                data-kt-menu="true"
                                             >
                                                <div className="menu-item px-3">
                                                   <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                                                      Payments
                                                   </div>
                                                </div>

                                                <div className="menu-item px-3">
                                                   <a
                                                      href="#"
                                                      className="menu-link px-3"
                                                   >
                                                      Create Invoice
                                                   </a>
                                                </div>

                                                <div className="menu-item px-3">
                                                   <a
                                                      href="#"
                                                      className="menu-link flex-stack px-3"
                                                   >
                                                      Create Payment
                                                      <span
                                                         className="ms-2"
                                                         data-bs-toggle="tooltip"
                                                         aria-label="Specify a target name for future usage and reference"
                                                         data-bs-original-title="Specify a target name for future usage and reference"
                                                         data-kt-initialized={1}
                                                      >
                                                         <i className="ki-duotone ki-information fs-6">
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                            <span className="path3" />
                                                         </i>{" "}
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="menu-item px-3">
                                                   <a
                                                      href="#"
                                                      className="menu-link px-3"
                                                   >
                                                      Generate Bill
                                                   </a>
                                                </div>

                                                <div
                                                   className="menu-item px-3"
                                                   data-kt-menu-trigger="hover"
                                                   data-kt-menu-placement="right-end"
                                                >
                                                   <a
                                                      href="#"
                                                      className="menu-link px-3"
                                                   >
                                                      <span className="menu-title">
                                                         Subscription
                                                      </span>
                                                      <span className="menu-arrow" />
                                                   </a>

                                                   <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                      <div className="menu-item px-3">
                                                         <a
                                                            href="#"
                                                            className="menu-link px-3"
                                                         >
                                                            Plans
                                                         </a>
                                                      </div>

                                                      <div className="menu-item px-3">
                                                         <a
                                                            href="#"
                                                            className="menu-link px-3"
                                                         >
                                                            Billing
                                                         </a>
                                                      </div>

                                                      <div className="menu-item px-3">
                                                         <a
                                                            href="#"
                                                            className="menu-link px-3"
                                                         >
                                                            Statements
                                                         </a>
                                                      </div>

                                                      <div className="separator my-2" />

                                                      <div className="menu-item px-3">
                                                         <div className="menu-content px-3">
                                                            <label className="form-check form-switch form-check-custom form-check-solid">
                                                               <input
                                                                  className="form-check-input w-30px h-20px"
                                                                  type="checkbox"
                                                                  defaultValue={
                                                                     1
                                                                  }
                                                                  name="notifications"
                                                               />

                                                               <span className="form-check-label text-muted fs-6">
                                                                  Recuring
                                                               </span>
                                                            </label>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>

                                                <div className="menu-item px-3 my-1">
                                                   <a
                                                      href="#"
                                                      className="menu-link px-3"
                                                   >
                                                      Settings
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div className="card-body py-5">
                                          <div className="mh-450px scroll-y me-n5 pe-5">
                                             <div className="row g-2">
                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/amazon.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         AWS
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/angular-icon-1.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         AngularJS
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/atica.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         Atica
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/beats-electronics.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         Music
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/codeigniter.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         Codeigniter
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/bootstrap-4.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         Bootstrap
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/google-tag-manager.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         GTM
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/disqus.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         Disqus
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/dribbble-icon-1.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         Dribble
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/google-play-store.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         Play Store
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/google-podcasts.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         Podcasts
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/figma-1.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         Figma
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/github.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         Github
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/gitlab.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         Gitlab
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/instagram-2-1.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         Instagram
                                                      </span>
                                                   </a>
                                                </div>

                                                <div className="col-4">
                                                   <a
                                                      href="#"
                                                      className="d-flex flex-column flex-center text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3"
                                                   >
                                                      <img
                                                         src="/metronic8/demo1/assets/media/svg/brand-logos/pinterest-p.svg"
                                                         className="w-25px h-25px mb-2"
                                                      />
                                                      <span className="fw-semibold">
                                                         Pinterest
                                                      </span>
                                                   </a>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div
                                 className="app-navbar-item ms-1 ms-md-4"
                                 onClick={() => {
                                    toggleTheme();
                                 }}
                              >
                                 <a
                                    href="#"
                                    className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px"
                                 >
                                    {theme === "dark" ? (
                                       <i className="ki-duotone ki-moon theme-dark-show fs-1">
                                          <span className="path1" />
                                          <span className="path2" />
                                       </i>
                                    ) : (
                                       <i className="ki-duotone ki-night-day theme-light-show fs-1">
                                          <span className="path1" />
                                          <span className="path2" />
                                          <span className="path3" />
                                          <span className="path4" />
                                          <span className="path5" />
                                          <span className="path6" />
                                          <span className="path7" />
                                          <span className="path8" />
                                          <span className="path9" />
                                          <span className="path10" />
                                       </i>
                                    )}
                                 </a>
                              </div>

                              <div
                                 className="app-navbar-item ms-1 ms-md-4"
                                 style={{ position: "relative" }}
                              >
                                 <div
                                    className="cursor-pointer symbol symbol-35px show menu-dropdown"
                                    onClick={() =>
                                       setProfileOptions((current) => !current)
                                    }
                                 >
                                    <div
                                       className="badge-light-primary"
                                       style={{
                                          height: "40px",
                                          width: "40px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          borderRadius: "50%",
                                       }}
                                    >
                                       {user?.name
                                          .charAt(0)
                                          .toLocaleUpperCase()}
                                    </div>
                                 </div>

                                 <div
                                    className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${profileOptions && `show`
                                       }`}
                                    style={{
                                       position: "absolute",
                                       inset: "0px 0px auto auto",
                                       margin: "0px",
                                       transform: "translate(0px, 74px)",
                                    }}
                                 >
                                    <div className="menu-item px-3">
                                       <div className="menu-content d-flex align-items-center px-3">
                                          <div className="symbol symbol-50px me-5">
                                             <div
                                                className="badge-light-primary"
                                                style={{
                                                   height: "40px",
                                                   width: "40px",
                                                   display: "flex",
                                                   justifyContent: "center",
                                                   alignItems: "center",
                                                   borderRadius: "50%",
                                                }}
                                             >
                                                {user?.name
                                                   .charAt(0)
                                                   .toLocaleUpperCase()}
                                             </div>

                                             {/*<img
                                          alt="Logo"
                                          src="/metronic8/demo1/assets/media/avatars/300-3.jpg"
                              />*/}
                                          </div>

                                          <div className="d-flex flex-column">
                                             <div className="fw-bold d-flex align-items-center fs-5">
                                                {formatName(user?.name)}
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="separator my-2" />

                                    <div className="menu-item px-5">
                                       <a href="#" className="menu-link px-5">
                                          Configurações
                                       </a>
                                    </div>

                                    <div className="menu-item px-5">
                                       <a
                                          href="#"
                                          className="menu-link px-5"
                                          onClick={() => {
                                             api.get("https://google.com");
                                          }}
                                       >
                                          My Statements
                                       </a>
                                    </div>

                                    <div className="separator my-2" />

                                    <div className="menu-item px-5">
                                       <a
                                          href="#"
                                          className="menu-link px-5"
                                          onClick={() => {
                                             signOut();
                                          }}
                                       >
                                          Sair
                                       </a>
                                    </div>
                                 </div>
                              </div>

                              <div
                                 className="app-navbar-item d-lg-none ms-2 me-n2"
                                 title="Show header menu"
                              >
                                 <div className="btn btn-flex btn-icon btn-active-color-primary w-30px h-30px">
                                    <i className="ki-duotone ki-element-4 fs-1">
                                       <span className="path1" />
                                       <span className="path2" />
                                    </i>{" "}
                                 </div>
                              </div>
                           </div>
                        </div>{" "}
                     </div>
                  </div>

                  <div className="app-wrapper  flex-column flex-row-fluid">
                     <div
                        className="app-sidebar flex-column"
                        data-kt-drawer="true"
                        data-kt-drawer-name="app-sidebar"
                        data-kt-drawer-activate="{default: true, lg: false}"
                        data-kt-drawer-overlay="true"
                        data-kt-drawer-width="225px"
                        data-kt-drawer-direction="start"
                        data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
                     >
                        <div className="app-sidebar-logo px-6">
                           <a href="#">
                              <img
                                 alt="Logo"
                                 src={Logo}
                                 className="h-25px app-sidebar-logo-default"
                              />
                              <img
                                 alt="Logo"
                                 src={Logo}
                                 className="h-20px app-sidebar-logo-minimize"
                              />
                           </a>

                           <div
                              className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary h-30px w-30px position-absolute top-50 start-100 translate-middle rotate "
                              data-kt-toggle="true"
                              data-kt-toggle-state="active"
                              data-kt-toggle-target="body"
                              data-kt-toggle-name="app-sidebar-minimize"
                           >
                              <i className="ki-duotone ki-black-left-line fs-3 rotate-180">
                                 <span className="path1" />
                                 <span className="path2" />
                              </i>{" "}
                           </div>
                        </div>
                        <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
                           <div className="app-sidebar-wrapper">
                              <div
                                 className="scroll-y my-5 mx-3"
                                 data-kt-scroll="true"
                                 data-kt-scroll-activate="true"
                                 data-kt-scroll-height="auto"
                                 data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
                                 data-kt-scroll-wrappers="#kt_app_sidebar_menu"
                                 data-kt-scroll-offset="5px"
                                 data-kt-scroll-save-state="true"
                                 style={{ height: 732 }}
                              >
                                 <div
                                    className="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6"
                                    data-kt-menu="true"
                                    data-kt-menu-expand="false"
                                 >
                                    <div
                                       data-kt-menu-trigger="click"
                                       className="menu-item here show"
                                    >
                                       <span className="menu-link">
                                          <span className="menu-icon">
                                             <i className="ki-duotone ki-element-11 fs-2">
                                                <span className="path1" />
                                                <span className="path2" />
                                                <span className="path3" />
                                                <span className="path4" />
                                             </i>
                                          </span>
                                          <span className="menu-title">
                                             Inicio
                                          </span>
                                       </span>
                                    </div>
                                    {/*<div
                                       data-kt-menu-trigger="click"
                                       className="menu-item menu-accordion"
                                    >
                                       <span className="menu-link">
                                          <span className="menu-icon">
                                             <i className="ki-duotone ki-address-book fs-2">
                                                <span className="path1" />
                                                <span className="path2" />
                                                <span className="path3" />
                                             </i>
                                          </span>
                                          <span className="menu-title">
                                             Nota fiscal
                                          </span>
                                       </span>
                                       </div>*/}
                                    <div
                                       onClick={() => {
                                          setMenu(
                                             menu != "payment"
                                                ? "payment"
                                                : undefined
                                          );
                                       }}
                                       className={`menu-item menu-accordion ${menu === "payment" && `hover show`
                                          } `}
                                    >
                                       <span className="menu-link">
                                          <span className="menu-icon">
                                             <i className="ki-duotone ki-element-plus fs-2">
                                                <span className="path1" />
                                                <span className="path2" />
                                                <span className="path3" />
                                                <span className="path4" />
                                                <span className="path5" />
                                             </i>
                                          </span>
                                          <span className="menu-title">
                                             Pagamentos
                                          </span>
                                          <span className="menu-arrow" />
                                       </span>

                                       <div className="menu-sub menu-sub-accordion">
                                          <div className="menu-item">
                                             <a className="menu-link" onClick={() => {
                                                navigate(
                                                   "/payment/create"
                                                )
                                             }}>
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">
                                                   Emitir pagamento de vendas
                                                </span>
                                             </a>
                                          </div>

                                          <div className="menu-item">
                                             <a className="menu-link" href="#">
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">
                                                   Visualizar
                                                </span>
                                             </a>
                                          </div>
                                       </div>
                                    </div>
                                    <div
                                       onClick={() => {
                                          setMenu(
                                             menu != "client"
                                                ? "client"
                                                : undefined
                                          );
                                       }}
                                       className={`menu-item menu-accordion ${menu === "client" && `hover show`
                                          }`}
                                    >
                                       <span className="menu-link">
                                          <span className="menu-icon">
                                             <i className="ki-duotone ki-file fs-2">
                                                <span className="path1" />
                                                <span className="path2" />
                                             </i>
                                          </span>
                                          <span className="menu-title">
                                             Clientes
                                          </span>
                                          <span className="menu-arrow" />
                                       </span>

                                       <div className="menu-sub menu-sub-accordion">
                                          <div className="menu-item">
                                             <a className="menu-link" href="#">
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">
                                                   Cadastrar
                                                </span>
                                             </a>
                                          </div>

                                          <div className="menu-item">
                                             <a className="menu-link" href="#">
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">
                                                   Lista Telefonica
                                                </span>
                                             </a>
                                          </div>
                                       </div>
                                    </div>
                                    <div
                                       onClick={() => {
                                          setMenu(
                                             menu != "product"
                                                ? "product"
                                                : undefined
                                          );
                                       }}
                                       className={`menu-item menu-accordion ${menu === "product" && `hover show`
                                          }`}
                                    >
                                       <span className="menu-link">
                                          <span className="menu-icon">
                                             <i className="ki-duotone ki-basket-ok fs-2">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                                <span className="path4"></span>
                                             </i>
                                          </span>
                                          <span className="menu-title">
                                             Produtos
                                          </span>
                                          <span className="menu-arrow" />
                                       </span>

                                       <div className="menu-sub menu-sub-accordion">
                                          <div className="menu-item">
                                             <a className="menu-link" onClick={() => {
                                                navigate(
                                                   "/product/create"
                                                )
                                             }}>
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">
                                                   Adicionar
                                                </span>
                                             </a>
                                          </div>

                                          <div className="menu-item">
                                             <a className="menu-link" onClick={() => {
                                                navigate(
                                                   "/product/list"
                                                )
                                             }}>
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">
                                                   Visualizar
                                                </span>
                                             </a>
                                          </div>
                                       </div>
                                    </div>

                                    <div
                                       onClick={() => {
                                          setMenu(
                                             menu != "nfes"
                                                ? "nfes"
                                                : undefined
                                          );
                                       }}
                                       className={`menu-item menu-accordion ${menu === "nfes" && `hover show`
                                          }`}
                                    >
                                       <span className="menu-link">
                                          <span className="menu-icon">
                                             <i className="ki-duotone ki-basket-ok fs-2">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                                <span className="path4"></span>
                                             </i>
                                          </span>
                                          <span className="menu-title">
                                             Notas Fiscais
                                          </span>
                                          <span className="menu-arrow" />
                                       </span>

                                       <div className="menu-sub menu-sub-accordion">
                                          <div className="menu-item">
                                             <a className="menu-link" href="#">
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">
                                                   Emitir
                                                </span>
                                             </a>
                                          </div>

                                          <div className="menu-item">
                                             <a className="menu-link" href="#">
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">
                                                   Visualizar
                                                </span>
                                             </a>
                                          </div>

                                          <div className="menu-item">
                                             <a
                                                className="menu-link"
                                                onClick={() =>
                                                   navigate(
                                                      "/product/import/nfe"
                                                   )
                                                }
                                             >
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">
                                                   Importar NFE
                                                </span>
                                             </a>
                                          </div>
                                       </div>
                                    </div>

                                    {/*<div
                                       onClick={() => {
                                          setMenu(
                                             menu != "budget"
                                                ? "budget"
                                                : undefined
                                          );
                                       }}
                                       className={`menu-item menu-accordion ${
                                          menu === "budget" && `hover show`
                                       }`}
                                    >
                                       <span className="menu-link">
                                          <span className="menu-icon">
                                             <i className="ki-duotone ki-abstract-39 fs-2">
                                                <span className="path1" />
                                                <span className="path2" />
                                             </i>
                                          </span>
                                          <span className="menu-title">
                                             Orçamento
                                          </span>
                                          <span className="menu-arrow" />
                                       </span>

                                       <div className="menu-sub menu-sub-accordion">
                                          <div className="menu-item">
                                             <a className="menu-link" href="#">
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">
                                                   Adicionar
                                                </span>
                                             </a>
                                          </div>

                                          <div className="menu-item">
                                             <a className="menu-link" href="#">
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">
                                                   Visualizar
                                                </span>
                                             </a>
                                          </div>
                                       </div>
                                    </div>*/}
                                    <div
                                       onClick={() => {
                                          setMenu(
                                             menu != "suppliers"
                                                ? "suppliers"
                                                : undefined
                                          );
                                       }}
                                       className={`menu-item menu-accordion ${menu === "suppliers" && `hover show`
                                          }`}
                                    >
                                       <span className="menu-link">
                                          <span className="menu-icon">
                                             <i className="ki-duotone ki-abstract-39 fs-2">
                                                <span className="path1" />
                                                <span className="path2" />
                                             </i>
                                          </span>
                                          <span className="menu-title">
                                             Fornecedores
                                          </span>
                                          <span className="menu-arrow" />
                                       </span>

                                       <div className="menu-sub menu-sub-accordion">
                                          <div className="menu-item">
                                             <a className="menu-link" onClick={() => {
                                                navigate(
                                                   "/supplier/create"
                                                )
                                             }}>
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">
                                                   Adicionar
                                                </span>
                                             </a>
                                          </div>

                                          <div className="menu-item">
                                             <a className="menu-link" href="#">
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title" onClick={() => {
                                                   navigate(
                                                      "/supplier/list"
                                                   )
                                                }}>
                                                   Visualizar
                                                </span>
                                             </a>
                                          </div>

                                          <div className="menu-item">
                                             <a className="menu-link" href="#">
                                                <span className="menu-bullet">
                                                   <span className="bullet bullet-dot" />
                                                </span>
                                                <span className="menu-title">
                                                   Novo Pedido
                                                </span>
                                             </a>
                                          </div>
                                       </div>
                                    </div>
                                    <div
                                       data-kt-menu-trigger="click"
                                       className="menu-item menu-accordion"
                                    >
                                       <span className="menu-link">
                                          <span className="menu-icon">
                                             <i className="ki-duotone ki-basket fs-2">
                                                <span className="path1" />
                                                <span className="path2" />
                                                <span className="path3" />
                                                <span className="path4" />
                                             </i>
                                          </span>
                                          <span className="menu-title">
                                             Estoque
                                          </span>
                                       </span>
                                    </div>
                                    {/*<div className="menu-item pt-5">
                                       <div className="menu-content">
                                          <span className="menu-heading fw-bold text-uppercase fs-7">
                                             eCommerce
                                          </span>
                                       </div>
                                    </div>
                                    <div
                                       data-kt-menu-trigger="click"
                                       className="menu-item menu-accordion"
                                    >
                                       <span className="menu-link">
                                          <span className="menu-icon">
                                             <i className="ki-duotone ki-basket fs-2">
                                                <span className="path1" />
                                                <span className="path2" />
                                                <span className="path3" />
                                                <span className="path4" />
                                             </i>
                                          </span>
                                          <span className="menu-title">
                                             Mercado Livre
                                          </span>
                                       </span>
                                    </div>
                                    <div
                                       data-kt-menu-trigger="click"
                                       className="menu-item menu-accordion"
                                    >
                                       <span className="menu-link">
                                          <span className="menu-icon">
                                             <i className="ki-duotone ki-abstract-25 fs-2">
                                                <span className="path1" />
                                                <span className="path2" />
                                             </i>
                                          </span>
                                          <span className="menu-title">
                                             Magalu
                                          </span>
                                       </span>
                                    </div>
                                    <div
                                       data-kt-menu-trigger="click"
                                       className="menu-item menu-accordion"
                                    >
                                       <span className="menu-link">
                                          <span className="menu-icon">
                                             <i className="ki-duotone ki-chart fs-2">
                                                <span className="path1" />
                                                <span className="path2" />
                                             </i>
                                          </span>
                                          <span className="menu-title">
                                             Americanas
                                          </span>
                                       </span>
                                    </div>
                                    <div className="menu-item pt-5">
                                       <div className="menu-content">
                                          <span className="menu-heading fw-bold text-uppercase fs-7">
                                             Ajuda
                                          </span>
                                       </div>
                                    </div>
                                    <div className="menu-item">
                                       <a
                                          className="menu-link"
                                          href="#"
                                          target="_blank"
                                       >
                                          <span className="menu-icon">
                                             <i className="ki-duotone ki-rocket fs-2">
                                                <span className="path1" />
                                                <span className="path2" />
                                             </i>
                                          </span>
                                          <span className="menu-title">
                                             Preciso de ajuda
                                          </span>
                                       </a>
                                    </div>*/}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6">
                           <a
                              href="#"
                              className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100"
                              data-bs-toggle="tooltip"
                              data-bs-trigger="hover"
                              data-bs-dismiss-="click"
                              data-bs-original-title="200+ in-house components and 3rd-party plugins"
                              data-kt-initialized={1}
                           >
                              <span className="btn-label">
                                 Api &amp; Documentação
                              </span>
                              <i className="ki-duotone ki-document btn-icon fs-2 m-0">
                                 <span className="path1" />
                                 <span className="path2" />
                              </i>{" "}
                           </a>
                        </div>{" "}
                     </div>
                     {children}
                  </div>
               </div>
            </div>
         </div>
         {/*<div style={{zIndex: 109}} className="drawer-overlay"></div>*/}
      </>
   );
};
