import React from "react";
import { ThemeProvider } from "./theme";
import { AuthProvider } from "./auth";

type BoxProps = {
   children: React.ReactNode; // 👈️ define children prop
};

const AppProvider: React.FC<BoxProps> = ({ children }) => (
   <ThemeProvider>
      <AuthProvider>{children}</AuthProvider>
   </ThemeProvider>
);

export default AppProvider;
