import Global from "./styles/global";

import { BrowserRouter } from "react-router-dom";
import RoutesPages from "./routes";
import AppProvider from "./hooks";

function App() {
   return (
      <>
         <AppProvider>
            <Global />
            <BrowserRouter>
               <RoutesPages />
            </BrowserRouter>
         </AppProvider>
      </>
   );
}

export default App;
