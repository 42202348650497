import React, { useState } from "react";

import Logo from "../../assets/media/logos/demo9.svg";

function HomeLast() {
   const [menu, setMenu] = useState<
      "product" | "nfe" | "nfse" | "payment" | "budget" | "client" | "bill"
   >();

   return (
      <>
         <div
            className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled"
            cz-shortcut-listen="true"
         >
            <div className="d-flex flex-column flex-root">
               <div className="page d-flex flex-row flex-column-fluid">
                  <div className="aside" data-kt-drawer="true">
                     <div className="aside-logo flex-column-auto pt-10 pt-lg-20">
                        <a href="index.html">
                           <img alt="Logo" src={Logo} className="h-50px" />
                        </a>
                     </div>
                     <div className="aside-menu flex-column-fluid pt-0 pb-7 py-lg-10">
                        <div
                           className="w-100 hover-scroll-y scroll-ms d-flex"
                           style={{ height: "600px" }}
                        >
                           <div className="menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-icon-gray-400 menu-arrow-gray-400 fw-semibold fs-6 my-auto">
                              <div
                                 className={` menu-item py-2  menu-dropdown ${
                                    menu === "product" && `show`
                                 }`}
                              >
                                 <span
                                    className="menu-link menu-center"
                                    onClick={() => {
                                       setMenu(
                                          menu === "product"
                                             ? undefined
                                             : "product"
                                       );
                                    }}
                                 >
                                    <span className="menu-icon me-0">
                                       <i className="ki-duotone ki-shop fs-2x">
                                          <span className="path1"></span>
                                          <span className="path2"></span>
                                          <span className="path3"></span>
                                          <span className="path4"></span>
                                          <span className="path5"></span>
                                       </i>
                                    </span>
                                 </span>
                                 <div
                                    className={`menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px mh-75 overflow-auto ${
                                       menu === "product" && `show`
                                    }`}
                                    style={{
                                       zIndex: 107,
                                       position: "fixed",
                                       marginTop: "-60px",
                                       left: "140px",
                                    }}
                                    data-popper-placement="right-start"
                                 >
                                    <div className="menu-item">
                                       <div className="menu-content">
                                          <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                                             Produtos
                                          </span>
                                       </div>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <div className="menu-link">
                                          <span className="menu-title">
                                             Adicionar Produtos
                                          </span>
                                       </div>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Estoque
                                          </span>
                                       </span>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Relatorio
                                          </span>
                                       </span>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Pedidos
                                          </span>
                                       </span>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Marketing place
                                          </span>
                                       </span>
                                    </div>
                                 </div>
                              </div>

                              <div
                                 className={`menu-item py-2 ${
                                    menu === "nfe" && `show`
                                 }`}
                              >
                                 <span
                                    className="menu-link menu-center"
                                    onClick={() => {
                                       setMenu(
                                          menu === "nfe" ? undefined : "nfe"
                                       );
                                    }}
                                 >
                                    <span className="menu-icon me-0">NFE</span>
                                 </span>
                                 <div
                                    className={`menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px mh-75 overflow-auto ${
                                       menu === "nfe" && `show`
                                    }`}
                                    style={{
                                       zIndex: 107,
                                       position: "fixed",
                                       marginTop: "-60px",
                                       left: "140px",
                                    }}
                                    data-popper-placement="right-start"
                                 >
                                    <div className="menu-item">
                                       <div className="menu-content">
                                          <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                                             Nota fiscal de produtos
                                          </span>
                                       </div>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Minhas Notas
                                          </span>
                                       </span>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Gerar Nota
                                          </span>
                                       </span>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Validar nota
                                          </span>
                                       </span>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Relatorio
                                          </span>
                                       </span>
                                    </div>
                                 </div>
                              </div>

                              <div
                                 className={`menu-item py-2 ${
                                    menu === "nfse" && `show`
                                 }`}
                              >
                                 <span
                                    className="menu-link menu-center"
                                    onClick={() => {
                                       setMenu(
                                          menu === "nfse" ? undefined : "nfse"
                                       );
                                    }}
                                 >
                                    <span className="menu-icon me-0">NFSE</span>
                                 </span>
                                 <div
                                    className={`menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px mh-75 overflow-auto ${
                                       menu === "nfse" && `show`
                                    }`}
                                    style={{
                                       zIndex: 107,
                                       position: "fixed",
                                       marginTop: "-60px",
                                       left: "140px",
                                    }}
                                    data-popper-placement="right-start"
                                 >
                                    <div className="menu-item">
                                       <div className="menu-content">
                                          <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                                             Nota fiscal de serviços
                                          </span>
                                       </div>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Minhas Notas
                                          </span>
                                       </span>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Gerar Nota
                                          </span>
                                       </span>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Validar nota
                                          </span>
                                       </span>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Relatorio
                                          </span>
                                       </span>
                                    </div>
                                 </div>
                              </div>

                              <div
                                 className={`menu-item py-2 ${
                                    menu === "payment" && `show`
                                 }`}
                              >
                                 <span
                                    className="menu-link menu-center"
                                    onClick={() => {
                                       setMenu(
                                          menu === "payment"
                                             ? undefined
                                             : "payment"
                                       );
                                    }}
                                 >
                                    <span className="menu-icon me-0">
                                       <i className="ki-duotone ki-dollar fs-2x">
                                          <span className="path1"></span>
                                          <span className="path2"></span>
                                          <span className="path3"></span>
                                       </i>
                                    </span>
                                 </span>
                                 <div
                                    className={`menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px mh-75 overflow-auto ${
                                       menu === "payment" && `show`
                                    }`}
                                    style={{
                                       zIndex: 107,
                                       position: "fixed",
                                       marginTop: "-60px",
                                       left: "140px",
                                    }}
                                    data-popper-placement="right-start"
                                 >
                                    <div className="menu-item">
                                       <div className="menu-content">
                                          <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                                             Pagamentos
                                          </span>
                                       </div>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Meus pagamentos
                                          </span>
                                       </span>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Emitir Boleto
                                          </span>
                                       </span>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Emitir Pix
                                          </span>
                                       </span>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Relatorio
                                          </span>
                                       </span>
                                    </div>
                                 </div>
                              </div>

                              <div
                                 className={`menu-item py-2 ${
                                    menu === "client" && `show`
                                 }`}
                              >
                                 <span
                                    className="menu-link menu-center"
                                    onClick={() => {
                                       setMenu(
                                          menu === "client"
                                             ? undefined
                                             : "client"
                                       );
                                    }}
                                 >
                                    <span className="menu-icon me-0">
                                       <i className="ki-duotone ki-profile-user fs-2x">
                                          <span className="path1"></span>
                                          <span className="path2"></span>
                                          <span className="path3"></span>
                                          <span className="path4"></span>
                                       </i>
                                    </span>
                                 </span>
                                 <div
                                    className={`menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px mh-75 overflow-auto ${
                                       menu === "client" && `show`
                                    }`}
                                    style={{
                                       zIndex: 107,
                                       position: "fixed",
                                       marginTop: "-60px",
                                       left: "140px",
                                    }}
                                    data-popper-placement="right-start"
                                 >
                                    <div className="menu-item">
                                       <div className="menu-content">
                                          <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                                             Clientes
                                          </span>
                                       </div>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Cadastrar
                                          </span>
                                       </span>
                                    </div>
                                 </div>
                              </div>

                              <div
                                 className={`menu-item py-2 ${
                                    menu === "budget" && `show`
                                 }`}
                              >
                                 <span
                                    className="menu-link menu-center"
                                    onClick={() => {
                                       setMenu(
                                          menu === "budget"
                                             ? undefined
                                             : "budget"
                                       );
                                    }}
                                 >
                                    <span className="menu-icon me-0">
                                       <i className="ki-duotone ki-cheque fs-2x">
                                          <span className="path1"></span>
                                          <span className="path2"></span>
                                          <span className="path3"></span>
                                          <span className="path4"></span>
                                          <span className="path5"></span>
                                          <span className="path6"></span>
                                          <span className="path7"></span>
                                       </i>
                                    </span>
                                 </span>
                                 <div
                                    className={`menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px mh-75 overflow-auto ${
                                       menu === "budget" && `show`
                                    }`}
                                    style={{
                                       zIndex: 107,
                                       position: "fixed",
                                       marginTop: "-60px",
                                       left: "140px",
                                    }}
                                    data-popper-placement="right-start"
                                 >
                                    <div className="menu-item">
                                       <div className="menu-content">
                                          <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                                             Orçamento
                                          </span>
                                       </div>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Orçamentos
                                          </span>
                                       </span>
                                    </div>
                                 </div>
                              </div>

                              <div
                                 className={`menu-item py-2 ${
                                    menu === "bill" && `show`
                                 }`}
                              >
                                 <span
                                    className="menu-link menu-center"
                                    onClick={() => {
                                       setMenu(
                                          menu === "bill" ? undefined : "bill"
                                       );
                                    }}
                                 >
                                    <span className="menu-icon me-0">
                                       <i className="ki-duotone ki-bill fs-2x">
                                          <span className="path1"></span>
                                          <span className="path2"></span>
                                          <span className="path3"></span>
                                          <span className="path4"></span>
                                          <span className="path5"></span>
                                          <span className="path6"></span>
                                       </i>
                                    </span>
                                 </span>
                                 <div
                                    className={`menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px mh-75 overflow-auto ${
                                       menu === "bill" && `show`
                                    }`}
                                    style={{
                                       zIndex: 107,
                                       position: "fixed",
                                       marginTop: "-60px",
                                       left: "140px",
                                    }}
                                    data-popper-placement="right-start"
                                 >
                                    <div className="menu-item">
                                       <div className="menu-content">
                                          <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                                             Orçamento
                                          </span>
                                       </div>
                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <span className="menu-link">
                                          <span className="menu-title">
                                             Orçamentos
                                          </span>
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="wrapper d-flex flex-column flex-row-fluid">
                     <div className="header-mobile py-3">
                        <div className="container d-flex flex-stack">
                           <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                              <a href="index.html">
                                 <img
                                    alt="Logo"
                                    src="assets/media/logos/demo9.svg"
                                    className="h-35px"
                                 />
                              </a>
                           </div>

                           <button className="btn btn-icon btn-active-color-primary me-n4">
                              <i className="ki-duotone ki-abstract-14 fs-2x">
                                 <span className="path1"></span>
                                 <span className="path2"></span>
                              </i>
                           </button>
                        </div>
                     </div>

                     <div className="header py-6 py-lg-0">
                        <div className="header-container container-xxl">
                           <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 py-3 py-lg-0 me-3">
                              <h1 className="d-flex flex-column text-dark fw-bold my-1">
                                 <span className="text-white fs-1">Inicio</span>
                              </h1>
                           </div>

                           <div className="d-flex align-items-center flex-wrap">
                              <div className="header-search py-3 py-lg-0 me-3"></div>

                              <div className="d-flex align-items-center py-3 py-lg-0">
                                 <div className="me-3">
                                    <a
                                       href="index.html#"
                                       className="btn btn-icon btn-custom btn-active-color-primary position-relative"
                                    >
                                       <i className="ki-duotone ki-notification-bing fs-1 text-white">
                                          <span className="path1"></span>
                                          <span className="path2"></span>
                                          <span className="path3"></span>
                                       </i>
                                       <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
                                    </a>

                                    <div className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px">
                                       <div
                                          className="d-flex flex-column bgi-no-repeat rounded-top"
                                          style={{
                                             backgroundImage:
                                                "url('assets/media/misc/menu-header-bg.jpg')",
                                          }}
                                       >
                                          <h3 className="text-white fw-semibold px-9 mt-10 mb-6">
                                             Notifications
                                             <span className="fs-8 opacity-75 ps-3">
                                                24 reports
                                             </span>
                                          </h3>

                                          <ul
                                             className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-semibold px-9"
                                             role="tablist"
                                          >
                                             <li className="nav-item">
                                                <a
                                                   className="nav-link text-white opacity-75 opacity-state-100 pb-4"
                                                   href="#"
                                                   tabIndex={-1}
                                                >
                                                   Alerts
                                                </a>
                                             </li>

                                             <li
                                                className="nav-item"
                                                role="presentation"
                                             >
                                                <a
                                                   className="nav-link text-white opacity-75 opacity-state-100 pb-4 active"
                                                   data-bs-toggle="tab"
                                                   href="index.html#kt_topbar_notifications_2"
                                                   aria-selected="true"
                                                   role="tab"
                                                >
                                                   Updates
                                                </a>
                                             </li>

                                             <li
                                                className="nav-item"
                                                role="presentation"
                                             >
                                                <a
                                                   className="nav-link text-white opacity-75 opacity-state-100 pb-4"
                                                   data-bs-toggle="tab"
                                                   href="index.html#kt_topbar_notifications_3"
                                                   aria-selected="false"
                                                   tabIndex={-1}
                                                   role="tab"
                                                >
                                                   Logs
                                                </a>
                                             </li>
                                          </ul>
                                       </div>

                                       <div className="tab-content">
                                          <div
                                             className="tab-pane fade"
                                             role="tabpanel"
                                          >
                                             <div className="scroll-y mh-325px my-5 px-8">
                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center">
                                                      <div className="symbol symbol-35px me-4">
                                                         <span className="symbol-label bg-light-primary">
                                                            <i className="ki-duotone ki-abstract-28 fs-2 text-primary">
                                                               <span className="path1"></span>
                                                               <span className="path2"></span>
                                                            </i>
                                                         </span>
                                                      </div>

                                                      <div className="mb-0 me-2">
                                                         <a
                                                            href="index.html#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                         >
                                                            Project Alice
                                                         </a>
                                                         <div className="text-gray-400 fs-7">
                                                            Phase 1 development
                                                         </div>
                                                      </div>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      1 hr
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center">
                                                      <div className="symbol symbol-35px me-4">
                                                         <span className="symbol-label bg-light-danger">
                                                            <i className="ki-duotone ki-information fs-2 text-danger">
                                                               <span className="path1"></span>
                                                               <span className="path2"></span>
                                                               <span className="path3"></span>
                                                            </i>
                                                         </span>
                                                      </div>

                                                      <div className="mb-0 me-2">
                                                         <a
                                                            href="index.html#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                         >
                                                            HR Confidential
                                                         </a>
                                                         <div className="text-gray-400 fs-7">
                                                            Confidential staff
                                                            documents
                                                         </div>
                                                      </div>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      2 hrs
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center">
                                                      <div className="symbol symbol-35px me-4">
                                                         <span className="symbol-label bg-light-warning">
                                                            <i className="ki-duotone ki-briefcase fs-2 text-warning">
                                                               <span className="path1"></span>
                                                               <span className="path2"></span>
                                                            </i>
                                                         </span>
                                                      </div>

                                                      <div className="mb-0 me-2">
                                                         <a
                                                            href="index.html#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                         >
                                                            Company HR
                                                         </a>
                                                         <div className="text-gray-400 fs-7">
                                                            Corporeate staff
                                                            profiles
                                                         </div>
                                                      </div>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      5 hrs
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center">
                                                      <div className="symbol symbol-35px me-4">
                                                         <span className="symbol-label bg-light-success">
                                                            <i className="ki-duotone ki-abstract-12 fs-2 text-success">
                                                               <span className="path1"></span>
                                                               <span className="path2"></span>
                                                            </i>
                                                         </span>
                                                      </div>

                                                      <div className="mb-0 me-2">
                                                         <a
                                                            href="index.html#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                         >
                                                            Project Redux
                                                         </a>
                                                         <div className="text-gray-400 fs-7">
                                                            New frontend admin
                                                            theme
                                                         </div>
                                                      </div>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      2 days
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center">
                                                      <div className="symbol symbol-35px me-4">
                                                         <span className="symbol-label bg-light-primary">
                                                            <i className="ki-duotone ki-colors-square fs-2 text-primary">
                                                               <span className="path1"></span>
                                                               <span className="path2"></span>
                                                               <span className="path3"></span>
                                                               <span className="path4"></span>
                                                            </i>
                                                         </span>
                                                      </div>

                                                      <div className="mb-0 me-2">
                                                         <a
                                                            href="index.html#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                         >
                                                            Project Breafing
                                                         </a>
                                                         <div className="text-gray-400 fs-7">
                                                            Product launch
                                                            status update
                                                         </div>
                                                      </div>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      21 Jan
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center">
                                                      <div className="symbol symbol-35px me-4">
                                                         <span className="symbol-label bg-light-info">
                                                            <i className="ki-duotone ki-picture fs-2 text-info"></i>
                                                         </span>
                                                      </div>

                                                      <div className="mb-0 me-2">
                                                         <a
                                                            href="index.html#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                         >
                                                            Banner Assets
                                                         </a>
                                                         <div className="text-gray-400 fs-7">
                                                            Collection of banner
                                                            images
                                                         </div>
                                                      </div>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      21 Jan
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center">
                                                      <div className="symbol symbol-35px me-4">
                                                         <span className="symbol-label bg-light-warning">
                                                            <i className="ki-duotone ki-color-swatch fs-2 text-warning">
                                                               <span className="path1"></span>
                                                               <span className="path2"></span>
                                                               <span className="path3"></span>
                                                               <span className="path4"></span>
                                                               <span className="path5"></span>
                                                               <span className="path6"></span>
                                                               <span className="path7"></span>
                                                               <span className="path8"></span>
                                                               <span className="path9"></span>
                                                               <span className="path10"></span>
                                                               <span className="path11"></span>
                                                               <span className="path12"></span>
                                                               <span className="path13"></span>
                                                               <span className="path14"></span>
                                                               <span className="path15"></span>
                                                               <span className="path16"></span>
                                                               <span className="path17"></span>
                                                               <span className="path18"></span>
                                                               <span className="path19"></span>
                                                               <span className="path20"></span>
                                                               <span className="path21"></span>
                                                            </i>
                                                         </span>
                                                      </div>

                                                      <div className="mb-0 me-2">
                                                         <a
                                                            href="index.html#"
                                                            className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                                         >
                                                            Icon Assets
                                                         </a>
                                                         <div className="text-gray-400 fs-7">
                                                            Collection of SVG
                                                            icons
                                                         </div>
                                                      </div>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      20 March
                                                   </span>
                                                </div>
                                             </div>

                                             <div className="py-3 text-center border-top">
                                                <a
                                                   href="pages/user-profile/activity.html"
                                                   className="btn btn-color-gray-600 btn-active-color-primary"
                                                >
                                                   View All
                                                   <i className="ki-duotone ki-arrow-right fs-5">
                                                      <span className="path1"></span>
                                                      <span className="path2"></span>
                                                   </i>
                                                </a>
                                             </div>
                                          </div>

                                          <div
                                             className="tab-pane fade show active"
                                             role="tabpanel"
                                          >
                                             <div className="d-flex flex-column px-9">
                                                <div className="pt-10 pb-0">
                                                   <h3 className="text-dark text-center fw-bold">
                                                      Get Pro Access
                                                   </h3>

                                                   <div className="text-center text-gray-600 fw-semibold pt-1">
                                                      Outlines keep you honest.
                                                      They stoping you from
                                                      amazing poorly about drive
                                                   </div>

                                                   <div className="text-center mt-5 mb-9">
                                                      <a
                                                         href="index.html#"
                                                         className="btn btn-sm btn-primary px-6"
                                                         data-bs-toggle="modal"
                                                         data-bs-target="#kt_modal_upgrade_plan"
                                                      >
                                                         Upgrade
                                                      </a>
                                                   </div>
                                                </div>

                                                <div className="text-center px-4">
                                                   <img
                                                      className="mw-100 mh-200px"
                                                      alt="image"
                                                      src="assets/media/illustrations/sigma-1/1.png"
                                                   />
                                                </div>
                                             </div>
                                          </div>

                                          <div
                                             className="tab-pane fade"
                                             role="tabpanel"
                                          >
                                             <div className="scroll-y mh-325px my-5 px-8">
                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center me-2">
                                                      <span className="w-70px badge badge-light-success me-4">
                                                         200 OK
                                                      </span>

                                                      <a
                                                         href="index.html#"
                                                         className="text-gray-800 text-hover-primary fw-semibold"
                                                      >
                                                         New order
                                                      </a>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      Just now
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center me-2">
                                                      <span className="w-70px badge badge-light-danger me-4">
                                                         500 ERR
                                                      </span>

                                                      <a
                                                         href="index.html#"
                                                         className="text-gray-800 text-hover-primary fw-semibold"
                                                      >
                                                         New customer
                                                      </a>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      2 hrs
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center me-2">
                                                      <span className="w-70px badge badge-light-success me-4">
                                                         200 OK
                                                      </span>

                                                      <a
                                                         href="index.html#"
                                                         className="text-gray-800 text-hover-primary fw-semibold"
                                                      >
                                                         Payment process
                                                      </a>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      5 hrs
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center me-2">
                                                      <span className="w-70px badge badge-light-warning me-4">
                                                         300 WRN
                                                      </span>

                                                      <a
                                                         href="index.html#"
                                                         className="text-gray-800 text-hover-primary fw-semibold"
                                                      >
                                                         Search query
                                                      </a>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      2 days
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center me-2">
                                                      <span className="w-70px badge badge-light-success me-4">
                                                         200 OK
                                                      </span>

                                                      <a
                                                         href="index.html#"
                                                         className="text-gray-800 text-hover-primary fw-semibold"
                                                      >
                                                         API connection
                                                      </a>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      1 week
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center me-2">
                                                      <span className="w-70px badge badge-light-success me-4">
                                                         200 OK
                                                      </span>

                                                      <a
                                                         href="index.html#"
                                                         className="text-gray-800 text-hover-primary fw-semibold"
                                                      >
                                                         Database restore
                                                      </a>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      Mar 5
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center me-2">
                                                      <span className="w-70px badge badge-light-warning me-4">
                                                         300 WRN
                                                      </span>

                                                      <a
                                                         href="index.html#"
                                                         className="text-gray-800 text-hover-primary fw-semibold"
                                                      >
                                                         System update
                                                      </a>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      May 15
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center me-2">
                                                      <span className="w-70px badge badge-light-warning me-4">
                                                         300 WRN
                                                      </span>

                                                      <a
                                                         href="index.html#"
                                                         className="text-gray-800 text-hover-primary fw-semibold"
                                                      >
                                                         Server OS update
                                                      </a>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      Apr 3
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center me-2">
                                                      <span className="w-70px badge badge-light-warning me-4">
                                                         300 WRN
                                                      </span>

                                                      <a
                                                         href="index.html#"
                                                         className="text-gray-800 text-hover-primary fw-semibold"
                                                      >
                                                         API rollback
                                                      </a>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      Jun 30
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center me-2">
                                                      <span className="w-70px badge badge-light-danger me-4">
                                                         500 ERR
                                                      </span>

                                                      <a
                                                         href="index.html#"
                                                         className="text-gray-800 text-hover-primary fw-semibold"
                                                      >
                                                         Refund process
                                                      </a>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      Jul 10
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center me-2">
                                                      <span className="w-70px badge badge-light-danger me-4">
                                                         500 ERR
                                                      </span>

                                                      <a
                                                         href="index.html#"
                                                         className="text-gray-800 text-hover-primary fw-semibold"
                                                      >
                                                         Withdrawal process
                                                      </a>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      Sep 10
                                                   </span>
                                                </div>

                                                <div className="d-flex flex-stack py-4">
                                                   <div className="d-flex align-items-center me-2">
                                                      <span className="w-70px badge badge-light-danger me-4">
                                                         500 ERR
                                                      </span>

                                                      <a
                                                         href="#"
                                                         className="text-gray-800 text-hover-primary fw-semibold"
                                                      >
                                                         Mail tasks
                                                      </a>
                                                   </div>

                                                   <span className="badge badge-light fs-8">
                                                      Dec 10
                                                   </span>
                                                </div>
                                             </div>

                                             <div className="py-3 text-center border-top">
                                                <a
                                                   href="pages/user-profile/activity.html"
                                                   className="btn btn-color-gray-600 btn-active-color-primary"
                                                >
                                                   View All
                                                   <i className="ki-duotone ki-arrow-right fs-5">
                                                      <span className="path1"></span>
                                                      <span className="path2"></span>
                                                   </i>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="me-3">
                                    <a
                                       href="index.html#"
                                       className="btn btn-icon btn-custom btn-active-color-primary"
                                    >
                                       <i className="ki-duotone ki-user fs-1 text-white">
                                          <span className="path1"></span>
                                          <span className="path2"></span>
                                       </i>
                                    </a>

                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px">
                                       <div className="menu-item px-3">
                                          <div className="menu-content d-flex align-items-center px-3">
                                             <div className="symbol symbol-50px me-5">
                                                <img
                                                   alt="Logo"
                                                   src="https://preview.keenthemes.com/metronic8/demo9/assets/media/avatars/300-1.jpg"
                                                />
                                             </div>

                                             <div className="d-flex flex-column">
                                                <div className="fw-bold d-flex align-items-center fs-5">
                                                   Max Smith
                                                   <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                                                      Pro
                                                   </span>
                                                </div>

                                                <a
                                                   href="index.html#"
                                                   className="fw-semibold text-muted text-hover-primary fs-7"
                                                >
                                                   max@kt.com
                                                </a>
                                             </div>
                                          </div>
                                       </div>

                                       <div className="separator my-2"></div>

                                       <div className="menu-item px-5">
                                          <a
                                             href="account/overview.html"
                                             className="menu-link px-5"
                                          >
                                             My Profile
                                          </a>
                                       </div>

                                       <div className="menu-item px-5">
                                          <a
                                             href="apps/projects/list.html"
                                             className="menu-link px-5"
                                          >
                                             <span className="menu-text">
                                                My Projects
                                             </span>
                                             <span className="menu-badge">
                                                <span className="badge badge-light-danger badge-circle fw-bold fs-7">
                                                   3
                                                </span>
                                             </span>
                                          </a>
                                       </div>

                                       <div className="menu-item px-5">
                                          <a
                                             href="account/statements.html"
                                             className="menu-link px-5"
                                          >
                                             My Statements
                                          </a>
                                       </div>

                                       <div className="separator my-2"></div>

                                       <div className="menu-item px-5 my-1">
                                          <a
                                             href="#"
                                             className="menu-link px-5"
                                          >
                                             Account Settings
                                          </a>
                                       </div>

                                       <div className="menu-item px-5">
                                          <a
                                             href="authentication/layouts/corporate/sign-in.html"
                                             className="menu-link px-5"
                                          >
                                             Sign Out
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="header-offset"></div>
                     </div>

                     <div className="content d-flex flex-column flex-column-fluid">
                        <div className="container-xxl">
                           <div className="row g-5 g-xl-8">
                              <div className="col-xl-4">
                                 <div className="row mb-5 mb-xl-8 g-5 g-xl-8">
                                    <div className="col-6">
                                       <div className="card card-stretch">
                                          <a
                                             href="#"
                                             className="btn btn-flex btn-text-gray-800 btn-icon-gray-400 btn-active-color-primary bg-body flex-column justfiy-content-start align-items-start text-start w-100 p-10"
                                          >
                                             <i className="ki-duotone ki-basket-ok fs-2tx mb-5 ms-n1">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                                <span className="path4"></span>
                                             </i>
                                             <span className="fs-4 fw-bold">
                                                NFE
                                             </span>
                                          </a>
                                       </div>
                                    </div>

                                    <div className="col-6">
                                       <div className="card card-stretch">
                                          <a
                                             href="account/statements.html"
                                             className="btn btn-flex btn-text-gray-800 btn-icon-gray-400 btn-active-color-primary bg-body flex-column justfiy-content-start align-items-start text-start w-100 p-10"
                                          >
                                             <i className="ki-duotone ki-wrench fs-2tx mb-5 ms-n1">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                             </i>

                                             <span className="fs-4 fw-bold">
                                                NFSE
                                             </span>
                                          </a>
                                       </div>
                                    </div>

                                    <div className="col-6">
                                       <div className="card card-stretch">
                                          <a
                                             href="#"
                                             className="btn btn-flex btn-text-gray-800 btn-icon-gray-400 btn-active-color-primary bg-body flex-column justfiy-content-start align-items-start text-start w-100 p-10"
                                          >
                                             <i className="ki-duotone ki-shop fs-2tx mb-5 ms-n1">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                                <span className="path4"></span>
                                                <span className="path5"></span>
                                             </i>
                                             <span className="fs-4 fw-bold">
                                                Produtos
                                             </span>
                                          </a>
                                       </div>
                                    </div>

                                    <div className="col-6">
                                       <div className="card card-stretch">
                                          <a
                                             href="#"
                                             className="btn btn-flex btn-text-gray-800 btn-icon-gray-400 btn-active-color-primary bg-body flex-column justfiy-content-start align-items-start text-start w-100 p-10"
                                          >
                                             <i className="ki-duotone ki-profile-user fs-2tx mb-5 ms-n1">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                                <span className="path4"></span>
                                             </i>
                                             <span className="fs-4 fw-bold">
                                                Clientes
                                             </span>
                                          </a>
                                       </div>
                                    </div>

                                    <div className="col-6">
                                       <div className="card card-stretch">
                                          <a
                                             href="apps/projects/project.html"
                                             className="btn btn-flex btn-text-gray-800 btn-icon-gray-400 btn-active-color-primary bg-body flex-column justfiy-content-start align-items-start text-start w-100 p-10"
                                          >
                                             <i className="ki-duotone ki-bill fs-2tx mb-5 ms-n1">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                                <span className="path4"></span>
                                                <span className="path5"></span>
                                                <span className="path6"></span>
                                             </i>

                                             <span className="fs-4 fw-bold">
                                                Pagamento
                                             </span>
                                          </a>
                                       </div>
                                    </div>

                                    <div className="col-6">
                                       <div className="card card-stretch">
                                          <a
                                             href="#"
                                             className="btn btn-flex btn-text-gray-800 btn-icon-gray-400 btn-active-color-primary bg-body flex-column justfiy-content-start align-items-start text-start w-100 p-10"
                                          >
                                             <i className="ki-duotone ki-cheque fs-2tx mb-5 ms-n1">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                                <span className="path4"></span>
                                                <span className="path5"></span>
                                                <span className="path6"></span>
                                                <span className="path7"></span>
                                             </i>

                                             <span className="fs-4 fw-bold">
                                                Orçamento
                                             </span>
                                          </a>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="card mb-5 mb-xl-8">
                                    <div className="card-header align-items-center border-0 mt-4">
                                       <h3 className="card-title align-items-start flex-column">
                                          <span className="fw-bold mb-2 text-dark">
                                             Atividades
                                          </span>
                                          <span className="text-muted fw-semibold fs-7">
                                             890.344 movimentações
                                          </span>
                                       </h3>
                                    </div>

                                    <div className="card-body pt-5">
                                       <div className="timeline-label">
                                          <div className="timeline-item">
                                             <div className="timeline-label fw-bold text-gray-800 fs-6">
                                                08:42
                                             </div>

                                             <div className="timeline-badge">
                                                <i className="fa fa-genderless text-warning fs-1"></i>
                                             </div>

                                             <div className="fw-mormal timeline-content text-muted ps-3">
                                                Outlines keep you honest. And
                                                keep structure
                                             </div>
                                          </div>

                                          <div className="timeline-item">
                                             <div className="timeline-label fw-bold text-gray-800 fs-6">
                                                10:00
                                             </div>

                                             <div className="timeline-badge">
                                                <i className="fa fa-genderless text-success fs-1"></i>
                                             </div>

                                             <div className="timeline-content d-flex">
                                                <span className="fw-bold text-gray-800 ps-3">
                                                   AEOL meeting
                                                </span>
                                             </div>
                                          </div>

                                          <div className="timeline-item">
                                             <div className="timeline-label fw-bold text-gray-800 fs-6">
                                                14:37
                                             </div>

                                             <div className="timeline-badge">
                                                <i className="fa fa-genderless text-danger fs-1"></i>
                                             </div>

                                             <div className="timeline-content fw-bold text-gray-800 ps-3">
                                                Make deposit
                                                <a
                                                   href="index.html#"
                                                   className="text-primary"
                                                >
                                                   USD 700
                                                </a>
                                                . to ESL
                                             </div>
                                          </div>

                                          <div className="timeline-item">
                                             <div className="timeline-label fw-bold text-gray-800 fs-6">
                                                16:50
                                             </div>

                                             <div className="timeline-badge">
                                                <i className="fa fa-genderless text-primary fs-1"></i>
                                             </div>

                                             <div className="timeline-content fw-mormal text-muted ps-3">
                                                Indulging in poorly driving and
                                                keep structure keep great
                                             </div>
                                          </div>

                                          <div className="timeline-item">
                                             <div className="timeline-label fw-bold text-gray-800 fs-6">
                                                21:03
                                             </div>

                                             <div className="timeline-badge">
                                                <i className="fa fa-genderless text-danger fs-1"></i>
                                             </div>

                                             <div className="timeline-content fw-semibold text-gray-800 ps-3">
                                                New order placed
                                                <a
                                                   href="index.html#"
                                                   className="text-primary"
                                                >
                                                   #XF-2356
                                                </a>
                                                .
                                             </div>
                                          </div>

                                          <div className="timeline-item">
                                             <div className="timeline-label fw-bold text-gray-800 fs-6">
                                                16:50
                                             </div>

                                             <div className="timeline-badge">
                                                <i className="fa fa-genderless text-primary fs-1"></i>
                                             </div>

                                             <div className="timeline-content fw-mormal text-muted ps-3">
                                                Indulging in poorly driving and
                                                keep structure keep great
                                             </div>
                                          </div>

                                          <div className="timeline-item">
                                             <div className="timeline-label fw-bold text-gray-800 fs-6">
                                                21:03
                                             </div>

                                             <div className="timeline-badge">
                                                <i className="fa fa-genderless text-danger fs-1"></i>
                                             </div>

                                             <div className="timeline-content fw-semibold text-gray-800 ps-3">
                                                New order placed
                                                <a
                                                   href="index.html#"
                                                   className="text-primary"
                                                >
                                                   #XF-2356
                                                </a>
                                                .
                                             </div>
                                          </div>

                                          <div className="timeline-item">
                                             <div className="timeline-label fw-bold text-gray-800 fs-6">
                                                10:30
                                             </div>

                                             <div className="timeline-badge">
                                                <i className="fa fa-genderless text-success fs-1"></i>
                                             </div>

                                             <div className="timeline-content fw-mormal text-muted ps-3">
                                                Finance KPI Mobile app launch
                                                preparion meeting
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className="col-xl-8 ps-xl-12">
                                 <div
                                    className="card bgi-position-y-bottom bgi-position-x-end bgi-no-repeat bgi-size-cover min-h-250px bg-primary mb-5 mb-xl-8"
                                    style={{
                                       backgroundPosition: "100% 50px",
                                       backgroundSize: "500px auto",
                                       backgroundImage:
                                          "url('assets/media/misc/city.png')",
                                    }}
                                 >
                                    <div className="card-body d-flex flex-column justify-content-center ps-lg-12">
                                       <h3 className="text-white fs-2qx fw-bold mb-7">
                                          Existem produtos <br />
                                          esperando o envio
                                       </h3>

                                       <div className="m-0">
                                          <a
                                             href="#"
                                             className="btn btn-success fw-semibold px-6 py-3"
                                             data-bs-target="#kt_modal_create_app"
                                          >
                                             Ver Produtos
                                          </a>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="card mb-5 mb-xl-8">
                                    <div className="card-header border-0 pt-5">
                                       <h3 className="card-title align-items-start flex-column">
                                          <span className="card-label fw-bold fs-3 mb-1">
                                             Serviços abertos
                                          </span>

                                          <span className="text-muted mt-1 fw-semibold fs-7">
                                             Mais de 400 novos serviços
                                          </span>
                                       </h3>
                                    </div>
                                    <div className="card-body py-3">
                                       <div className="tab-content">
                                          <div
                                             className="tab-pane fade show active"
                                             role="tabpanel"
                                          >
                                             <div className="table-responsive">
                                                <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                                                   <thead>
                                                      <tr className="border-0">
                                                         <th className="p-0 w-50px"></th>
                                                         <th className="p-0 min-w-150px"></th>
                                                         <th className="p-0 min-w-140px"></th>
                                                         <th className="p-0 min-w-110px"></th>
                                                         <th className="p-0 min-w-50px"></th>
                                                      </tr>
                                                   </thead>

                                                   <tbody>
                                                      <tr>
                                                         <td>
                                                            <div className="symbol symbol-45px me-2">
                                                               <span className="symbol-label">
                                                                  <img
                                                                     src="assets/media/svg/brand-logos/plurk.svg"
                                                                     className="h-50 align-self-center"
                                                                  />
                                                               </span>
                                                            </div>
                                                         </td>
                                                         <td>
                                                            <a
                                                               href="#"
                                                               className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                                            >
                                                               Brad Simmons
                                                            </a>
                                                            <span className="text-muted fw-semibold d-block">
                                                               Movie Creator
                                                            </span>
                                                         </td>
                                                         <td className="text-end text-muted fw-bold">
                                                            React, HTML
                                                         </td>
                                                         <td className="text-end">
                                                            <span className="badge badge-light-success">
                                                               Approved
                                                            </span>
                                                         </td>
                                                         <td className="text-end">
                                                            <a
                                                               href="index.html#"
                                                               className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                                            >
                                                               <i className="ki-duotone ki-arrow-right fs-2">
                                                                  <span className="path1"></span>
                                                                  <span className="path2"></span>
                                                               </i>
                                                            </a>
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td>
                                                            <div className="symbol symbol-45px me-2">
                                                               <span className="symbol-label">
                                                                  <img
                                                                     src="assets/media/svg/brand-logos/telegram.svg"
                                                                     className="h-50 align-self-center"
                                                                     alt=""
                                                                  />
                                                               </span>
                                                            </div>
                                                         </td>
                                                         <td>
                                                            <a
                                                               href="index.html#"
                                                               className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                                            >
                                                               Popular Authors
                                                            </a>
                                                            <span className="text-muted fw-semibold d-block">
                                                               Most Successful
                                                            </span>
                                                         </td>
                                                         <td className="text-end text-muted fw-bold">
                                                            Python, MySQL
                                                         </td>
                                                         <td className="text-end">
                                                            <span className="badge badge-light-warning">
                                                               In Progress
                                                            </span>
                                                         </td>
                                                         <td className="text-end">
                                                            <a
                                                               href="#"
                                                               className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                                            >
                                                               <i className="ki-duotone ki-arrow-right fs-2">
                                                                  <span className="path1"></span>
                                                                  <span className="path2"></span>
                                                               </i>
                                                            </a>
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td>
                                                            <div className="symbol symbol-45px me-2">
                                                               <span className="symbol-label">
                                                                  <img
                                                                     src="assets/media/svg/brand-logos/vimeo.svg"
                                                                     className="h-50 align-self-center"
                                                                  />
                                                               </span>
                                                            </div>
                                                         </td>
                                                         <td>
                                                            <a
                                                               href="#"
                                                               className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                                            >
                                                               New Users
                                                            </a>
                                                            <span className="text-muted fw-semibold d-block">
                                                               Awesome Users
                                                            </span>
                                                         </td>
                                                         <td className="text-end text-muted fw-bold">
                                                            Laravel,Metronic
                                                         </td>
                                                         <td className="text-end">
                                                            <span className="badge badge-light-primary">
                                                               Success
                                                            </span>
                                                         </td>
                                                         <td className="text-end">
                                                            <a
                                                               href="index.html#"
                                                               className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                                            >
                                                               <i className="ki-duotone ki-arrow-right fs-2">
                                                                  <span className="path1"></span>
                                                                  <span className="path2"></span>
                                                               </i>
                                                            </a>
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td>
                                                            <div className="symbol symbol-45px me-2">
                                                               <span className="symbol-label">
                                                                  <img
                                                                     src="assets/media/svg/brand-logos/bebo.svg"
                                                                     className="h-50 align-self-center"
                                                                  />
                                                               </span>
                                                            </div>
                                                         </td>
                                                         <td>
                                                            <a
                                                               href="index.html#"
                                                               className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                                            >
                                                               Active Customers
                                                            </a>
                                                            <span className="text-muted fw-semibold d-block">
                                                               Movie Creator
                                                            </span>
                                                         </td>
                                                         <td className="text-end text-muted fw-bold">
                                                            AngularJS, C#
                                                         </td>
                                                         <td className="text-end">
                                                            <span className="badge badge-light-danger">
                                                               Rejected
                                                            </span>
                                                         </td>
                                                         <td className="text-end">
                                                            <a
                                                               href="index.html#"
                                                               className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                                            >
                                                               <i className="ki-duotone ki-arrow-right fs-2">
                                                                  <span className="path1"></span>
                                                                  <span className="path2"></span>
                                                               </i>
                                                            </a>
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td>
                                                            <div className="symbol symbol-45px me-2">
                                                               <span className="symbol-label">
                                                                  <img
                                                                     src="assets/media/svg/brand-logos/kickstarter.svg"
                                                                     className="h-50 align-self-center"
                                                                  />
                                                               </span>
                                                            </div>
                                                         </td>
                                                         <td>
                                                            <a
                                                               href="index.html#"
                                                               className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                                            >
                                                               Bestseller Theme
                                                            </a>
                                                            <span className="text-muted fw-semibold d-block">
                                                               Best Customers
                                                            </span>
                                                         </td>
                                                         <td className="text-end text-muted fw-bold">
                                                            ReactJS, Ruby
                                                         </td>
                                                         <td className="text-end">
                                                            <span className="badge badge-light-warning">
                                                               In Progress
                                                            </span>
                                                         </td>
                                                         <td className="text-end">
                                                            <a
                                                               href="#"
                                                               className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                                            >
                                                               <i className="ki-duotone ki-arrow-right fs-2">
                                                                  <span className="path1"></span>
                                                                  <span className="path2"></span>
                                                               </i>
                                                            </a>
                                                         </td>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="card mb-5 mb-xl-8">
                                    <div className="card-header border-0 pt-5">
                                       <h3 className="card-title align-items-start flex-column">
                                          <span className="card-label fw-bold fs-3 mb-1">
                                             Vendas em aberto
                                          </span>

                                          <span className="text-muted mt-1 fw-semibold fs-7">
                                             Mais de 400 novas vendas
                                          </span>
                                       </h3>
                                    </div>

                                    <div className="card-body py-3">
                                       <div className="tab-content">
                                          <div className="tab-pane fade show active">
                                             <div className="table-responsive">
                                                <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                                                   <thead>
                                                      <tr className="border-0">
                                                         <th className="p-0 w-50px"></th>
                                                         <th className="p-0 min-w-150px"></th>
                                                         <th className="p-0 min-w-140px"></th>
                                                         <th className="p-0 min-w-110px"></th>
                                                         <th className="p-0 min-w-50px"></th>
                                                      </tr>
                                                   </thead>

                                                   <tbody>
                                                      <tr>
                                                         <td>
                                                            <div className="symbol symbol-45px me-2">
                                                               <span className="symbol-label">
                                                                  <img
                                                                     src="assets/media/svg/brand-logos/plurk.svg"
                                                                     className="h-50 align-self-center"
                                                                  />
                                                               </span>
                                                            </div>
                                                         </td>
                                                         <td>
                                                            <a
                                                               href="#"
                                                               className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                                            >
                                                               Brad Simmons
                                                            </a>
                                                            <span className="text-muted fw-semibold d-block">
                                                               Movie Creator
                                                            </span>
                                                         </td>
                                                         <td className="text-end text-muted fw-bold">
                                                            React, HTML
                                                         </td>
                                                         <td className="text-end">
                                                            <span className="badge badge-light-success">
                                                               Approved
                                                            </span>
                                                         </td>
                                                         <td className="text-end">
                                                            <a
                                                               href="#"
                                                               className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                                            >
                                                               <i className="ki-duotone ki-arrow-right fs-2">
                                                                  <span className="path1"></span>
                                                                  <span className="path2"></span>
                                                               </i>
                                                            </a>
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td>
                                                            <div className="symbol symbol-45px me-2">
                                                               <span className="symbol-label">
                                                                  <img
                                                                     src="assets/media/svg/brand-logos/telegram.svg"
                                                                     className="h-50 align-self-center"
                                                                  />
                                                               </span>
                                                            </div>
                                                         </td>
                                                         <td>
                                                            <a
                                                               href="#"
                                                               className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                                            >
                                                               Popular Authors
                                                            </a>
                                                            <span className="text-muted fw-semibold d-block">
                                                               Most Successful
                                                            </span>
                                                         </td>
                                                         <td className="text-end text-muted fw-bold">
                                                            Python, MySQL
                                                         </td>
                                                         <td className="text-end">
                                                            <span className="badge badge-light-warning">
                                                               In Progress
                                                            </span>
                                                         </td>
                                                         <td className="text-end">
                                                            <a
                                                               href="#"
                                                               className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                                            >
                                                               <i className="ki-duotone ki-arrow-right fs-2">
                                                                  <span className="path1"></span>
                                                                  <span className="path2"></span>
                                                               </i>
                                                            </a>
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td>
                                                            <div className="symbol symbol-45px me-2">
                                                               <span className="symbol-label">
                                                                  <img
                                                                     src="assets/media/svg/brand-logos/vimeo.svg"
                                                                     className="h-50 align-self-center"
                                                                  />
                                                               </span>
                                                            </div>
                                                         </td>
                                                         <td>
                                                            <a
                                                               href="#"
                                                               className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                                            >
                                                               New Users
                                                            </a>
                                                            <span className="text-muted fw-semibold d-block">
                                                               Awesome Users
                                                            </span>
                                                         </td>
                                                         <td className="text-end text-muted fw-bold">
                                                            Laravel,Metronic
                                                         </td>
                                                         <td className="text-end">
                                                            <span className="badge badge-light-primary">
                                                               Success
                                                            </span>
                                                         </td>
                                                         <td className="text-end">
                                                            <a
                                                               href="#"
                                                               className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                                            >
                                                               <i className="ki-duotone ki-arrow-right fs-2">
                                                                  <span className="path1"></span>
                                                                  <span className="path2"></span>
                                                               </i>
                                                            </a>
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td>
                                                            <div className="symbol symbol-45px me-2">
                                                               <span className="symbol-label">
                                                                  <img
                                                                     src="assets/media/svg/brand-logos/bebo.svg"
                                                                     className="h-50 align-self-center"
                                                                  />
                                                               </span>
                                                            </div>
                                                         </td>
                                                         <td>
                                                            <a
                                                               href="index.html#"
                                                               className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                                            >
                                                               Active Customers
                                                            </a>
                                                            <span className="text-muted fw-semibold d-block">
                                                               Movie Creator
                                                            </span>
                                                         </td>
                                                         <td className="text-end text-muted fw-bold">
                                                            AngularJS, C#
                                                         </td>
                                                         <td className="text-end">
                                                            <span className="badge badge-light-danger">
                                                               Rejected
                                                            </span>
                                                         </td>
                                                         <td className="text-end">
                                                            <a
                                                               href="#"
                                                               className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                                            >
                                                               <i className="ki-duotone ki-arrow-right fs-2">
                                                                  <span className="path1"></span>
                                                                  <span className="path2"></span>
                                                               </i>
                                                            </a>
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td>
                                                            <div className="symbol symbol-45px me-2">
                                                               <span className="symbol-label">
                                                                  <img
                                                                     src="assets/media/svg/brand-logos/kickstarter.svg"
                                                                     className="h-50 align-self-center"
                                                                  />
                                                               </span>
                                                            </div>
                                                         </td>
                                                         <td>
                                                            <a
                                                               href="#"
                                                               className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                                                            >
                                                               Bestseller Theme
                                                            </a>
                                                            <span className="text-muted fw-semibold d-block">
                                                               Best Customers
                                                            </span>
                                                         </td>
                                                         <td className="text-end text-muted fw-bold">
                                                            ReactJS, Ruby
                                                         </td>
                                                         <td className="text-end">
                                                            <span className="badge badge-light-warning">
                                                               In Progress
                                                            </span>
                                                         </td>
                                                         <td className="text-end">
                                                            <a
                                                               href="#"
                                                               className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                                            >
                                                               <i className="ki-duotone ki-arrow-right fs-2">
                                                                  <span className="path1"></span>
                                                                  <span className="path2"></span>
                                                               </i>
                                                            </a>
                                                         </td>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="footer py-4 d-flex flex-lg-column">
                           <div className="container-fluid d-flex flex-column flex-md-row flex-stack">
                              <div className="text-dark order-2 order-md-1">
                                 <span className="text-gray-400 fw-semibold me-1">
                                    Criado por pommering
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export { HomeLast };
