import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
:root {
   color-scheme: light;
   --bs-gray-100: #f9f9f9;
   --bs-gray-200: #f1f1f4;
   --bs-gray-300: #dbdfe9;
   --bs-gray-400: #c4cada;
   --bs-gray-500: #99a1b7;
   --bs-gray-600: #78829d;
   --bs-gray-700: #4b5675;
   --bs-gray-800: #252f4a;
   --bs-gray-900: #071437;
   --bs-light: #f9f9f9;
   --bs-primary: #1b84ff;
   --bs-secondary: #f9f9f9;
   --bs-success: #17c653;
   --bs-info: #7239ea;
   --bs-warning: #f6c000;
   --bs-danger: #f8285a;
   --bs-dark: #1e2129;
   --bs-light-rgb: 249, 249, 249;
   --bs-primary-rgb: 27, 132, 255;
   --bs-secondary-rgb: 249, 249, 249;
   --bs-success-rgb: 23, 198, 83;
   --bs-info-rgb: 114, 57, 234;
   --bs-warning-rgb: 246, 192, 0;
   --bs-danger-rgb: 248, 40, 90;
   --bs-dark-rgb: 30, 33, 41;
   --bs-primary-text-emphasis: #0b3566;
   --bs-secondary-text-emphasis: #646464;
   --bs-success-text-emphasis: #094f21;
   --bs-info-text-emphasis: #2e175e;
   --bs-warning-text-emphasis: #624d00;
   --bs-danger-text-emphasis: #631024;
   --bs-light-text-emphasis: #4b5675;
   --bs-dark-text-emphasis: #4b5675;
   --bs-primary-bg-subtle: #d1e6ff;
   --bs-secondary-bg-subtle: #fefefe;
   --bs-success-bg-subtle: #d1f4dd;
   --bs-info-bg-subtle: #e3d7fb;
   --bs-warning-bg-subtle: #fdf2cc;
   --bs-danger-bg-subtle: #fed4de;
   --bs-light-bg-subtle: #fcfcfc;
   --bs-dark-bg-subtle: #c4cada;
   --bs-primary-border-subtle: #a4ceff;
   --bs-secondary-border-subtle: #fdfdfd;
   --bs-success-border-subtle: #a2e8ba;
   --bs-info-border-subtle: #c7b0f7;
   --bs-warning-border-subtle: #fbe699;
   --bs-danger-border-subtle: #fca9bd;
   --bs-light-border-subtle: #f1f1f4;
   --bs-dark-border-subtle: #99a1b7;
   --bs-body-color: #071437;
   --bs-body-color-rgb: 7, 20, 55;
   --bs-body-bg: #ffffff;
   --bs-body-bg-rgb: 255, 255, 255;
   --bs-emphasis-color: #000000;
   --bs-emphasis-color-rgb: 0, 0, 0;
   --bs-secondary-color: rgba(7, 20, 55, 0.75);
   --bs-secondary-color-rgb: 7, 20, 55;
   --bs-secondary-bg: #f1f1f4;
   --bs-secondary-bg-rgb: 241, 241, 244;
   --bs-tertiary-color: rgba(7, 20, 55, 0.5);
   --bs-tertiary-color-rgb: 7, 20, 55;
   --bs-tertiary-bg: #f9f9f9;
   --bs-tertiary-bg-rgb: 249, 249, 249;
   --bs-heading-color: #071437;
   --bs-link-color: #1b84ff;
   --bs-link-hover-color: #056ee9;
   --bs-link-color-rgb: 27, 132, 255;
   --bs-link-hover-color-rgb: 5, 110, 233;
   --bs-highlight-color: #071437;
   --bs-highlight-bg: #fff3cd;
   --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
   --bs-form-valid-color: #17c653;
   --bs-form-valid-border-color: #17c653;
   --bs-form-invalid-color: #f8285a;
   --bs-form-invalid-border-color: #f8285a;
   --bs-gray-100-rgb: 249, 249, 249;
   --bs-gray-200-rgb: 241, 241, 244;
   --bs-gray-300-rgb: 219, 223, 233;
   --bs-gray-400-rgb: 196, 202, 218;
   --bs-gray-500-rgb: 153, 161, 183;
   --bs-gray-600-rgb: 120, 130, 157;
   --bs-gray-700-rgb: 75, 86, 117;
   --bs-gray-800-rgb: 37, 47, 74;
   --bs-gray-900-rgb: 7, 20, 55;
   --bs-primary-active: #056ee9;
   --bs-secondary-active: #dbdfe9;
   --bs-light-active: #fcfcfc;
   --bs-success-active: #04b440;
   --bs-info-active: #5014d0;
   --bs-warning-active: #dead00;
   --bs-danger-active: #d81a48;
   --bs-dark-active: #111318;
   --bs-primary-light: #e9f3ff;
   --bs-secondary-light: #f9f9f9;
   --bs-success-light: #dfffea;
   --bs-info-light: #f8f5ff;
   --bs-warning-light: #fff8dd;
   --bs-danger-light: #ffeef3;
   --bs-dark-light: #f9f9f9;
   --bs-light-light: #ffffff;
   --bs-secondary-inverse: #252f4a;
   --bs-light-inverse: #252f4a;
   --bs-primary-clarity: rgba(27, 132, 255, 0.2);
   --bs-secondary-clarity: rgba(249, 249, 249, 0.2);
   --bs-success-clarity: rgba(23, 198, 83, 0.2);
   --bs-info-clarity: rgba(114, 57, 234, 0.2);
   --bs-warning-clarity: rgba(246, 192, 0, 0.2);
   --bs-danger-clarity: rgba(248, 40, 90, 0.2);
   --bs-dark-clarity: rgba(30, 33, 41, 0.2);
   --bs-light-clarity: rgba(255, 255, 255, 0.2);
   --bs-text-primary: #1b84ff;
   --bs-text-secondary: #f9f9f9;
   --bs-text-light: #f9f9f9;
   --bs-text-success: #17c653;
   --bs-text-info: #7239ea;
   --bs-text-warning: #f6c000;
   --bs-text-danger: #f8285a;
   --bs-text-dark: #1e2129;
   --bs-text-muted: #99a1b7;
   --bs-text-gray-100: #f9f9f9;
   --bs-text-gray-200: #f1f1f4;
   --bs-text-gray-300: #dbdfe9;
   --bs-text-gray-400: #c4cada;
   --bs-text-gray-500: #99a1b7;
   --bs-text-gray-600: #78829d;
   --bs-text-gray-700: #4b5675;
   --bs-text-gray-800: #252f4a;
   --bs-text-gray-900: #071437;
   --bs-border-color: #f1f1f4;
   --bs-border-dashed-color: #dbdfe9;
   --bs-component-active-bg: #1b84ff;
   --bs-component-hover-color: #1b84ff;
   --bs-component-hover-bg: #f9f9f9;
   --bs-component-checked-bg: #1b84ff;
   --bs-root-card-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
   --bs-root-card-border-color: #f1f1f4;
   --bs-table-striped-bg: rgba(var(--bs-gray-100-rgb), 0.75);
   --bs-table-loading-message-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
   --bs-dropdown-bg: var(--bs-body-bg);
   --bs-dropdown-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
   --bs-code-bg: #f1f3f8;
   --bs-code-shadow: 0px 3px 9px rgba(0, 0, 0, 0.08);
   --bs-symbol-label-color: var(--bs-gray-800);
   --bs-symbol-label-bg: var(--bs-gray-100);
   --bs-symbol-border-color: rgba(var(--bs-body-bg), 0.5);
   --bs-bullet-bg-color: var(--bs-gray-400);
   --bs-scrolltop-box-shadow: var(--bs-box-shadow);
   --bs-scrolltop-bg-color: var(--bs-primary);
   --bs-scrolltop-bg-color-hover: var(--bs-primary);
   --bs-scrolltop-icon-color: var(--bs-primary-inverse);
   --bs-scrolltop-icon-color-hover: var(--bs-primary-inverse);
   --bs-drawer-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.05);
   --bs-drawer-bg-color: #ffffff;
   --bs-drawer-overlay-bg-color: rgba(0, 0, 0, 0.2);
   --bs-menu-dropdown-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
   --bs-menu-dropdown-bg-color: var(--bs-body-bg);
   --bs-menu-heading-color: #99a1b7;
   --bs-menu-link-color-hover: #1b84ff;
   --bs-menu-link-color-show: #1b84ff;
   --bs-menu-link-color-here: #1b84ff;
   --bs-menu-link-color-active: #1b84ff;
   --bs-menu-link-bg-color-hover: #f9f9f9;
   --bs-menu-link-bg-color-show: #f9f9f9;
   --bs-menu-link-bg-color-here: #f9f9f9;
   --bs-menu-link-bg-color-active: #f9f9f9;
   --bs-scrollbar-color: #f1f1f4;
   --bs-scrollbar-hover-color: #dbdfe9;
   --bs-overlay-bg: rgba(0, 0, 0, 0.05);
   --bs-blockui-overlay-bg: rgba(0, 0, 0, 0.05);
   --bs-rating-color-default: #c4cada;
   --bs-ribbon-label-box-shadow: 0px -1px 5px 0px rgba(30, 33, 41, 0.1);
   --bs-ribbon-clip-bg: #1e2129;
   --bs-engage-btn-bg: #ffffff;
   --bs-engage-btn-box-shadow: 0px 0px 22px #e0e0e0;
   --bs-engage-btn-border-color: #e8e8e8;
   --bs-engage-btn-color: #252f4a;
   --bs-engage-btn-icon-color: #78829d;
   --bs-engage-btn-color-active: #252f4a;
   --bs-app-bg-color: #fcfcfc;
   --bs-app-blank-bg-color: #fcfcfc;
   --bs-app-header-base-bg-color: transparent;
   --bs-app-header-minimize-bg-color: #ffffff;
   --bs-app-header-minimize-box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
   --bs-app-toolbar-base-bg-color: #ffffff;
   --bs-app-toolbar-base-box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
   --bs-app-toolbar-base-border-top: 1px dashed #dbdfe9;
   --bs-app-aside-base-bg-color: #ffffff;
   --bs-app-footer-box-shadow: 0px 10px 30px 0px rgba(49, 25, 79, 0.05);
   --bs-app-header-light-separator-color: #e4e6ef;
   --bs-app-sidebar-base-toggle-btn-box-shadow: 0px 8px 14px rgba(15, 42, 81, 0.04);
   --bs-app-sidebar-base-toggle-btn-bg-color: #ffffff;
   --bs-app-sidebar-base-toggle-btn-border-color: #f1f1f2;
   --bs-app-sidebar-base-border-color: #f1f1f2;
   --bs-app-sidebar-light-bg-color: #ffffff;
   --bs-app-sidebar-light-box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.05);
   --bs-app-sidebar-light-separator-color: #e4e6ef;
   --bs-app-sidebar-light-scrollbar-color-hover: #f1f1f4;
   --bs-app-sidebar-light-menu-heading-color: #b5b5c3;
   --bs-app-sidebar-light-menu-link-bg-color-active: #f7f8fb;
   --bs-app-sidebar-light-header-menu-link-bg-color-active: #f7f8fb;
   --bs-app-sidebar-light-menu-link-color: #252f4a;
   --bs-app-sidebar-light-menu-link-icon-color: #99a1b7;
}
   
`;
