export const formatName = (name: string | undefined) => {

   if (!name) {
      return "";
   }

   // Divida o nome em partes separadas por espaços
   const parts = name.split(' ');

   if (parts.length <= 2) {
      return name;
   }

   const last_name = parts.pop();
   const first_name = parts.shift();

   const middle_name = parts.map((name) => ` ${name.charAt(0)}.`);

   // Mapeie cada parte para manter apenas a primeira letra e substituir o resto por "."
   return `${first_name} ${middle_name} ${last_name}`;
}