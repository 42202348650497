import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
:root {
   color-scheme: dark;
   --bs-gray-100: #1b1c22;
   --bs-gray-200: #26272f;
   --bs-gray-300: #363843;
   --bs-gray-400: #464852;
   --bs-gray-500: #636674;
   --bs-gray-600: #808290;
   --bs-gray-700: #9a9cae;
   --bs-gray-800: #b5b7c8;
   --bs-gray-900: #f5f5f5;
   --bs-light: #1b1c22;
   --bs-primary: #006ae6;
   --bs-secondary: #363843;
   --bs-success: #00a261;
   --bs-info: #883fff;
   --bs-warning: #c59a00;
   --bs-danger: #e42855;
   --bs-dark: #272a34;
   --bs-light-rgb: 27, 28, 34;
   --bs-primary-rgb: 0, 106, 230;
   --bs-secondary-rgb: 54, 56, 67;
   --bs-success-rgb: 0, 162, 97;
   --bs-info-rgb: 136, 63, 255;
   --bs-warning-rgb: 197, 154, 0;
   --bs-danger-rgb: 228, 40, 85;
   --bs-dark-rgb: 39, 42, 52;
   --bs-primary-text-emphasis: #76b5ff;
   --bs-secondary-text-emphasis: #fbfbfb;
   --bs-success-text-emphasis: #74dd98;
   --bs-info-text-emphasis: #aa88f2;
   --bs-warning-text-emphasis: #fad966;
   --bs-danger-text-emphasis: #fb7e9c;
   --bs-light-text-emphasis: #f9f9f9;
   --bs-dark-text-emphasis: #dbdfe9;
   --bs-primary-bg-subtle: #051a33;
   --bs-secondary-bg-subtle: #323232;
   --bs-success-bg-subtle: #052811;
   --bs-info-bg-subtle: #170b2f;
   --bs-warning-bg-subtle: #312600;
   --bs-danger-bg-subtle: #320812;
   --bs-light-bg-subtle: #252f4a;
   --bs-dark-bg-subtle: #131825;
   --bs-primary-border-subtle: #104f99;
   --bs-secondary-border-subtle: #959595;
   --bs-success-border-subtle: #0e7732;
   --bs-info-border-subtle: #44228c;
   --bs-warning-border-subtle: #947300;
   --bs-danger-border-subtle: #951836;
   --bs-light-border-subtle: #4b5675;
   --bs-dark-border-subtle: #252f4a;
   --bs-body-color: #f5f5f5;
   --bs-body-color-rgb: 245, 245, 245;
   --bs-body-bg: #15171c;
   --bs-body-bg-rgb: 21, 23, 28;
   --bs-emphasis-color: #ffffff;
   --bs-emphasis-color-rgb: 255, 255, 255;
   --bs-secondary-color: rgba(245, 245, 245, 0.75);
   --bs-secondary-color-rgb: 245, 245, 245;
   --bs-secondary-bg: #252f4a;
   --bs-secondary-bg-rgb: 37, 47, 74;
   --bs-tertiary-color: rgba(245, 245, 245, 0.5);
   --bs-tertiary-color-rgb: 245, 245, 245;
   --bs-tertiary-bg: #162241;
   --bs-tertiary-bg-rgb: 22, 34, 65;
   --bs-heading-color: #f5f5f5;
   --bs-link-color: #006ae6;
   --bs-link-hover-color: #3388eb;
   --bs-link-color-rgb: 0, 106, 230;
   --bs-link-hover-color-rgb: 51, 136, 235;
   --bs-highlight-color: #f5f5f5;
   --bs-highlight-bg: #664d03;
   --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
   --bs-form-valid-color: #75b798;
   --bs-form-valid-border-color: #75b798;
   --bs-form-invalid-color: #ea868f;
   --bs-form-invalid-border-color: #ea868f;
   --bs-gray-100-rgb: 27, 28, 34;
   --bs-gray-200-rgb: 38, 39, 47;
   --bs-gray-300-rgb: 54, 56, 67;
   --bs-gray-400-rgb: 70, 72, 82;
   --bs-gray-500-rgb: 99, 102, 116;
   --bs-gray-600-rgb: 128, 130, 144;
   --bs-gray-700-rgb: 154, 156, 174;
   --bs-gray-800-rgb: 181, 183, 200;
   --bs-gray-900-rgb: 245, 245, 245;
   --bs-primary-active: #107eff;
   --bs-secondary-active: #464852;
   --bs-light-active: #1f212a;
   --bs-success-active: #01bf73;
   --bs-info-active: #9e63ff;
   --bs-warning-active: #d9aa00;
   --bs-danger-active: #ff3767;
   --bs-dark-active: #2d2f39;
   --bs-primary-light: #172331;
   --bs-secondary-light: #363843;
   --bs-success-light: #1f212a;
   --bs-info-light: #272134;
   --bs-warning-light: #242320;
   --bs-danger-light: #302024;
   --bs-dark-light: #1e2027;
   --bs-light-light: #1f212a;
   --bs-secondary-inverse: #ffffff;
   --bs-light-inverse: #808290;
   --bs-primary-clarity: rgba(0, 106, 230, 0.2);
   --bs-secondary-clarity: rgba(54, 56, 67, 0.2);
   --bs-success-clarity: rgba(0, 162, 97, 0.2);
   --bs-info-clarity: rgba(136, 63, 255, 0.2);
   --bs-warning-clarity: rgba(197, 154, 0, 0.2);
   --bs-danger-clarity: rgba(228, 40, 85, 0.2);
   --bs-dark-clarity: rgba(39, 42, 52, 0.2);
   --bs-light-clarity: rgba(31, 33, 42, 0.2);
   --bs-text-primary: #006ae6;
   --bs-text-secondary: #363843;
   --bs-text-light: #1b1c22;
   --bs-text-success: #00a261;
   --bs-text-info: #883fff;
   --bs-text-warning: #c59a00;
   --bs-text-danger: #e42855;
   --bs-text-dark: #272a34;
   --bs-text-muted: #636674;
   --bs-text-gray-100: #1b1c22;
   --bs-text-gray-200: #26272f;
   --bs-text-gray-300: #363843;
   --bs-text-gray-400: #464852;
   --bs-text-gray-500: #636674;
   --bs-text-gray-600: #808290;
   --bs-text-gray-700: #9a9cae;
   --bs-text-gray-800: #b5b7c8;
   --bs-text-gray-900: #f5f5f5;
   --bs-border-color: #26272f;
   --bs-border-dashed-color: #363843;
   --bs-component-active-bg: #006ae6;
   --bs-component-hover-color: #006ae6;
   --bs-component-hover-bg: #1b1c22;
   --bs-component-checked-bg: #006ae6;
   --bs-root-card-box-shadow: none;
   --bs-root-card-border-color: #1e2027;
   --bs-table-striped-bg: rgba(27, 28, 34, 0.75);
   --bs-table-loading-message-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
   --bs-dropdown-bg: #1c1d22;
   --bs-dropdown-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
   --bs-code-bg: #2b2b40;
   --bs-code-shadow: rgba(0, 0, 0, 0.08) 0px 3px 9px 0px;
   --bs-symbol-label-color: #b5b7c8;
   --bs-symbol-label-bg: #1b1c22;
   --bs-symbol-border-color: rgba(255, 255, 255, 0.5);
   --bs-bullet-bg-color: #464852;
   --bs-scrolltop-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
   --bs-scrolltop-bg-color: #1b84ff;
   --bs-scrolltop-bg-color-hover: #1b84ff;
   --bs-scrolltop-icon-color: #ffffff;
   --bs-scrolltop-icon-color-hover: #ffffff;
   --bs-drawer-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
   --bs-drawer-bg-color: #1c1d22;
   --bs-drawer-overlay-bg-color: rgba(0, 0, 0, 0.4);
   --bs-menu-dropdown-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
   --bs-menu-dropdown-bg-color: #1c1d22;
   --bs-menu-heading-color: #636674;
   --bs-menu-link-color-hover: #006ae6;
   --bs-menu-link-color-show: #006ae6;
   --bs-menu-link-color-here: #006ae6;
   --bs-menu-link-color-active: #006ae6;
   --bs-menu-link-bg-color-hover: #1b1c22;
   --bs-menu-link-bg-color-show: #1b1c22;
   --bs-menu-link-bg-color-here: #1b1c22;
   --bs-menu-link-bg-color-active: #1b1c22;
   --bs-scrollbar-color: #26272f;
   --bs-scrollbar-hover-color: #363843;
   --bs-overlay-bg: rgba(255, 255, 255, 0.05);
   --bs-blockui-overlay-bg: rgba(255, 255, 255, 0.05);
   --bs-rating-color-default: #464852;
   --bs-ribbon-label-box-shadow: 0px -1px 5px 0px rgba(255, 255, 255, 0.1);
   --bs-ribbon-clip-bg: #f9f9f9;
   --bs-engage-btn-bg: #26272f;
   --bs-engage-btn-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
   --bs-engage-btn-border-color: #26272f;
   --bs-engage-btn-color: #b5b7c8;
   --bs-engage-btn-icon-color: #808290;
   --bs-engage-btn-color-active: #b5b7c8;
   --bs-app-bg-color: #0f1014;
   --bs-app-blank-bg-color: #0f1014;
   --bs-app-header-base-bg-color: #0d0e12;
   --bs-app-header-minimize-bg-color: #0d0e12;
   --bs-app-header-minimize-box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
   --bs-app-toolbar-base-bg-color: #131313;
   --bs-app-toolbar-base-box-shadow: none;
   --bs-app-toolbar-base-border-top: 1px dashed #363843;
   --bs-app-aside-base-bg-color: #131313;
   --bs-app-footer-box-shadow: none;
   --bs-app-header-light-separator-color: #26272f;
   --bs-app-sidebar-base-toggle-btn-box-shadow: none;
   --bs-app-sidebar-base-toggle-btn-bg-color: #26272f;
   --bs-app-sidebar-base-toggle-btn-border-color: none;
   --bs-app-sidebar-base-border-color: none;
   --bs-app-sidebar-light-bg-color: #0d0e12;
   --bs-app-sidebar-light-box-shadow: none;
   --bs-app-sidebar-light-separator-color: #26272f;
   --bs-app-sidebar-light-scrollbar-color-hover: #26272f;
   --bs-app-sidebar-light-menu-heading-color: #636674;
   --bs-app-sidebar-light-menu-link-bg-color-active: #2a2a3c;
   --bs-app-sidebar-light-header-menu-link-bg-color-active: #1b1c22;
   --bs-app-sidebar-light-menu-link-color: #dbdfe9;
   --bs-app-sidebar-light-menu-link-icon-color: #7f8194;
}

.app-sidebar {
   border-right: 1px solid #1f212a !important;
}

`;
