import { GroupBase, StylesConfig } from "react-select";

interface Option {
   value: string;
   label: string;
}

export const StyleSelect: StylesConfig<Option, false, GroupBase<Option>> | undefined = {
   indicatorSeparator:
      (
         styles
      ) => ({
         ...styles,
         display:
            "none",
      }),
   dropdownIndicator:
      (
         styles
      ) => ({
         ...styles,
         color: "var(--bs-gray-500)",
      }),
   control: (
      styles
   ) => ({
      ...styles,
      backgroundColor:
         "var(--bs-body-bg)",
      color: "var(--bs-input-color)",
      fontSize: "1.15rem",
      fontWeight: 500,
      borderRadius:
         "0.475rem",
      lineHeight: 1.5,
      border:
         "1px solid var(--bs-gray-300) !important",
      boxShadow:
         "none",
      ":focus-within":
      {
         border:
            "1px solid var(--bs-gray-400) !important",
      },
      cursor:
         "pointer",
   }),
   placeholder: (
      styles
   ) => ({
      ...styles,
      paddingTop:
         "0.775rem",
      paddingBottom:
         "0.775rem",
      paddingLeft:
         "1rem",
      borderRadius:
         "0.625rem",
      fontSize:
         "1.10rem",
      color: "var(--bs-gray-500)",
   }),
   input: (
      styles
   ) => ({
      ...styles,
      paddingLeft:
         "1rem",
      borderRadius:
         "0.625rem",
      color: "var(--bs-input-color)",
      lineHeight: 1.5,
      fontSize:
         "1.10rem",
      margin: 0,
      paddingTop:
         "0.775rem",
      paddingBottom:
         "0.775rem",
      boxSizing:
         "border-box",
      fontFamily:
         "inherit",
   }),
   singleValue: (
      styles
   ) => ({
      ...styles,
      paddingTop:
         "0.775rem",
      paddingBottom:
         "0.775rem",
      paddingLeft:
         "1rem",
      borderRadius:
         "0.625rem",
      color: "var(--bs-input-color)",
      lineHeight: 1.5,
      fontSize:
         "1.10rem",
   }),
   valueContainer:
      (
         styles
      ) => ({
         ...styles,
         padding: 0,
         lineHeight: 1.5,
         fontSize:
            "1.10rem",
      }),
   menuList: (
      styles
   ) => ({
      ...styles,
      backgroundColor:
         "var(--bs-dropdown-bg)",
      borderRadius:
         "0.475rem",
      background:
         "var(--bs-dropdown-bg)",
   }),
   option: (
      styles,
      {
         isFocused,
         isSelected,
      }
   ) => {
      return {
         ...styles,
         backgroundColor:
            isSelected
               ? "var(--bs-gray-100)"
               : isFocused
                  ? "var(--bs-gray-100)"
                  : "var(--bs-gray-100)",
         color: isSelected
            ? "var(--bs-component-hover-color)"
            : isFocused
               ? "var(--bs-component-hover-color)"
               : undefined,
         ":active":
         {
            background:
               undefined,
         },
         border:
            undefined,
         cursor: "pointer",
      };
   },
   menu: (
      styles
   ) => ({
      ...styles,
      backgroundColor:
         "var(--bs-dropdown-bg)",
      borderRadius:
         "0.475rem",
   }),
}
