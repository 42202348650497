import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import Swal from "sweetalert2";

import image from '../../assets/media/image-default-dark.svg';
import { useDropzone } from "react-dropzone";
import { StyleSelect } from "../../styles/styleSelect";
import ReactSelect from "react-select";
import Editor from "../../components/TextArea";
import Quill from "quill";
import api from "../../service/api";
import { useParams } from "react-router-dom";
//import "quill/dist/quill.core.css";

//import "nouislider/distribute/nouislider.css";

export interface Root {
   supplier: Supplier;
   nfe_identification: string;
   produtos: Produto[];
}

export interface Supplier {
   cnpj: number;
   name: string;
}

export interface Produto {
   name: string;
   quantity: number;
   unit_price: number;
   total_price: number;
   unit_type: string;
   selected: boolean;
}

function EditProduct() {

   const { id } = useParams();

   const editorRef = useRef<Quill | null>(null);

   const [files, setFiles] = useState<File[]>([]);

   const [initValues, setInitValues] = useState<{
      main_image?: File,
      name: string,
      description: string,
      price: string,
      category?: string,
      quantity?: number,
      limit: number
   }>({
      name: '',
      description: '',
      price: 'R$ 0,00',
      category: undefined,
      quantity: undefined,
      limit: 0
   });

   const convertImageToFile = async (imagePath: string) => {
      try {
         const response = await api.get<Blob>('/image/', {
            responseType: 'blob', params: {
               img: imagePath
            }
         }); // Fetch the image


         // Create a new File object from the Blob
         const file = new File([response.data], 'image.jpg', { type: response.data.type });

         // Set the file in state (or process as needed)
         return file;


      } catch (error) {
         console.error('Error converting image to file:', error);
      }
   };


   useEffect(() => {
      api.get<{
         "id": string,
         "name": string,
         "description": string,
         "price": number,
         "category": string,
         "quantity": number,
         "min_quantity": number | null,
         "Product_images": {
            "id": string,
            "main": boolean,
            "path": string,
            "image": string
         }[]
      }>(`/product/${id}`).then(async (response) => {



         const mainImage = response.data.Product_images.find(image => image.main);

         setInitValues({
            main_image: mainImage ? await convertImageToFile(mainImage.path) : undefined,
            name: response.data.name,
            description: response.data.description,
            price: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(response.data.price),
            category: response.data.category,
            quantity: response.data.quantity,
            limit: response.data.min_quantity || 0
         });

         //editorRef?.current?.clipboard.dangerouslyPasteHTML(response.data.description);


         const mainImageFile = mainImage ? await convertImageToFile(mainImage.path) : undefined;

         setImagePreviewUrl(mainImageFile ? URL.createObjectURL(mainImageFile) : undefined);

         const files: (File | undefined)[] = await Promise.all(response.data.Product_images.filter(image => !image.main).map(async product => {
            return await convertImageToFile(product.path)
         }))

         setFiles(files.filter((file) => file !== undefined) as File[]);

         //editorRef.current?.setText("teste")

         setLoading(false)


      }).catch((error) => {
         console.log(error)
      })
   }, [id])



   const [imagePreviewUrl, setImagePreviewUrl] = useState<string | undefined>(undefined);

   const [fileUrls, setFileUrls] = useState<string[]>([]);

   useEffect(() => {
      // Limpa os URLs antigos
      fileUrls.forEach((url) => URL.revokeObjectURL(url));

      // Cria novos URLs
      const newFileUrls = files.map(file => URL.createObjectURL(file));
      setFileUrls(newFileUrls);

      // Cleanup function to revoke URLs when component unmounts or files change
      return () => {
         newFileUrls.forEach(url => URL.revokeObjectURL(url));
      };
   }, [files]);



   const onDrop = useCallback((acceptedFiles: File[]) => {


      if (files.length + acceptedFiles.length > 5) {
         Swal.fire({
            text: "Você pode adicionar no máximo 5 arquivos",
            icon: "error",
            showCancelButton: false,
            showConfirmButton: false,
            buttonsStyling: false,
            customClass: {
               icon: "my-2 mx-auto",
            },
         });
         return
      }


      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles.map((file) => file)]);
   }, [files]);

   const removeFile = (file: File) => {
      setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
   };

   const { getRootProps, open, getInputProps } = useDropzone({
      maxFiles: 10,
      onDrop,
      noClick: true,
   });


   const SignupSchema = Yup.object().shape({
      name: Yup.string()
         .min(2, 'Nome é muito curto')
         .required('O nome do produto é obrigatório'),
      price: Yup.string()
         .test('is-greater-than-zero', 'O valor deve ser maior que zero', (value) => !!value && (Number(value.replace(/\D/g, "")) / 100) > 0)
         .required('Campo obrigatório'),
   });

   const [categories, setCategories] = useState<{ id: string, name: string }[]>([]);

   // Use a ref to access the quill instance directly


   useEffect(() => {

      api.get<{ id: string, name: string }[]>('/category').then((response) => {

         setCategories(response.data)

      }).catch((error) => {
         console.log(error)
      })

   }, [])


   const [loading, setLoading] = useState(true);
   const [sending, setSeding] = useState(false);

   return (
      <>
         <div
            className="app-main flex-column flex-row-fluid"
            style={{ height: "calc(100vh - 74px)" }}
         >
            <div className="d-flex flex-column flex-column-fluid">
               <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
                  <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack ">
                     <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">

                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                           Editar produto
                        </h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

                           <li className="breadcrumb-item text-muted">
                              <a href="/metronic8/demo1/index.html" className="text-muted text-hover-primary">
                                 Home
                              </a>
                           </li>
                           <li className="breadcrumb-item">
                              <span className="bullet bg-gray-500 w-5px h-2px" />
                           </li>
                           <li className="breadcrumb-item text-muted">
                              eCommerce
                           </li>
                           <li className="breadcrumb-item">
                              <span className="bullet bg-gray-500 w-5px h-2px" />
                           </li>
                           <li className="breadcrumb-item text-muted">
                              Catalog
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>

               {loading ? (
                  <>
                     <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
                        Carregando...
                     </div>
                  </>
               ) : (
                  <>
                     <div className="app-content flex-column-fluid" style={{ display: `${loading ? "none" : "flex"}` }}>
                        <div className="app-container container-xxl">
                           <Formik
                              initialValues={initValues}
                              onSubmit={(values, { resetForm }) => {

                                 const formData = new FormData();
                                 values.main_image && formData.append('images', values.main_image);
                                 formData.append('name', values.name);
                                 formData.append('description', values.description);
                                 formData.append('price', `${parseInt(values.price.replace(/\D/g, "")) / 100}`);
                                 formData.append('quantity', values.quantity?.toString() || "0");
                                 formData.append('category', values.category || initValues.category || "");
                                 formData.append('min_quantity', values.limit.toString());
                                 files.forEach((image, key) => formData.append(`images`, image));
                                 setSeding(true);
                                 api.patch(`/product/${id}`, formData).then((response) => {

                                    setSeding(false);
                                    Swal.fire({
                                       text: "Produto criado com sucesso!",
                                       icon: "success",
                                       showCancelButton: false,
                                       showConfirmButton: false,
                                       buttonsStyling: false,
                                       customClass: {
                                          icon: "my-2 mx-auto",
                                       },
                                    });

                                    if (response.status != 201) {
                                       Swal.fire({
                                          text: "Erro ao editar produto!",
                                          icon: "error",
                                          showCancelButton: false,
                                          showConfirmButton: false,
                                          buttonsStyling: false,
                                          customClass: {
                                             icon: "my-2 mx-auto",
                                          },
                                       });
                                       return
                                    }


                                 }).catch((error) => {
                                    setSeding(false);
                                    console.log(error)
                                 })

                              }}
                              validationSchema={SignupSchema}
                              validateOnChange={false}
                              validateOnBlur={false}
                              enableReinitialize
                           >
                              {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
                                 <Form onSubmit={handleSubmit} className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework">

                                    <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                                       <div className="card card-flush py-4">
                                          <div className="card-header">
                                             <div className="card-title">
                                                <h2>Foto</h2>
                                             </div>
                                          </div>
                                          <div className="card-body text-center pt-0">
                                             <div className={`image-input ${!imagePreviewUrl ? "image-input-empty" : "image-input-changed"} image-input-outline image-input-placeholder mb-3`} data-kt-image-input="true" style={{ backgroundImage: `url(${image})` }}>
                                                <div className="image-input-wrapper w-150px h-150px" style={{ backgroundImage: `url(${imagePreviewUrl})` }} />
                                                <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" aria-label="Change avatar" data-bs-original-title="Change avatar" data-kt-initialized="1">
                                                   <i className="ki-duotone ki-pencil fs-7">
                                                      <span className="path1" />
                                                      <span className="path2" />
                                                   </i>
                                                   <input type="file" name="main_image" accept=".png, .jpg, .jpeg" onChange={(e) => {
                                                      setFieldValue("main_image", e.target.files?.[0]);

                                                      const file = e.target.files?.[0];

                                                      setImagePreviewUrl(file && URL.createObjectURL(file));


                                                   }} />
                                                   <input type="hidden" name="avatar_remove" />
                                                </label>
                                                <span onClick={() => { setFieldValue("main_image", undefined) }} className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" aria-label="Cancel avatar" data-bs-original-title="Cancel avatar" data-kt-initialized="1">
                                                   <i className="ki-duotone ki-cross fs-2">
                                                      <span className="path1" />
                                                      <span className="path2" />
                                                   </i>
                                                </span>

                                             </div>
                                             <div className="text-muted fs-7">Defina a imagem em miniatura do produto. Somente arquivos de imagem *.png, *.jpg e *.jpeg são aceitos</div>
                                          </div>
                                       </div>

                                       <div className="card card-flush py-4">

                                          <div className="card-header">

                                             <div className="card-title">
                                                <h2>Categoria</h2>
                                             </div>

                                          </div>

                                          <div className="card-body pt-0">
                                             <ReactSelect
                                                isSearchable={true}
                                                placeholder="Selecione o tipo"
                                                className="mb-2"
                                                noOptionsMessage={() => "Sem opção"}
                                                theme={(theme) => ({
                                                   ...theme,
                                                   colors: {
                                                      ...theme.colors,
                                                      primary: "var(--bs-gray-400)",
                                                   },
                                                })}
                                                onChange={(e) => {
                                                   setFieldValue("category", e?.value);
                                                }}
                                                value={
                                                   values.category
                                                      ? {
                                                         value: values.category,
                                                         label:
                                                            categories.find(
                                                               (category) => category.id === values.category
                                                            )?.name || "",
                                                      }
                                                      : null
                                                }
                                                styles={StyleSelect}
                                                options={categories.map((category) => ({
                                                   value: category.id,
                                                   label: category.name,
                                                }))}
                                             />

                                             <div className="text-muted fs-7 mb-7">Adicione produto a uma categoria.</div>

                                             <a href="/metronic8/demo1/apps/ecommerce/catalog/add-category.html" className="btn btn-light-primary btn-sm ">
                                                <i className="ki-duotone ki-plus fs-2"></i>
                                                Criar nova categoria
                                             </a>
                                          </div>
                                       </div>


                                       <div className="card card-flush py-4">

                                          <div className="card-header">

                                             <div className="card-title">
                                                <h2>Vendas semanais</h2>
                                             </div>

                                          </div>



                                          <div className="card-body pt-0">
                                             <span className="text-muted">Não há dados disponíveis. Os dados de vendas começarão a ser capturados assim que o produto for publicado.</span>
                                          </div>

                                       </div>


                                    </div>
                                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10" >

                                       <div className="tab-content" >

                                          <div className={`tab-pane fade active show`} >
                                             <div className="d-flex flex-column gap-7 gap-lg-10" >


                                                <div className="card card-flush py-4">

                                                   <div className="card-header">
                                                      <div className="card-title">
                                                         <h2>Geral</h2>
                                                      </div>
                                                   </div>

                                                   <div className="card-body pt-0">

                                                      {!loading ? (<>

                                                         <div className="mb-10 fv-row fv-plugins-icon-container">

                                                            <label className="required form-label">Nome do produto</label>
                                                            <input type="text" name="name" className="form-control mb-2" placeholder="Nome do produto" onChange={handleChange} value={values.name} />

                                                            <div className="text-muted fs-7">Um nome de produto é obrigatório e recomendado para ser exclusivo.</div>
                                                            {errors.name && <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{errors.name}</div>}


                                                         </div>

                                                         <div className="fv-row fv-plugins-icon-container">

                                                            <label className="form-label">Descrição</label>

                                                            <Editor
                                                               ref={editorRef}
                                                               readOnly={false}
                                                               defaultValue={initValues.description}
                                                               onTextChange={() => {
                                                                  setFieldValue("description", editorRef.current?.getSemanticHTML());
                                                               }}
                                                            />


                                                            <div className="text-muted fs-7 d-block">
                                                               Defina uma descrição do produto para melhor visibilidade.
                                                            </div>

                                                         </div>
                                                      </>) : (<div>Carregando...</div>)}




                                                   </div>

                                                </div>


                                                <div className="card card-flush py-4">

                                                   <div className="card-header">
                                                      <div className="card-title">
                                                         <h2>Imagens</h2>
                                                      </div>
                                                   </div>




                                                   <div className="card-body pt-0">

                                                      <div className="fv-row mb-2 w-100">
                                                         <div {...getRootProps({ className: 'dropzone dz-clickable' })} onClick={open} style={{ maxWidth: "860px" }}>
                                                            <input {...getInputProps()} />
                                                            <div className="dz-message needsclick">
                                                               <i className="ki-duotone ki-file-up text-primary fs-3x">
                                                                  <span className="path1"></span>
                                                                  <span className="path2"></span>
                                                               </i>
                                                               <div className="ms-4">
                                                                  <h3 className="fs-5 fw-bold text-gray-900 mb-1">Solte os arquivos aqui ou clique para fazer upload.</h3>
                                                                  <span className="fs-7 fw-semibold text-gray-500">Carregue até 5 arquivos</span>

                                                               </div>
                                                            </div>
                                                            {files.map((file, key) => (
                                                               <div key={key} className="dz-preview dz-processing dz-image-preview" onClick={(event) => {
                                                                  event.stopPropagation();
                                                               }}>
                                                                  <div className="dz-image">
                                                                     <img data-dz-thumbnail="" alt={file.name} src={fileUrls[key]} className="w-100 h-100" style={{ objectFit: "cover" }} />
                                                                  </div>
                                                                  <div className="dz-details">
                                                                     <div className="dz-size">
                                                                        <span><strong>{(file.size / 1024 / 1024).toFixed(2)}</strong> MB</span>
                                                                     </div>
                                                                     <div className="dz-filename">
                                                                        <span>{file.name}</span>
                                                                     </div>
                                                                  </div>
                                                                  <a style={{ zIndex: 5 }} className="dz-remove" onClick={() => { removeFile(file) }} data-dz-remove="">Remove file</a>
                                                               </div>
                                                            ))}
                                                         </div>
                                                      </div>

                                                   </div>
                                                </div>
                                                <div className="card card-flush py-4" >

                                                   <div className="card-header">
                                                      <div className="card-title">
                                                         <h2>Preço</h2>
                                                      </div>
                                                   </div>

                                                   <div className="card-body pt-0" >

                                                      <div className=" fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid">

                                                         <label className="required form-label">Preço base</label>

                                                         <input type="text" name="price" className="form-control mb-2" placeholder="Preço base" onChange={(event) => {
                                                            const value = event.target.value;
                                                            setFieldValue("price", new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                                                               Number(value.replace(/\D/g, "")) / 100
                                                            ))
                                                         }} value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                                                            Number(values.price.toString().replace(/\D/g, "")) / 100
                                                         )} disabled={sending} />

                                                         <div className="text-muted fs-7">Defina o preço do produto.</div>

                                                         {errors.price && <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{errors.price}</div>}

                                                      </div>

                                                   </div>

                                                </div>

                                                <div className="card card-flush py-4">

                                                   <div className="card-header">
                                                      <div className="card-title">
                                                         <h2>Inventário</h2>
                                                      </div>
                                                   </div>



                                                   <div className="card-body pt-0">

                                                      <div className="mb-10 fv-row fv-plugins-icon-container">

                                                         <label className="required form-label">Quantidade</label>

                                                         <input type="text" name="quantity" className="form-control mb-2" placeholder="Na prateleira" disabled={sending} onChange={(e) => {
                                                            setFieldValue("quantity", Number(e.target.value.replace(/\D/g, "")))
                                                         }} value={values.quantity ? Number(values.quantity) : 0} />

                                                         <div className="text-muted fs-7">Insira a quantidade do produto.</div>

                                                         <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">{errors.quantity}</div>
                                                      </div>


                                                      <div className=" fv-row fv-plugins-icon-container">

                                                         <label className="form-label">Limite minimo do estoque</label>

                                                         <input type="text" name="limit" value={values.limit} className="form-control mb-2" disabled={sending} placeholder="Limite" onChange={(e) => {
                                                            setFieldValue("limit", e.target.value.replace(/\D/g, ""))
                                                         }} />

                                                         <div className="text-muted fs-7">Insira o número do código de barras do produto.</div>

                                                         <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                      </div>

                                                   </div>
                                                </div>

                                             </div>
                                          </div>


                                       </div>


                                       <div className="d-flex justify-content-end">

                                          <a href="/metronic8/demo1/apps/ecommerce/catalog/products.html" className="btn btn-light me-5">
                                             Cancelar
                                          </a>
                                          <button type="submit" className="btn btn-primary">
                                             <span className="indicator-label">
                                                Editar produto
                                             </span>
                                             <span className="indicator-progress">
                                                Aguarde... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                             </span>
                                          </button>
                                       </div>
                                    </div>
                                 </Form>
                              )}
                           </Formik>
                        </div>
                     </div>
                  </>
               )}







            </div>
            <div className="app-footer">
               <div className="app-container  container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
                  <div className="text-gray-900 order-2 order-md-1">
                     <span className="text-muted fw-semibold me-1">2023©</span>
                     <a
                        href="#"
                        target="_blank"
                        className="text-gray-800 text-hover-primary"
                     >
                        Pommering
                     </a>
                  </div>
               </div>
            </div>{" "}
         </div>

      </>
   );
}

export { EditProduct };
