import React, { createContext, useContext, useState, ReactNode } from "react";

// Defina os tipos para os temas
type Theme = "light" | "dark";
type ThemeContextType = {
   theme: Theme;
   toggleTheme: () => void;
};

// Crie o contexto do tema
const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

// Crie um provedor de tema
export const ThemeProvider: React.FC<{ children: ReactNode }> = ({
   children,
}) => {
   const [theme, setTheme] = useState<Theme>(() => {
      const storedTheme = localStorage.getItem("theme");
      return (storedTheme as Theme) || "light";
   });

   const toggleTheme = () => {
      const newTheme = theme === "light" ? "dark" : "light";
      setTheme(newTheme);
      localStorage.setItem("theme", newTheme);
   };

   return (
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
         {children}
      </ThemeContext.Provider>
   );
};

// Crie um gancho personalizado para acessar o contexto do tema
export const useTheme = () => {
   const context = useContext(ThemeContext);
   if (context === undefined) {
      throw new Error("useTheme must be used within a ThemeProvider");
   }
   return context;
};
