import React, {
   forwardRef,
   useEffect,
   useLayoutEffect,
   useRef,
   MutableRefObject,
} from 'react';
import Quill, { QuillOptions } from 'quill';

interface EditorProps extends QuillOptions {
   readOnly?: boolean;
   defaultValue?: any; // Adjust the type as per the actual data structure
   onTextChange?: (...args: any[]) => void;
   onSelectionChange?: (...args: any[]) => void;
   value?: string; // New prop to handle value
   disabled?: boolean
}

const Editor = forwardRef<Quill | null, EditorProps>(
   ({ readOnly, defaultValue, onTextChange, onSelectionChange }, ref) => {
      const containerRef = useRef<HTMLDivElement | null>(null);

      const onTextChangeRef = useRef(onTextChange);
      const onSelectionChangeRef = useRef(onSelectionChange);

      useLayoutEffect(() => {
         onTextChangeRef.current = onTextChange;
         onSelectionChangeRef.current = onSelectionChange;
      });

      useEffect(() => {

         (ref as MutableRefObject<Quill | null>).current?.enable(!readOnly);
      }, [ref, readOnly]);


      useEffect(() => {
         const container = containerRef.current;
         if (container) {
            const editorContainer = container.appendChild(
               container.ownerDocument.createElement('div'),
            );
            const quill = new Quill(editorContainer, {
               theme: 'snow',
            });

            (ref as MutableRefObject<Quill | null>).current = quill;

            if (defaultValue) {
               quill.clipboard.dangerouslyPasteHTML(defaultValue);
            }


            quill.on(Quill.events.TEXT_CHANGE, (...args) => {
               onTextChangeRef.current?.(...args);
            });

            quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
               onSelectionChangeRef.current?.(...args);
            });

            return () => {
               (ref as MutableRefObject<Quill | null>).current = null;
               container.innerHTML = '';
            };
         }
      }, [ref, defaultValue]);

      return <div className="mb-2 create-product-description min-h-200px" style={{ width: `${containerRef.current?.clientWidth}px` }} ref={containerRef}></div>;
   },
);

Editor.displayName = 'Editor';

export default Editor;