import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`


:root {
   --bs-breakpoint-xs: 0;
   --bs-breakpoint-sm: 576px;
   --bs-breakpoint-md: 768px;
   --bs-breakpoint-lg: 992px;
   --bs-breakpoint-xl: 1200px;
   --bs-breakpoint-xxl: 1400px;

   --bs-xs: 0;
   --bs-sm: 576px;
   --bs-md: 768px;
   --bs-lg: 992px;
   --bs-xl: 1200px;
   --bs-xxl: 1400px;
   --bs-scrollbar-size: 5px;
   --bs-scrollbar-overlay-size: 19px;
   --bs-scrollbar-overlay-space: 7px;
   --bs-white-bg-rgb: 255, 255, 255;
   --bs-black-bg-rgb: 0, 0, 0;

   --bs-blue: #0d6efd;
   --bs-indigo: #6610f2;
   --bs-purple: #6f42c1;
   --bs-pink: #d63384;
   --bs-red: #dc3545;
   --bs-orange: #fd7e14;
   --bs-yellow: #ffc107;
   --bs-green: #198754;
   --bs-teal: #20c997;
   --bs-cyan: #0dcaf0;
   --bs-black: #000000;
   --bs-white: #ffffff;
   --bs-gray: #78829d;
   --bs-gray-dark: #252f4a;

   --bs-text-white: #ffffff;

   --bs-box-shadow-xs: 0 0.1rem 0.75rem 0.25rem rgba(0, 0, 0, 0.05);
   --bs-box-shadow-sm: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
   --bs-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
   --bs-box-shadow-lg: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1);

   --bs-input-color: var(--bs-gray-700);
   --bs-input-bg: var(--bs-body-bg);
   --bs-input-solid-color: var(--bs-gray-700);
   --bs-input-solid-bg: var(--bs-gray-100);
   --bs-input-solid-bg-focus: var(--bs-gray-200);
   --bs-input-solid-placeholder-color: var(--bs-gray-500);
   --bs-tooltip-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);

   --bs-code-border-color: transparent;
   --bs-code-color: #b93993;
   --bs-font-sans-serif: Inter, Helvetica, "sans-serif";
   --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
   --bs-gradient: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0)
   );
   --bs-body-font-family: var(--bs-font-sans-serif);
   --bs-body-font-size: 1rem;
   --bs-body-font-weight: 400;
   --bs-body-line-height: 1.5;
   --bs-white-rgb: 255, 255, 255;
   --bs-black-rgb: 0, 0, 0;
   --bs-border-width: 1px;
   --bs-border-style: solid;
   --bs-border-radius: 0.475rem;
   --bs-border-radius-sm: 0.425rem;
   --bs-border-radius-lg: 0.625rem;
   --bs-border-radius-xl: 1rem;
   --bs-border-radius-xxl: 2rem;
   --bs-border-radius-2xl: var(--bs-border-radius-xxl);
   --bs-border-radius-pill: 50rem;
   --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
   --bs-focus-ring-width: 0.25rem;
   --bs-focus-ring-opacity: 0.25;

   --bs-primary-inverse: #ffffff;
   --bs-success-inverse: #ffffff;
   --bs-info-inverse: #ffffff;
   --bs-warning-inverse: #ffffff;
   --bs-danger-inverse: #ffffff;
   --bs-dark-inverse: #ffffff;

   --bs-scrolltop-opacity: 0;
   --bs-scrolltop-opacity-on: 0.3;
   --bs-scrolltop-opacity-hover: 1;
   --bs-rating-color-active: #ffad0f;
   
   --bs-ribbon-label-bg: #1b84ff;
   --bs-ribbon-label-border-color: #0053b4;

   --bs-app-footer-bg-color: transparent;

   --bs-link-decoration: none;
   --bs-link-hover-decoration: none;

   --bs-focus-ring-color: rgba(27, 132, 255, 0.25);
   --bs-component-active-color: #ffffff;
   --bs-component-checked-color: #ffffff;

   --bs-app-header-base-box-shadow: none;
}

.create-product-description > .ql-container{
   height: 200px;
}

`;
