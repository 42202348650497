import { FastField, FieldArray, Form, Formik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";

import image from '../../assets/media/image-default-dark.svg';
import api from "../../service/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cnpj } from "cpf-cnpj-validator";

interface Product {
   response: Array<{
      id: string
      description: string
      category: string
      price: number
      name: string
      quantity: number
      Product_images: Array<{
         path: string
      }>
      image: string
      Avaliacao?: number
   }>
   pagination: {
      totalPages: number
      currentPage: number
   }
}

function ListProduct() {

   const navigate = useNavigate();

   const [products, setProducts] = useState<Product | undefined>(undefined);

   const [page, setPage] = useState(1);

   useEffect(() => {
      api.get<Product>(`/product?limit=10&page=${page}`).then(({ data }) => {
         setProducts(data);
      }).catch(error => {
         Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message,
         });
      });

   }, [page])


   return (
      <>
         <div
            className="app-main flex-column flex-row-fluid"
            style={{ height: "calc(100vh - 74px)" }}
         >

            <div className="d-flex flex-column flex-column-fluid">
               <div className="app-toolbar  py-3 py-lg-6 ">
                  <div className="app-container  container-xxl d-flex flex-stack ">
                     <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">

                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                           Produtos
                        </h1>

                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

                           <li className="breadcrumb-item text-muted">
                              <a href="/metronic8/demo1/index.html" className="text-muted text-hover-primary">
                                 Inicio </a>
                           </li>


                           <li className="breadcrumb-item">
                              <span className="bullet bg-gray-500 w-5px h-2px"></span>
                           </li>


                           <li className="breadcrumb-item text-muted"> Produtos </li>


                        </ul>

                     </div>
                  </div>

               </div>

               <div className="app-content  flex-column-fluid ">

                  <div className="app-container  container-xxl ">

                     <div className="card card-flush">

                        <div className="card-header align-items-center py-5 gap-2 gap-md-5">

                           <div className="card-title">

                              <div className="d-flex align-items-center position-relative my-1">
                                 <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                 </i>
                                 <input type="text" className="form-control form-control-solid w-250px ps-12" placeholder="Pesquisar fornecedor" />
                              </div>

                           </div>
                           <div className="m-0" data-select2-id="select2-data-124-qass">

                              <a href="#" className="btn btn-flex btn-secondary fw-bold" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                 <i className="ki-duotone ki-filter fs-6 text-muted me-1"><span className="path1"></span><span className="path2"></span></i>
                                 Filtros
                              </a>

                              {/*show */}
                              <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px " data-kt-menu="true" id="kt_menu_66ec5fffc77df" style={{ zIndex: 107, position: 'absolute', margin: '10px 0px 0px 0px' }} data-select2-id="select2-data-kt_menu_66ec5fffc77df">

                                 <div className="px-7 py-5">
                                    <div className="fs-5 text-gray-900 fw-bold">Filter Options</div>
                                 </div>



                                 <div className="separator border-gray-200"></div>




                                 <div className="px-7 py-5" data-select2-id="select2-data-123-2e93">

                                    <div className="mb-10">

                                       <label className="form-label fw-semibold">Member Type:</label>



                                       <div className="d-flex">

                                          <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                                             <input className="form-check-input" type="checkbox" value="1" />
                                             <span className="form-check-label">
                                                Author
                                             </span>
                                          </label>



                                          <label className="form-check form-check-sm form-check-custom form-check-solid">
                                             <input className="form-check-input" type="checkbox" value="2" />
                                             <span className="form-check-label">
                                                Customer
                                             </span>
                                          </label>

                                       </div>

                                    </div>



                                    <div className="mb-10">

                                       <label className="form-label fw-semibold">Notifications:</label>



                                       <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                          <input className="form-check-input" type="checkbox" value="" name="notifications" />
                                          <label className="form-check-label">
                                             Enabled
                                          </label>
                                       </div>

                                    </div>



                                    <div className="d-flex justify-content-end">
                                       <button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>

                                       <button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                                    </div>

                                 </div>

                              </div>
                           </div>
                           <div className="card-toolbar flex-row-fluid justify-content-end gap-5">

                              <a onClick={() => {
                                 navigate(
                                    "/supplier/create"
                                 )
                              }} className="btn btn-primary">
                                 Adicionar Fornecedor
                              </a>
                           </div>
                        </div>

                        <div className="card-body pt-0">
                           <div className="dt-container dt-bootstrap5 dt-empty-footer">
                              <div className="table-responsive">
                                 <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable" style={{ width: "100%" }}>
                                    <colgroup>
                                       <col style={{ width: "286.656px" }} />
                                       <col style={{ width: "143.328px" }} />
                                       <col style={{ width: "152.375px" }} />
                                       <col style={{ width: "143.328px" }} />
                                       <col style={{ width: "143.328px" }} />
                                       <col style={{ width: "150.766px" }} />
                                    </colgroup>
                                    <thead>
                                       <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                          <th className="min-w-200px dt-orderable-asc dt-orderable-desc" rowSpan={1} colSpan={1} tabIndex={0}>
                                             <span className="dt-column-title">Produto</span>
                                             <span className="dt-column-order"></span>
                                          </th>


                                          <th className="text-end min-w-100px dt-type-numeric dt-orderable-asc dt-orderable-desc" rowSpan={1} colSpan={1} tabIndex={0}>
                                             <span className="dt-column-title" >Identificação</span>
                                             <span className="dt-column-order"></span>
                                          </th>


                                          <th className="text-end min-w-70px dt-type-numeric dt-orderable-asc dt-orderable-desc" rowSpan={1} colSpan={1} tabIndex={0}>
                                             <span className="dt-column-title">Quantidade</span>
                                             <span className="dt-column-order"></span>
                                          </th>

                                          <th className="text-end min-w-70px dt-orderable-none" rowSpan={1} colSpan={1} aria-label="Actions">
                                             <span className="dt-column-title">Preço</span>
                                             <span className="dt-column-order"></span>
                                          </th>

                                          <th className="text-end min-w-70px dt-orderable-none" rowSpan={1} colSpan={1} aria-label="Actions">
                                             <span className="dt-column-title">Avaliação</span>
                                             <span className="dt-column-order"></span>
                                          </th>

                                          <th className="text-end min-w-70px dt-orderable-none" rowSpan={1} colSpan={1} aria-label="Actions">
                                             <span className="dt-column-title">Ações</span>
                                             <span className="dt-column-order"></span>
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-600">

                                       {products?.response.map((product) => (
                                          <tr key={product.id}>
                                             <td>
                                                <div className="d-flex align-items-center">
                                                   <a className="symbol symbol-50px">
                                                      {product.image ?
                                                         <span className="symbol-label" style={{ backgroundImage: `url(${product.image})` }}></span> :
                                                         <span className="symbol-label" >
                                                            <i className="ki-duotone ki-basket fs-1">
                                                               <span className="path1"></span>
                                                               <span className="path2"></span>
                                                               <span className="path3"></span>
                                                               <span className="path4"></span>
                                                            </i>
                                                         </span>}

                                                   </a>
                                                   <div className="ms-5">

                                                      <a className="text-gray-800 text-hover-primary fs-5 fw-bold" >{product.name}</a>
                                                   </div>
                                                </div>
                                             </td>
                                             <td className="text-end pe-0 dt-type-numeric">
                                                <span className="fw-bold">{product.id}</span>
                                             </td>
                                             <td className="text-end pe-0 dt-type-numeric">
                                                <span className="badge badge-light-warning">Low stock</span>
                                                <span className="fw-bold text-warning ms-3">{product.quantity}</span>
                                             </td>
                                             <td className="text-end pe-0 dt-type-numeric">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(product.price)}</td>
                                             <td className="text-end pe-0" data-order="rating-3">
                                                <div className="rating justify-content-end">

                                                   {product?.Avaliacao ? [1, 2, 3, 4, 5].map((rating, key) => (
                                                      <div key={rating} className={`rating-label ${key < (product?.Avaliacao || 0) && `checked`}`}>
                                                         <i className="ki-duotone ki-star fs-6"></i>
                                                      </div>
                                                   )) : `Sem avaliação`}

                                                </div>
                                             </td>
                                             <td className="text-end ">
                                                <div className="d-flex flex-end">
                                                   <a onClick={() => { navigate(`/product/edit/${product.id}`) }} className="btn btn-primary w-40px h-40px p-0 m-0 d-flex align-items-center justify-content-center me-5">
                                                      <i className="ki-duotone ki-pencil p-0 fs-3">
                                                         <span className="path1"></span>
                                                         <span className="path2"></span>
                                                      </i>
                                                   </a>
                                                   <a className="btn btn-danger w-40px h-40px p-0 m-0 d-flex align-items-center justify-content-center" onClick={() => {
                                                      Swal.fire({
                                                         title: `<h2 class="swal2-title" id="swal2-title" style="display: block;color: var(--bs-gray-800);">Você tem certeza?</h2>`,
                                                         text: "Você não poderá reverter isso!",
                                                         icon: 'warning',
                                                         showCancelButton: true,
                                                         confirmButtonColor: '#3085d6',
                                                         cancelButtonColor: '#d33',
                                                         confirmButtonText: 'Sim, excluir!',
                                                         cancelButtonText: 'Cancelar',

                                                      }).then((result) => {
                                                         if (result.isConfirmed) {
                                                            api.delete(`/product/${product.id}`).then(() => {
                                                               Swal.fire(
                                                                  '<h2 class="swal2-title" id="swal2-title" style="display: block;color: var(--bs-gray-800);">Excluído!</h2>',
                                                                  'Seu produto foi excluído.',
                                                                  'success'
                                                               );

                                                               api.get<Product>(`/product?limit=10&page=${page}`).then(({ data }) => {
                                                                  setProducts(data);
                                                               }).catch(error => {
                                                                  Swal.fire({
                                                                     icon: 'error',
                                                                     title: 'Oops...',
                                                                     text: error.response.data.message,
                                                                  });
                                                               });

                                                            }).catch(error => {
                                                               Swal.fire({
                                                                  icon: 'error',
                                                                  title: 'Oops...',
                                                                  text: error.response.data.message,
                                                               });
                                                            });
                                                         }
                                                      })
                                                   }}>
                                                      <i className="ki-duotone ki-trash p-0 fs-3">
                                                         <span className="path1"></span>
                                                         <span className="path2"></span>
                                                         <span className="path3"></span>
                                                         <span className="path4"></span>
                                                         <span className="path5"></span>
                                                      </i>
                                                   </a>
                                                </div>




                                             </td>
                                          </tr>
                                       ))}

                                    </tbody>
                                 </table>
                              </div>
                              <div className="row mt-4">
                                 <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar">
                                    <div className="d-flex mt-2">
                                       <select className="form-select form-select-solid form-select-sm" >
                                          <option value="10">10</option>
                                          <option value="25">25</option>
                                          <option value="50">50</option>
                                          <option value="100">100</option>
                                       </select>
                                       <label></label>
                                    </div>
                                 </div>
                                 <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                                    <div className="dt-paging paging_simple_numbers">
                                       <nav>
                                          <ul className="pagination">


                                             <li className={`dt-paging-button page-item ${products?.pagination && products.pagination.currentPage <= 1 && 'disabled'}`}>
                                                <button className="page-link previous" type="button" tabIndex={-1} onClick={() => setPage(page - 1)}>
                                                   <i className="previous"></i>
                                                </button>
                                             </li>


                                             {products?.pagination && products?.pagination.totalPages >= 1 && Array.from(Array(products?.pagination.totalPages).keys()).map((page) => (
                                                <li key={page} className={`dt-paging-button page-item ${products?.pagination.currentPage === page + 1 ? 'active' : ''}`}>
                                                   <button className="page-link" type="button" onClick={() => setPage(page + 1)}>{page + 1}</button>
                                                </li>
                                             ))}


                                             <li className={`dt-paging-button page-item ${products?.pagination && products.pagination.totalPages <= page && 'disabled'}`}>
                                                <button className="page-link next" type="button" tabIndex={-1} onClick={() => setPage(page + 1)}>
                                                   <i className="next"></i>
                                                </button>
                                             </li>

                                          </ul>
                                       </nav>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>

               </div>

            </div>

            <div className="app-footer">
               <div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3 ">

                  <div className="text-gray-900 order-2 order-md-1">
                     <span className="text-muted fw-semibold me-1">2024©</span>
                     <a href="https://keenthemes.com" target="_blank" className="text-gray-800 text-hover-primary">Keenthemes</a>
                  </div>



                  <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                     <li className="menu-item">
                        <a href="https://keenthemes.com" target="_blank" className="menu-link px-2">About</a>
                     </li>

                     <li className="menu-item"><a href="https://devs.keenthemes.com" target="_blank"
                        className="menu-link px-2">Support</a></li>

                     <li className="menu-item"><a href="https://1.envato.market/Vm7VRE" target="_blank"
                        className="menu-link px-2">Purchase</a></li>
                  </ul>
               </div>

            </div>

         </div>

      </>
   );
}

export { ListProduct };
